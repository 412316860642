import React, { useState, createContext } from "react";
import axiosClient from "../helper";

export const HomeContext = createContext();

const HomePageProvider = ({ children }) => {
  const [Blog, setBlog] = useState([]);
  const [news, setNews] = useState([]);
  const [totalnews, setTotalNews] = useState([]);
  const [totalJobs, setTotalJobs] = useState([]);
  const [totalBlogs, setTotalBlogs] = useState([]);
  const [totalDirectories, setTotalDirectories] = useState([]);
  const [dirPageNo, setDirPageNo] = useState(1);
  const [totalVideos, setTotalVideo] = useState([]);
  const [jobLocation, setJobLocation] = useState("");
  const [jobSpecialties, setjobSpecialties] = useState("");
  const [jobType, setJobType] = useState("");
  const [jobs, setJobs] = useState([]);
  const [Loader, setLoader] = useState(false);
  const [blogPageNo, setBlogPageNo] = useState(1);
  const [videoPageNo, setVideoPageNo] = useState(1);
  const [jobPageNo, setJobPageNo] = useState(1);
  const [jobTypeR, setJobTypeR] = useState("");
  const [NewsPageNo, setNewsPageNo] = useState(1);
  const [newsType, setNewsType] = useState("");
  const [alpha, setAlpha] = useState("A");
  const [noCFound, setNoCFound] = useState("");
  const [videos, setVideos] = useState([]);
  const [banners, setBanners] = useState([]);
  const [Company, setCompany] = useState([]);
  const [trainingGallery, setTrainingGallery] = useState([]);
  const [courses, setCourses] = useState([]);
  const [userName, setUserName] = React.useState("");
  const [blogMsg, setBlogMsg] = React.useState("");
  const [link, setLink] = React.useState([]);
  const [typeRelatedJobs, setTypeRelatedJobs] = React.useState([]);
  const [isCoursePurchased, setIsCoursePurchased] = React.useState(false);
  const [courseIds, setCourseIds] = React.useState([]);
  const [scholarshipNews, setScholarshipNews] = React.useState([]);
  const [admissionFormNews, setAdmissionFormNews] = React.useState([]);
  const [nursingCouncilUpdates, setNursingCouncilUpdates] = React.useState([]);
  const [clientLogos, setClientLogos] = React.useState([]);
  const [isHomePage, setIsHomePage] = React.useState(false);

  React.useEffect(() => {
    setLoader(false);
    axiosClient()
      .get(`/authenticate`)
      .then((res) => {
        setCourseIds(res.data.data.courseId);
        setUserName(res.data.data);

        setLoader(true);
      })
      .catch((error) => {});
  }, [isCoursePurchased]);

  React.useEffect(() => {
    axiosClient()
      .get(`/jobsByJobType?jobType=NGO/HMO&page=1`)
      .then((response) => {
        setTypeRelatedJobs(response.data.data);
      });
  }, []);
  React.useEffect(() => {
    axiosClient()
      .get(`allLinkAds`)
      .then((response) => {
        setLink(response.data.data);
      });
  }, []);
  React.useEffect(() => {
    axiosClient()
      .get(`allClientLogos`)
      .then((response) => {
        setClientLogos(response.data.data);
      });
  }, []);
  React.useEffect(() => {
    axiosClient()
      .get(`getTrainingGallery`)
      .then((response) => {
        setTrainingGallery(response.data.data);
      });
  }, []);
  React.useEffect(() => {
    axiosClient()
      .get(`getAllCourse`)
      .then((response) => {
        setCourses(response.data.data);
      });
  }, []);
  React.useEffect(() => {
    setLoader(false);
    axiosClient()
      .get(`/getVideos?page=${videoPageNo}`)
      .then((res) => {
        setVideos(res.data.data);
        setTotalVideo(res.data.totalVideos);

        setLoader(true);
      })
      .catch((error) => {});
  }, [videoPageNo]);

  React.useEffect(() => {
    setLoader(false);

    axiosClient()
      .get("/getBanners")
      .then((res) => {
        setBanners(res.data.data);
        setLoader(true);
      })
      .catch((error) => {});
  }, []);
  React.useEffect(() => {
    setLoader(false);
    axiosClient()
      .get(`/getAllBlogs?page=${blogPageNo}`)
      .then((res) => {
        setBlog(res.data.data);
        setTotalBlogs(res.data.totalBlogs);
        setBlogMsg(res.data.message);
        setLoader(true);
      })
      .catch((error) => {});
  }, [blogPageNo]);

  React.useEffect(() => {
    axiosClient()
      .get(`/getAllCompanies?page=${dirPageNo}&keyword=${alpha}`)
      .then((res) => {
        setCompany(res.data.data);
        setTotalDirectories(res.data.totalDirectories);
        setNoCFound(res.data.status);
        setLoader(true);
      })
      .catch((error) => {});
  }, [dirPageNo, alpha]);

  React.useEffect(() => {
    setLoader(false);
    axiosClient()
      .get(`/getAllNews?page=${NewsPageNo}&&type=${newsType}`)
      .then((res) => {
        setNews(res.data.data);
        setTotalNews(res.data.totalNews);
        console.log(" res.data.data.", res.data.data);
        // setScholarshipNews(
        //   res.data.data.filter(function (el) {
        //     return el.newsType === "Scholarships";
        //   })
        // );

        // setAdmissionFormNews(
        //   res.data.data.filter(function (el) {
        //     return el.newsType === "Nursing Admission Forms on Sale";
        //   })
        // );
        // setNursingCouncilUpdates(
        //   res.data.data.filter(function (el) {
        //     return el.newsType === "Nursing Council Updates";
        //   })
        // );
        setLoader(true);
      })
      .catch((error) => {});
  }, [NewsPageNo, newsType]);
  React.useEffect(() => {
    setLoader(false);
    axiosClient()
      .get(`/getNewsByType`)
      .then((res) => {
        console.log(" my data", res.data.data);
        setScholarshipNews(res.data.data.scholarshipNews);

        setAdmissionFormNews(res.data.data.admissionNews);

        setNursingCouncilUpdates(res.data.data.nursingCouncil);

        setLoader(true);
      })
      .catch((error) => {});
  }, [NewsPageNo, newsType]);

  React.useEffect(() => {
    setLoader(false);

    axiosClient()
      .get(`/getAllJobs?page=${jobPageNo}&&type=${jobTypeR}`)
      .then((res) => {
        setJobs(res.data.data);
        setLoader(true);
        setTotalJobs(res.data.totalJobs);
      })
      .catch((error) => {});
    // }, [jobPageNo, jobTypeR, jobSpecialties, jobLocation, jobType]);
  }, [jobPageNo, jobTypeR, isHomePage]);

  React.useEffect(() => {
    setLoader(false);

    if (jobSpecialties !== "") {
      axiosClient()
        .get(`/getJobBySpecialties?specialties=${jobSpecialties}`)
        .then((res) => {
          setJobs(res.data.data);
          setLoader(true);
          setTotalJobs(res.data.totalJobs);
        })
        .catch((error) => {});
    }
  }, [jobSpecialties]);

  React.useEffect(() => {
    setLoader(false);

    if (jobLocation !== "") {
      axiosClient()
        .get(`/getJobByLocation?location=${jobLocation}`)
        .then((res) => {
          setJobs(res.data.data);
          setLoader(true);
          setTotalJobs(res.data.totalJobs);
        })
        .catch((error) => {});
    }
  }, [jobLocation]);

  React.useEffect(() => {
    setLoader(false);
    if (jobType !== "") {
      axiosClient()
        .get(`/getJobByJobType?jobType=${jobType}`)
        .then((res) => {
          setJobs(res.data.data);
          setLoader(true);
          setTotalJobs(res.data.totalJobs);
        })
        .catch((error) => {});
    }
  }, [jobType]);
  return (
    <HomeContext.Provider
      value={{
        setUserName,
        courseIds,
        clientLogos,
        userName,
        Blog,
        typeRelatedJobs,
        news,
        jobs,
        link,
        trainingGallery,
        courses,
        noCFound,
        setAlpha,
        setBlogPageNo,
        setJobPageNo,
        setVideoPageNo,
        setNewsPageNo,
        setJobLocation,
        setjobSpecialties,
        setJobType,
        setIsCoursePurchased,
        setDirPageNo,
        setNewsType,
        setJobTypeR,
        setIsHomePage,
        isHomePage,
        isCoursePurchased,
        Loader,
        totalnews,
        totalJobs,
        totalBlogs,
        totalVideos,
        totalDirectories,
        videos,
        banners,
        Company,
        blogMsg,
        scholarshipNews,
        admissionFormNews,
        nursingCouncilUpdates,
      }}
    >
      {children}
    </HomeContext.Provider>
  );
};

export default HomePageProvider;
