import React from "react";
import { Divider, Grid } from "@material-ui/core";
import { QuestionAnswerTwoTone } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
export default function QuizComp(props) {
  const {
    quiz,
    currentQuestion,
    quizTitle,
    showScore,
    score,
    setScore,
    setCurrentQuestion,
    setShowScore,
  } = props;

  const correct = [];
  const [hidden, setHidden] = React.useState(false);
  const [Right, setRight] = React.useState(false);
  const [Wrong, setWrong] = React.useState(false);

  const handleAnswerOptionClick = (isCorrect) => {
    setHidden(true);
    if (isCorrect) {
      setRight(true);
      setScore(score + 1);
    } else {
      setWrong(true);
    }
  };
  const handleNext = () => {
    window.scrollTo(0, 0);
    setHidden(false);
    setRight(false);
    setWrong(false);

    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < quiz.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setShowScore(true);
    }
  };

  return (
    <div>
      <h3 style={{ marginTop: "40px" }} className="super">
        {quizTitle}
      </h3>
      {quizTitle ? (
        <Grid container xs={12}>
          <div className="app">
            {showScore ? (
              <>
                <div className="score-section">
                  You scored {score} out of {quiz.length}
                </div>
                <div style={{ marginTop: "20px", textAlign: "center" }}>
                  <Divider />
                  <h4> Answers</h4>
                  <Divider />
                </div>
                {quiz.map((Quiz) => (
                  <div style={{ marginTop: "20px" }}>
                    <h5>
                      QUESTION :{" "}
                      <p
                        dangerouslySetInnerHTML={{ __html: Quiz.description }}
                      ></p>
                    </h5>

                    {
                      <h5>
                        RIGHT ANS :{" "}
                        {Quiz.options.map((x, j) =>
                          x.isCorrect ? x.text : null
                        )}
                      </h5>
                    }
                    {/* {correct.map((c) => (
                      <h5>RIGHT ANS : {c.text}</h5>
                    ))} */}

                    <h5>EXPLANATION : {Quiz.explanation}</h5>
                    <Divider />
                  </div>
                ))}
              </>
            ) : (
              <>
                <div className="question-section">
                  <div className="question-count">
                    <span>Question {currentQuestion + 1}</span>/{quiz.length}
                  </div>
                  <div className="question-text">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: quiz[currentQuestion].description,
                      }}
                    ></p>
                  </div>
                </div>
                <div className="answer-section">
                  {quiz[currentQuestion].options.map((options) => (
                    <button
                      className="quizBtn"
                      onClick={() => handleAnswerOptionClick(options.isCorrect)}
                      disabled={hidden ? true : false}
                    >
                      {options.text}
                    </button>
                  ))}
                </div>
                {Right ? (
                  <div style={{ display: "flex", marginTop: "10px" }}>
                    <CheckCircleIcon
                      style={{ fontSize: "35px", color: "lightgreen" }}
                    />{" "}
                    <h6 style={{ color: "green", marginTop: "6px" }}>
                      your answer is correct{" "}
                    </h6>
                  </div>
                ) : null}
                {Wrong ? (
                  <div style={{ display: "flex", marginTop: "10px" }}>
                    <CancelIcon style={{ fontSize: "35px", color: "red" }} />
                    <h6 style={{ color: "red", marginTop: "6px" }}>
                      your answer is wrong{" "}
                    </h6>
                  </div>
                ) : null}
                {hidden
                  ? quiz.map((Quiz, i) => {
                      if (i === currentQuestion) {
                        return (
                          <div style={{ marginTop: "10px" }}>
                            <div style={{ display: "flex" }}>
                              <h6 style={{ color: "green" }}>
                                correct answer is : {""}{" "}
                              </h6>

                              {Quiz.options.map((x, j) => (
                                <h6> {x.isCorrect ? x.text : null}</h6>
                              ))}
                            </div>

                            <h6> {Quiz.explanation}</h6>
                          </div>
                        );
                      }
                    })
                  : null}
                {hidden ? (
                  <Button
                    style={{
                      background: "#1979C3",

                      marginTop: "20px",
                      color: "white",
                      // marginBottom: "10px",
                      float: "right",
                    }}
                    type="submit"
                    // fullWidth
                    variant="contained"
                    onClick={handleNext}
                  >
                    Next Question
                  </Button>
                ) : null}
              </>
            )}
          </div>
        </Grid>
      ) : null}
    </div>
  );
}
