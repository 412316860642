import "./App.css";
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Navigate,
  useHistory,
  Redirect,
} from "react-router-dom";

import DetailPage from "./Pages/jobdetailPage/jobdetailPage";
import Signup from "./Pages/SignUp/SignUp";
import SignIn from "./Pages/SignIn/SignIn";
import Blog from "./Pages/BlogPage/blogPage";
import PostABlog from "./Pages/postBlog/postBlog";
import PostAJob from "./Pages/postJob/postJob";
import PostANews from "./Pages/PostNews/PostNews";
import Footer from "./Components/Footer/Footer";
import NavBar from "./Components/NavBar/NavBar";
import PrimaryNavBar from "./Components/PrimaryNavBar/PrimaryNavBar";
import NewsDetailPage from "./Pages/NewsDetailPage/NewsDetail";
import ViewAllNews from "./Pages/ViewAllNews/ViewAllNews";
import ViewAllJobs from "./Pages/ViewAllJobs/ViewAllJobs";
import ViewAllBlogs from "./Pages/ViewAllBlogs/ViewAllBlogs";
import CompanyDetail from "./Pages/CompanyDetail/CompanyDetail";
import BlogerDetail from "./Pages/BlogerDetail/BlogerDetail";
import BlogDetail from "./Pages/BlogDetailPage/BlogDetail";
import Directory from "./Pages/Directory/directory";
import ProfilePage from "./Pages/ProfilePage/ProfilePage";
import CompanyProfile from "./Pages/CompanyProfile/CompanyProfile";
import HomePage from "./Pages/HomePage/HomePage";
import ViewAllVideos from "./Pages/ViewAllVideos/viewAllVideos";
import CompanyProvider from "./Context/companyContext";
import HomePageProvider from "./Context/HomeContext";
import TrainingGallery from "./Pages/TrainingGallery/trainingGallery";
import NewsProvider from "./Context/NewsContext";
import BlogProvider from "./Context/BlogContext";
import EventProvider from "./Context/EventContext";
import PostApiProvider from "./Context/PostApiContext";
import CourseProvider from "./Context/CourseContext";
import BloggerProvider from "./Context/BloggerContext";
import ContactUs from "./Pages/contactUs/contactUs";
import CompanyNews from "./Pages/CompanyNews/CompanyNews";
import OnlineCourse from "./Pages/OnlineCourse/onlineCourse";
import CourseVideo from "./Pages/courseVideo/courseVideo";
import CourseCheckout from "./Pages/courseCheckout/courseCheckout";
import MyCourses from "./Pages/myCourses/myCourses";
import Calender from "./Pages/Calender/calender";
import MyCourseDetail from "./Pages/MyCourseDetail/myCourseDetail";
import Feed from "./Pages/Feed/Feed";

import Quiz from "./Pages/Quiz/quiz";
import ReactGA from "react-ga";

import GoogleAd from "./Components/GoogleAdsen";
import Page404 from "./Pages/404";
function App() {
  const TRACKING_ID = "UA-208194801-2"; // YOUR_OWN_TRACKING_ID
  const history = useHistory();

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const routes = (
    <>
      <div className="ad-class" style={{ height: 0 }}>
        <GoogleAd slot="3289423240033040" />
      </div>
      <Switch>
        {/* <Route exact path='/route2'>
  <ContextB.Provider>
    <Component1 />
  </ContextB.Provider>
</Route> */}
        <Route exact path="/Signup">
          <HomePageProvider>
            <NewsProvider>
              <BlogProvider>
                <CourseProvider>
                  <PostApiProvider>
                    <Signup />
                  </PostApiProvider>
                </CourseProvider>
              </BlogProvider>
            </NewsProvider>
          </HomePageProvider>
        </Route>
        <Route path="/Signin" exact>
          <HomePageProvider>
            <NewsProvider>
              <BlogProvider>
                <CourseProvider>
                  <PostApiProvider>
                    <SignIn />
                  </PostApiProvider>
                </CourseProvider>
              </BlogProvider>
            </NewsProvider>
          </HomePageProvider>
        </Route>
        <Route path="/contactUs" exact>
          <HomePageProvider>
            <NewsProvider>
              <BlogProvider>
                <CourseProvider>
                  <PostApiProvider>
                    <ContactUs />
                  </PostApiProvider>
                </CourseProvider>
              </BlogProvider>
            </NewsProvider>
          </HomePageProvider>
        </Route>
        <Route path="/courseVideo" exact>
          <HomePageProvider>
            <NewsProvider>
              <BlogProvider>
                <CourseProvider>
                  <PostApiProvider>
                    <CourseVideo />
                  </PostApiProvider>
                </CourseProvider>
              </BlogProvider>
            </NewsProvider>
          </HomePageProvider>
        </Route>
        <Route path="/courseCheckout/:id?" exact>
          <HomePageProvider>
            <NewsProvider>
              <BlogProvider>
                <CourseProvider>
                  <PostApiProvider>
                    <CourseCheckout />
                  </PostApiProvider>
                </CourseProvider>
              </BlogProvider>
            </NewsProvider>
          </HomePageProvider>
        </Route>
        <Route path="/myCourses" exact>
          <HomePageProvider>
            <NewsProvider>
              <BlogProvider>
                <CourseProvider>
                  <PostApiProvider>
                    <MyCourses />
                  </PostApiProvider>
                </CourseProvider>
              </BlogProvider>
            </NewsProvider>
          </HomePageProvider>
        </Route>
        <Route path="/myCourseDetail/:id?" exact>
          <HomePageProvider>
            <NewsProvider>
              <BlogProvider>
                <CourseProvider>
                  <PostApiProvider>
                    <MyCourseDetail />
                  </PostApiProvider>
                </CourseProvider>
              </BlogProvider>
            </NewsProvider>
          </HomePageProvider>
        </Route>
        <Route path="/quiz" exact>
          <HomePageProvider>
            <NewsProvider>
              <BlogProvider>
                <CourseProvider>
                  <PostApiProvider>
                    <Quiz />
                  </PostApiProvider>
                </CourseProvider>
              </BlogProvider>
            </NewsProvider>
          </HomePageProvider>
        </Route>
        <Route path="/ViewAllBlogs" exact>
          <HomePageProvider>
            <NewsProvider>
              <BlogProvider>
                <CourseProvider>
                  <PostApiProvider>
                    <ViewAllBlogs />
                  </PostApiProvider>
                </CourseProvider>
              </BlogProvider>
            </NewsProvider>
          </HomePageProvider>
        </Route>
        <Route path="/ViewAllVideos" exact>
          <HomePageProvider>
            <NewsProvider>
              <BlogProvider>
                <CourseProvider>
                  <PostApiProvider>
                    <ViewAllVideos />
                  </PostApiProvider>
                </CourseProvider>
              </BlogProvider>
            </NewsProvider>
          </HomePageProvider>
        </Route>
        <Route path="/blogPage" exact>
          <HomePageProvider>
            <NewsProvider>
              <BlogProvider>
                <CourseProvider>
                  <PostApiProvider>
                    <Blog />
                  </PostApiProvider>
                </CourseProvider>
              </BlogProvider>
            </NewsProvider>
          </HomePageProvider>
        </Route>
        <Route path="/TrainingGallery" exact>
          <HomePageProvider>
            <NewsProvider>
              <BlogProvider>
                <CourseProvider>
                  <PostApiProvider>
                    <TrainingGallery />
                  </PostApiProvider>
                </CourseProvider>
              </BlogProvider>
            </NewsProvider>
          </HomePageProvider>
        </Route>
        {/* From now add <EventProvider>  */}
        <Route path="/Calender" exact>
          <HomePageProvider>
            <NewsProvider>
              <BlogProvider>
                <CourseProvider>
                  <PostApiProvider>
                    <EventProvider>
                      <Calender />
                    </EventProvider>
                  </PostApiProvider>
                </CourseProvider>
              </BlogProvider>
            </NewsProvider>
          </HomePageProvider>
        </Route>
        <Route path="/OnlineCourse/:id?" exact>
          <HomePageProvider>
            <NewsProvider>
              <BlogProvider>
                <CourseProvider>
                  <PostApiProvider>
                    <OnlineCourse />
                  </PostApiProvider>
                </CourseProvider>
              </BlogProvider>
            </NewsProvider>
          </HomePageProvider>
        </Route>
        <Route path="/" exact>
          <HomePageProvider>
            <NewsProvider>
              <BlogProvider>
                <CourseProvider>
                  <PostApiProvider>
                    <CompanyProvider>
                      <HomePage />
                    </CompanyProvider>
                  </PostApiProvider>
                </CourseProvider>
              </BlogProvider>
            </NewsProvider>
          </HomePageProvider>
        </Route>
        <Route path="/Directory/:id?" exact>
          <HomePageProvider>
            <NewsProvider>
              <BlogProvider>
                <CourseProvider>
                  <PostApiProvider>
                    <CompanyProvider>
                      <Directory />
                    </CompanyProvider>
                  </PostApiProvider>
                </CourseProvider>
              </BlogProvider>
            </NewsProvider>
          </HomePageProvider>
        </Route>
        <Route path="/ViewAllNews" exact>
          <HomePageProvider>
            <NewsProvider>
              <BlogProvider>
                <CourseProvider>
                  <PostApiProvider>
                    <CompanyProvider>
                      <ViewAllNews />
                    </CompanyProvider>
                  </PostApiProvider>
                </CourseProvider>
              </BlogProvider>
            </NewsProvider>
          </HomePageProvider>
        </Route>
        <Route path="/ViewAllJobs/:location?" exact>
          <HomePageProvider>
            <NewsProvider>
              <BlogProvider>
                <CourseProvider>
                  <PostApiProvider>
                    <CompanyProvider>
                      <ViewAllJobs />
                    </CompanyProvider>
                  </PostApiProvider>
                </CourseProvider>
              </BlogProvider>
            </NewsProvider>
          </HomePageProvider>
        </Route>
        <Route path="/news/:id?" exact>
          <HomePageProvider>
            <NewsProvider>
              <BlogProvider>
                <CourseProvider>
                  <PostApiProvider>
                    <CompanyProvider>
                      <NewsDetailPage />
                    </CompanyProvider>
                  </PostApiProvider>
                </CourseProvider>
              </BlogProvider>
            </NewsProvider>
          </HomePageProvider>
        </Route>
        <Route path="/CompanyNews/:id?" exact>
          <HomePageProvider>
            <NewsProvider>
              <BlogProvider>
                <CourseProvider>
                  <PostApiProvider>
                    <CompanyProvider>
                      <CompanyNews />
                    </CompanyProvider>
                  </PostApiProvider>
                </CourseProvider>
              </BlogProvider>
            </NewsProvider>
          </HomePageProvider>
        </Route>
        {/* From now add <BloggerProvider>  */}
        <Route path="/ProfilePage/:id?" exact>
          <HomePageProvider>
            <NewsProvider>
              <BlogProvider>
                <CourseProvider>
                  <PostApiProvider>
                    <CompanyProvider>
                      <BloggerProvider>
                        <ProfilePage />
                      </BloggerProvider>
                    </CompanyProvider>
                  </PostApiProvider>
                </CourseProvider>
              </BlogProvider>
            </NewsProvider>
          </HomePageProvider>
        </Route>
        <Route path="/blog/:id?" exact>
          <HomePageProvider>
            <NewsProvider>
              <BlogProvider>
                <CourseProvider>
                  <PostApiProvider>
                    <CompanyProvider>
                      <BloggerProvider>
                        <BlogDetail />
                      </BloggerProvider>
                    </CompanyProvider>
                  </PostApiProvider>
                </CourseProvider>
              </BlogProvider>
            </NewsProvider>
          </HomePageProvider>
        </Route>
        <Route path="/CompanyProfile/:id?" exact>
          <HomePageProvider>
            <NewsProvider>
              <BlogProvider>
                <CourseProvider>
                  <PostApiProvider>
                    <CompanyProvider>
                      <CompanyProfile />
                    </CompanyProvider>
                  </PostApiProvider>
                </CourseProvider>
              </BlogProvider>
            </NewsProvider>
          </HomePageProvider>
        </Route>
        <Route path="/job/:id?" exact>
          <HomePageProvider>
            <NewsProvider>
              <BlogProvider>
                <CourseProvider>
                  <PostApiProvider>
                    <CompanyProvider>
                      <DetailPage />
                    </CompanyProvider>
                  </PostApiProvider>
                </CourseProvider>
              </BlogProvider>
            </NewsProvider>
          </HomePageProvider>
        </Route>
        <Route path="/CompanyDetail/:id?" exact>
          <HomePageProvider>
            <NewsProvider>
              <BlogProvider>
                <CourseProvider>
                  <PostApiProvider>
                    <CompanyProvider>
                      <CompanyDetail />
                    </CompanyProvider>
                  </PostApiProvider>
                </CourseProvider>
              </BlogProvider>
            </NewsProvider>
          </HomePageProvider>
        </Route>
        {/* From now dont add <CompanyProvider> */}
        <Route path="/BlogerDetail/:id?" exact>
          <HomePageProvider>
            <NewsProvider>
              <BlogProvider>
                <CourseProvider>
                  <PostApiProvider>
                    <BlogerDetail />
                  </PostApiProvider>
                </CourseProvider>
              </BlogProvider>
            </NewsProvider>
          </HomePageProvider>
        </Route>
        <Route path="/postNews" exact>
          <HomePageProvider>
            <NewsProvider>
              <BlogProvider>
                <CourseProvider>
                  <PostApiProvider>
                    <PostANews />
                  </PostApiProvider>
                </CourseProvider>
              </BlogProvider>
            </NewsProvider>
          </HomePageProvider>
        </Route>
        <Route path="/postBlog" exact>
          <HomePageProvider>
            <NewsProvider>
              <BlogProvider>
                <CourseProvider>
                  <PostApiProvider>
                    <PostABlog />
                  </PostApiProvider>
                </CourseProvider>
              </BlogProvider>
            </NewsProvider>
          </HomePageProvider>
        </Route>
        <Route path="/postJob" exact>
          <HomePageProvider>
            <NewsProvider>
              <BlogProvider>
                <CourseProvider>
                  <PostApiProvider>
                    <PostAJob />
                  </PostApiProvider>
                </CourseProvider>
              </BlogProvider>
            </NewsProvider>
          </HomePageProvider>
        </Route>
        <Route path="/feed" exact>
          <Feed />
        </Route>
        <Route exact path="/404">
          <HomePageProvider>
            <NewsProvider>
              <BlogProvider>
                <CourseProvider>
                  <PostApiProvider>
                    <Page404 />
                  </PostApiProvider>
                </CourseProvider>
              </BlogProvider>
            </NewsProvider>
          </HomePageProvider>
        </Route>
        <Redirect from="*" to="/404" />{" "}
        {/*  <Route path="*">
          <Page404 />{" "}
        </Route>*/}
      </Switch>

      <Footer />
    </>
  );

  return <div>{routes}</div>;
}

export default App;
