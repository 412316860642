import React from "react";
import "../Loader/loader.scss";
export default function loader() {
  return (
    <div className="bod">
      <div class="LoaderBalls">
        <div class="LoaderBalls__item"></div>
        <div class="LoaderBalls__item"></div>
        <div class="LoaderBalls__item"></div>
      </div>
    </div>
  );
}
