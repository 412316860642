import React, { useContext, useEffect } from 'react';

import Container from '@material-ui/core/Container';
import Load from '../../Components/Loader/loader';
import JobCard from '../../Components/jobCards/jobCard';

import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import { Grid } from '@material-ui/core';
import { HomeContext } from '../../Context/HomeContext.js';

import { useParams } from 'react-router-dom';
import PrimaryNavBar from '../../Components/PrimaryNavBar/PrimaryNavBar';
import NavBar from '../../Components/NavBar/NavBar';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
      marginBottom: '30px'
    }
  }
}));
export default function ViewAllJobss() {
  const { jobs, setJobPageNo, Loader, totalJobs, typeRelatedJobs } = useContext(HomeContext);

  const classes = useStyles();

  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    setJobPageNo(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const totaljobs = Math.ceil(totalJobs / 6);

  return (
    <React.Fragment>
      <PrimaryNavBar />
      <NavBar />
      <Container style={{ marginTop: '40px' }}>
        <Grid container justify="center" spacing={3}>
          <Grid item container justify="center">
            <h1 className="super">All Jobs</h1>
          </Grid>

          {Loader ? (
            jobs.length === 0 ? (
              <div style={{ textAlign: 'center', marginTop: '120px', marginBottom: '120px' }}>
                <h4>No Jobs Found ! </h4>
              </div>
            ) : (
              <Grid item container justify="space-between" spacing={8} style={{ marginTop: '10px' }}>
                {jobs.map((Job) => (
                  <Grid item xs={11} md={6} lg={6}>
                    <JobCard Jobs={Job} />
                  </Grid>
                ))}
              </Grid>
            )
          ) : (
            <Grid
              item
              container
              justify="center"
              style={{
                marginTop: '60px',
                marginBottom: '450px'
              }}
            >
              <Load />
            </Grid>
          )}
          {jobs.length === 0 ? null : (
            <Grid className={classes.root}>
              <Pagination count={totaljobs} color="primary" onChange={handleChange} />
            </Grid>
          )}
        </Grid>
      </Container>
    </React.Fragment>
  );
}
