import React, { useContext } from 'react';

import { Grid, Container } from '@material-ui/core';

import { CourseContext } from '../../Context/CourseContext';
import ReactPlayer from 'react-player';
import './courseVideo.css';
import PrimaryNavBar from '../../Components/PrimaryNavBar/PrimaryNavBar';
import NavBar from '../../Components/NavBar/NavBar';
import Load from '../../Components/Loader/loader';
import { useParams } from 'react-router-dom';
import Rating from '@material-ui/lab/Rating';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router-dom';
import { PostApiContext } from '../../Context/PostApiContext';
import { HomeContext } from '../../Context/HomeContext';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  }
}));
export default function OnlineCourse() {
  const classes = useStyles();
  // const { id } = useParams();
  const [value, setValue] = React.useState(4);

  const { courseIds } = useContext(HomeContext);

  const { onlineCourses, Loader } = useContext(CourseContext);

  const history = useHistory();

  function handleCheckout(id) {
    localStorage.getItem('token') ? history.push(`/courseCheckout/${id}`) : history.push('/SignIn');
  }

  return (
    <React.Fragment>
      <PrimaryNavBar />
      <NavBar />
      <Container>
        <h2 style={{ marginTop: '40px' }} className="super">
          Courses Videos
        </h2>
        {Loader ? (
          <Grid container>
            <Grid item container spacing={2} style={{ marginTop: '50px', marginBottom: '90px' }}>
              {onlineCourses.map((online) => {
                return (
                  <Grid item xs={12} md={6} lg={4}>
                    <img
                      className="newsimg"
                      style={{ cursor: 'pointer' }}
                      src={online.onlineCourseThumbnail}
                      width="100%"
                      height="200px"
                      onClick={() => handleCheckout(online._id)}
                    />
                    {/* <ReactPlayer
                      style={{ cursor: "pointer" }}
                      controls="true"
                      light={online.videoThumbnail}
                 
                    
                      onClick={() => handleCheckout(online._id)}
                    /> */}
                    <h4
                      style={{
                        marginTop: '5px'
                      }}
                    >
                      <b> {online.courseName}</b>
                    </h4>

                    <Box component="fieldset" mb={3} borderColor="transparent">
                      <Rating name="read-only" value={value} readOnly />
                    </Box>
                    {courseIds.find((o) => o._id === online._id) ? (
                      // <h6 style={{ color: "red", border: "1px solid purple" }}>
                      //   <b>PURCHASED</b>
                      // </h6>
                      <Button variant="outlined" color="secondary">
                        PURCHASED
                      </Button>
                    ) : (
                      <h4>$ {online.price}</h4>
                    )}
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        ) : (
          <Grid container justify="center" style={{ marginTop: '80px', marginBottom: '350px' }}>
            <Load />
          </Grid>
        )}
      </Container>
    </React.Fragment>
  );
}
