import React, { useContext } from 'react';

import Container from '@material-ui/core/Container';
import Load from '../../Components/Loader/loader';
import './courseCheckout.css';
import { useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import JobCard from '../../Components/jobCards/jobCard';
import Button from '@material-ui/core/Button';
import { CompanyContext } from '../../Context/companyContext';
import PrimaryNavBar from '../../Components/PrimaryNavBar/PrimaryNavBar';
import NavBar from '../../Components/NavBar/NavBar';
import { CourseContext } from '../../Context/CourseContext';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import ReactPlayer from 'react-player';
import PaypalBtn from '../../Components/paypal';
import { PostApiContext } from '../../Context/PostApiContext';
import { HomeContext } from '../../Context/HomeContext';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LabelIcon from '@material-ui/icons/Label';
import MovieIcon from '@material-ui/icons/Movie';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  root: {
    width: '100%',
    marginTop: '7px'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  }
}));

export default function CompanyProfile() {
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : true);
  };

  const handleChanges = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const { id } = useParams();
  const [value, setValue] = React.useState(4);
  const { courseIds } = useContext(HomeContext);

  const [alert, setAlert] = React.useState(false);
  const { Loader, onlineCourseCheckout, setOnlineCourseId, courseModules } = useContext(CourseContext);

  const { onlineCourseThumbnail, courseName, price, courseDescription } = onlineCourseCheckout;

  const [isPurchased, setIsPurchased] = React.useState(false);
  const [moduleCount, setModuleCount] = React.useState(0);
  const [videosCount, setVideosCount] = React.useState(0);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  React.useEffect(() => {
    window.scrollTo(0, 0);
    setOnlineCourseId(id);
  }, []);
  const history = useHistory();

  function handleCheckout() {
    setAlert(true);
    // history.push("/courseVideo");
  }

  const handleNavigate = () => {
    history.push('/courseVideo');
  };
  return (
    <React.Fragment>
      <PrimaryNavBar />
      <NavBar />
      <Container style={{ marginTop: '40px', marginBottom: '90px' }}>
        {Loader ? (
          <Grid container justify="space-between" xs={12} container direction="row">
            <Grid xs={12} md={8} lg={8} item container style={{ marginTop: '5px', marginBottom: '30px' }}>
              <Grid item container>
                <h3>Online Course</h3>
              </Grid>
              <Grid item xs={12} md={9} lg={9}>
                <img
                  className="newsimg"
                  style={{ cursor: 'pointer' }}
                  src={onlineCourseThumbnail}
                  width="100%"
                  height="250px"
                  onClick={handleCheckout}
                />
                {/* <ReactPlayer
                  style={{ cursor: "pointer" }}
                  controls="true"
                  light={videoThumbnail}
                  className="videoPlayer"
                  url={videoURL}
                  onClick={handleCheckout}
                /> */}
                <h4
                  style={{
                    marginTop: '5px'
                  }}
                >
                  <b> {courseName} </b>
                </h4>
                <Box component="fieldset" mb={3} borderColor="transparent">
                  <Rating name="read-only" value={value} readOnly />
                </Box>
                <Grid item style={{ marginTop: '20px' }}>
                  <h5>Course Details:</h5>
                  <p style={{ textAlign: 'justify' }}>
                    {' '}
                    <p
                      dangerouslySetInnerHTML={{
                        __html: courseDescription
                      }}
                    ></p>
                  </p>
                </Grid>
                <h5 style={{ marginTop: '40px' }}>Modules:</h5>
                {courseModules.map((module) => (
                  <div className={classes.root}>
                    <Accordion expanded={expanded === module._id} onChange={handleChange(module._id)} id={module._id}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                        <Typography className={classes.heading}>
                          <b> {module.moduleName}</b>
                        </Typography>
                        <Typography className={classes.secondaryHeading}>{module.moduleDescription}</Typography>
                      </AccordionSummary>
                      {module.courseVideos.map((video) => (
                        <AccordionDetails>
                          <Typography>
                            <MovieIcon style={{ color: 'red' }} /> {video.videoTitle}
                          </Typography>
                        </AccordionDetails>
                      ))}

                      {module.subModules.map((sub) => (
                        <div>
                          {/* <Accordion
                            expanded={expanded === sub._id}
                            onChange={handleChanges(sub._id)}
                            id={sub._id}
                          > */}
                          <AccordionSummary
                          // expandIcon={<ExpandMoreIcon />}
                          // aria-controls="panel2bh-content"
                          // id="panel2bh-header"
                          >
                            <Typography className={classes.heading} style={{ marginLeft: '15px' }}>
                              <FiberManualRecordIcon style={{ fontSize: '10px' }} /> <b> {sub.subModuleName}</b>
                            </Typography>
                            <Typography className={classes.secondaryHeading}>{sub.subModuleDescription}</Typography>
                          </AccordionSummary>
                          {sub.courseVideos.map((video) => (
                            <AccordionDetails style={{ marginLeft: '50px' }}>
                              <Typography>
                                <MovieIcon style={{ color: 'red' }} /> {video.videoTitle}
                                {/* <h2>hi g</h2> */}
                              </Typography>
                            </AccordionDetails>
                          ))}
                          {/* </Accordion> */}
                        </div>
                      ))}
                    </Accordion>
                  </div>
                ))}
                {/* <h4>$ {price}</h4> */}
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} style={{ marginTop: '30px' }}>
              <Grid
                item
                container
                style={{
                  backgroundColor: '#F8F9FA',
                  padding: '20px',
                  boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
                  marginTop: '20px'
                }}
              >
                <Grid item container justify="center" xs={12}>
                  <h4 style={{ color: '#FE9A01' }}>Checkout Details</h4>
                </Grid>
                <Grid item xs={10} style={{ marginTop: '30px', color: 'gray' }}>
                  <h6>price</h6>
                  <hr></hr>
                  <h6>Total Price</h6>
                </Grid>
                <Grid item xs={2} style={{ marginTop: '30px' }}>
                  <h6>${price}</h6>
                  <hr></hr>
                  <h6>${price}</h6>
                </Grid>

                {courseIds.find((o) => o._id === id) || isPurchased ? (
                  <Grid item container justify="center" style={{ marginTop: '10px' }}>
                    {' '}
                    <Button variant="outlined" color="secondary">
                      PURCHASED
                    </Button>
                  </Grid>
                ) : (
                  <Grid item container justify="center" style={{ marginTop: '20px' }}>
                    <PaypalBtn
                      setIsPurchased={setIsPurchased}
                      courseDetails={onlineCourseCheckout}
                      setSuccess={setSuccess}
                      setError={setError}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <SweetAlert
              show={success}
              type="success"
              title="Transaction Successful!"
              onConfirm={() => setSuccess(false)}
              // onConfirm={handleSuccess}

              // onCancel={this.onCancel}
            >
              Go to my courses to start this course!
            </SweetAlert>
            <SweetAlert
              show={alert}
              title="You have to buy course to get access!"
              onConfirm={handleNavigate}
              // onCancel={this.onCancel}
            >
              if you already PURCHASED, go to my courses
            </SweetAlert>
          </Grid>
        ) : (
          <div style={{ margin: '100px', marginBottom: '400px' }}>
            <Load />
          </div>
        )}
      </Container>
    </React.Fragment>
  );
}
