import React, { useEffect, useState } from "react";
import axiosClient from "../../helper";
import { Grid, Container } from "@material-ui/core";

export default function Feed() {
  const [data, setData] = useState();
  useEffect(() => {
    axiosClient()
      .get(`/feed`)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {});
  }, []);
  return (
    <React.Fragment>
      <Container>
        <Grid container>
          <Grid item style={{ marginTop: "30px" }}>
            {data}
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
