import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';

import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';

import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import './ProfileCard.css';

const useStyles = makeStyles((theme) => ({
	root: {
		maxWidth: 350,
		backgroundColor: '#FFFFFF',
	},
	media: {
		height: '350px',
		// paddingTop: "56.25%", // 16:9
	},
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: 'rotate(180deg)',
	},
	avatar: {
		backgroundColor: '#1979C3',
	},
}));

export default function ProfileCard(props) {
	const {
		about,
		pictureURL,
		firstName,
		lastName,
		facebookUrl,
		instaUrl,
		tweeterUrl,
	} = props.BloggerProfile;

	const classes = useStyles();
	const [expanded, setExpanded] = React.useState(false);

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};
	function handleRedirect(url) {
		window.open(url);
	}
	return (
		<Card className={classes.root}>
			<CardHeader
				avatar={
					<Avatar aria-label='recipe' className={classes.avatar}>
						H
					</Avatar>
				}
				title={
					<h4 style={{ color: '#1979C3', cursor: 'pointer' }}>
						{' '}
						{firstName} {lastName}
					</h4>
				}
			/>
			<CardMedia
				className={classes.media}
				image={pictureURL}
				title='Paella dish'
			/>
			<CardContent>
				<Typography variant='h5' align='center' className='ProfileCardTxt'>
					<b>About Me</b>
				</Typography>
				<Typography
					variant='h6'
					color='textSecondary'
					component='p'
					className='ProfileCardTxt'
				>
					{about}
					{/* I'm computer engineer and MERN Stack developer with variety of
          professional knowledge. I would like to dedicate myself towards the
          achievements and my responsibilities. */}
				</Typography>
			</CardContent>
			<CardActions disableSpacing>
				<IconButton aria-label='instagram'>
					<InstagramIcon
						onClick={() => handleRedirect(instaUrl)}
						className='instagram'
					/>
				</IconButton>
				<IconButton aria-label='facebook'>
					<FacebookIcon
						onClick={() => handleRedirect(facebookUrl)}
						className='facebook'
					/>
				</IconButton>
				<IconButton aria-label='twitter'>
					<TwitterIcon
						onClick={() => handleRedirect(tweeterUrl)}
						className='twitter'
					/>
				</IconButton>
			</CardActions>
		</Card>
	);
}
