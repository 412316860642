import React from "react";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Button from "@material-ui/core/Button";
import { NewsContext } from "../../Context/NewsContext";

export default function JobCard(props) {
  const { setNewsId } = React.useContext(NewsContext);

  const history = useHistory();

  function handleDetailPage(id) {
    history.push(`/news/${id}`);
    setNewsId(id);
  }
  function handleViewAllNews(NewType) {
    history.push("/ViewAllNews");
    // history.push(`/ViewAllJobs?type=${jobType}`);
  }
  return (
    <Grid
      container
      justify="center"
      container
      justify="center"
      style={{
        padding: "20px",
      }}
    >
      <Grid
        xs={12}
        item
        style={{
          backgroundColor: "#1979C3",
          color: "white",
          textAlign: "center",
        }}
      >
        <h3>Scholarships </h3>
      </Grid>
      {props.scholarshipNews.slice(0, 2).map((data) => (
        <>
          <Grid item xs={12}>
            <h6
              className="font"
              style={{
                marginTop: "20px",
                cursor: "pointer",
                textAlign: "justify",
              }}
              onClick={() => handleDetailPage(data.slug)}
            >
              {/* COVID-19 stress strains nurses’ physical and emotional health */}
              {data.newsTitle}
            </h6>
          </Grid>

          <Grid item xs={12}>
            <p style={{ color: "gray" }}>{data.newsDescription}</p>
          </Grid>
          <Grid item xs={12}>
            <h6
              style={{ color: "red", cursor: "pointer" }}
              onClick={() => handleDetailPage(data._id)}
            >
              Read More
            </h6>
          </Grid>
          <Grid item xs={12}>
            <hr></hr>
          </Grid>
        </>
      ))}
      {props.scholarshipNews.length === 0 ? null : (
        <Grid container>
          <Button
            style={{
              background: "red",
              color: "white",
            }}
            type="submit"
            variant="contained"
            onClick={() => handleViewAllNews("Scholarships")}
          >
            View More <ArrowForwardIcon />
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
