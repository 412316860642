import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import "./nav.scss";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Modal from "@material-ui/core/Modal";
import SimpleModal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import MoreIcon from "@material-ui/icons/MoreVert";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
// import Button from "@material-ui/core/Button";
import axiosClient from "../../helper";
import Dropdown from "react-bootstrap/Dropdown";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import ThemeSwitch from "../../DarkTheme/ThemeSwitch";
import { PostApiContext } from "../../Context/PostApiContext";
import { HomeContext } from "../../Context/HomeContext";
import NavBarLogo from "../../assets/images/logo.png";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: "56%",
    left: "49%",
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  grow: {
    // flexGrow: 1,
  },
  title: {
    display: "none",
    color: "black",
    [theme.breakpoints.up("xs")]: {
      display: "block",
      color: "black",
      cursor: "pointer",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#EEEEE9",
    "&:hover": {
      backgroundColor: "#EEEEE9",
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "black",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    color: "black",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  navbar1: {
    padding: "10px",
    cursor: "pointer",
  },
  paper: {
    position: "relative",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    boxShadow: "0 0 4px",
    borderRadius: "10px",
    padding: theme.spacing(2, 4, 3),
  },
}));
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function PrimaryNavBar() {
  const { userName } = useContext(HomeContext);
  const { firstName, lastName } = userName;
  const [opens, setOpenss] = React.useState(false);
  const [popupMsg, setPopupMsg] = React.useState("");
  const [snackbarColor, setSnackbarColor] = React.useState("");
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [isBtndisable, setisBtndisable] = React.useState(false);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        {localStorage.getItem("token") ? (
          <div className={classes.navbar1}>
            {" "}
            <div className="wrapper">
              <h6 style={{ marginTop: "5px", color: "gray" }}>
                Welcome{" "}
                <b>
                  {firstName ? firstName : ""} {lastName ? lastName : ""}{" "}
                  {/* {localStorage.getItem('bloggerId') === 'undefined' ? null : (
                    <AccountCircleIcon
                      style={{ fontSize: '30px', color: '#1979C3' }}
                      onClick={handleNavi}
                    />
                  )} */}
                </b>
              </h6>
            </div>
          </div>
        ) : null}
      </MenuItem>

      {localStorage.getItem("token") ? null : (
        <MenuItem>
          <p onClick={handleSignup}>Register</p>
        </MenuItem>
      )}
      <MenuItem onClick={handleProfileMenuOpen}>
        {localStorage.getItem("token") ? null : (
          <div onClick={handleSign}>
            <b> Sign In</b>{" "}
          </div>
        )}
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        {localStorage.getItem("token") ? (
          <div onClick={handleSignOut}>
            <Button variant="outlined" color="primary">
              <b> Logout</b>{" "}
            </Button>
          </div>
        ) : null}
      </MenuItem>
    </Menu>
  );

  const history = useHistory();
  function handleSignup() {
    history.push("/Signup");
  }
  function handleContactUs() {
    history.push("/contactUs");
  }
  function handleHome() {
    history.push("/");
  }
  function handleSignOut() {
    localStorage.removeItem("token");
    localStorage.removeItem("companyId");
    localStorage.removeItem("bloggerId");

    history.push("/");
    window.location.reload();
  }
  function handleSign() {
    history.push("/SignIn");
  }
  function handleNavi() {
    history.push(`/BlogerDetail/${localStorage.getItem("bloggerId")}`);
  }
  const handleCloses = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenss(false);
  };
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.grow}>
      <AppBar position="static" color="white">
        <Toolbar style={{ justifyContent: "space-between" }}>
          {/* <Typography
            className={classes.title}
            variant="h6"
            noWrap
            onClick={handleHome}
          >
            Nursing Logo
          </Typography> */}
          <img
            onClick={handleHome}
            style={{ cursor: "pointer" }}
            src={NavBarLogo}
            width="300px"
            height="55px"
          />

          <Grid className={classes.sectionDesktop}>
            {/* <div className={classes.navbar1}>
              {' '}
              <Button
                variant="outlined"
                color="primary"
                onClick={handleContactUs}
              >
                <b> Contact</b>{' '}
              </Button>
            </div> */}
            {localStorage.getItem("token") ? null : (
              <div className={classes.navbar1} onClick={handleSignup}>
                <Button variant="outlined" color="primary">
                  <b> Register</b>{" "}
                </Button>
              </div>
            )}
            {localStorage.getItem("token") ? null : (
              <div className={classes.navbar1} onClick={handleSign}>
                <Button variant="outlined" color="primary">
                  <b> Sign In</b>{" "}
                </Button>
              </div>
            )}
            {localStorage.getItem("token") ? (
              <div className={classes.navbar1} onClick={handleSignOut}>
                <Button variant="outlined" color="primary">
                  <b> Logout</b>{" "}
                </Button>
              </div>
            ) : null}
            {localStorage.getItem("token") ? (
              <div className={classes.navbar1}>
                {" "}
                <div className="wrapper">
                  <h6 style={{ marginTop: "5px", color: "gray" }}>
                    Welcome{" "}
                    <b>
                      {firstName} {lastName}{" "}
                      {localStorage.getItem("bloggerId") ===
                      "undefined" ? null : (
                        <AccountCircleIcon
                          style={{ fontSize: "30px", color: "#1979C3" }}
                          onClick={handleNavi}
                        />
                      )}
                    </b>
                  </h6>
                </div>
              </div>
            ) : null}
          </Grid>

          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </div>
  );
}
