import React, { useContext, useEffect } from "react";
import Container from "@material-ui/core/Container";
import Load from "../../Components/Loader/loader";
import BlogCard from "../../Components/BlogCard/BlogCard";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import { Grid } from "@material-ui/core";
import { HomeContext } from "../../Context/HomeContext.js";
import PrimaryNavBar from "../../Components/PrimaryNavBar/PrimaryNavBar";
import NavBar from "../../Components/NavBar/NavBar";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
      marginBottom: "30px",
    },
  },
}));
export default function ViewAllBlogs() {
  const { Blog, setBlogPageNo, Loader, totalBlogs } = useContext(HomeContext);

  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    setBlogPageNo(value);
  };
  const totalblogs = Math.ceil(totalBlogs / 6);

  return (
    <React.Fragment>
      <PrimaryNavBar />
      <NavBar />
      <Container style={{ marginTop: "40px" }}>
        <Grid container justify="center" spacing={3}>
          <Grid item container justify="center">
            <h1 className="super">All Blogs</h1>
          </Grid>
          {Loader ? (
            <Grid
              container
              spacing={3}
              justify="center"
              style={{ marginTop: "20px", marginBottom: "30px" }}
            >
              {Blog.map((blog) => (
                <Grid item xs={11} sm={6} md={4} lg={4}>
                  {" "}
                  <div>
                    <BlogCard BlogDetail={blog} />
                  </div>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid
              item
              container
              justify="center"
              style={{
                marginTop: "60px",
                marginBottom: "450px",
              }}
            >
              <Load />
            </Grid>
          )}
          <Grid item>
          <div className={classes.root}>
            <Pagination
              count={totalblogs}
              color="primary"
              onChange={handleChange}
            />
          </div>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
