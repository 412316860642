import React, { useContext } from "react";

import Carousel from "react-bootstrap/Carousel";
import "./CarouselStyle.css";
import { HomeContext } from "../../Context/HomeContext.js";

export default function CarouselCard() {
  const { banners } = useContext(HomeContext);

  function handleRedirect(url) {
    window.open(url);
  }
  return (
    <React.Fragment>
      <Carousel
        className="carousal"
        style={{
          height: "400px",
          borderRadius: "5px",
          boxShadow: "0 0 5px",
        }}
        fade="true"
        fade-interval="5000"
        interval="2000"
      >
        {banners.map((image) => (
          <Carousel.Item>
            <img
              className="carousal"
              style={{ cursor: "pointer", borderRadius: "5px" }}
              className="d-block w-100"
              src={image.bannerImg}
              onClick={() => handleRedirect(image.redirectURL)}
              alt="First slide"
              width="100%"
              height="400px"
            />
          </Carousel.Item>
        ))}
      </Carousel>
    </React.Fragment>
  );
}
