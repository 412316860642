import React from 'react';
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import userPic from '../../assets/images/User.png';
import axiosClient from '../../helper';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { useFileUpload } from 'use-file-upload';
import PrimaryNavBar from '../../Components/PrimaryNavBar/PrimaryNavBar';
import NavBar from '../../Components/NavBar/NavBar';
import { useParams } from 'react-router-dom';

function Alert(props) {
	return <MuiAlert elevation={6} variant='filled' {...props} />;
}
export default function BloggerDetail() {
	const [open, setOpen] = React.useState(false);
	const [popupMsg, setPopupMsg] = React.useState('');
	const [snackbarColor, setSnackbarColor] = React.useState('');
	const [isBtndisable, setisBtndisable] = React.useState(false);
	const [image, setImage] = React.useState('');
	const { id } = useParams();

	const defaultSrc =
		'https://www.pngkit.com/png/full/301-3012694_account-user-profile-avatar-comments-fa-user-circle.png';

	const [files, selectFiles] = useFileUpload();

	const handleProfileImages = async (e) => {
		const files = e;
		const data = new FormData();
		data.append('file', files.file);
		data.append('upload_preset', 'productimages');

		// setLoaing(true);
		setisBtndisable(true);
		const res = await fetch(
			'https://api.cloudinary.com/v1_1/my-cloud-name/image/upload',
			{
				method: 'POST',
				body: data,
			}
		);
		let file = await res.json();
		setImage(file.secure_url);
		updateFormData({
			...formData,
			bloggerPicture: file.secure_url,
		});

		setisBtndisable(false);
	};

	const initialFormData = Object.freeze({
		aboutInfoBlogger: '',
		bloggerPicture: '',
		tweeterUrl: '',
		facebookUrl: '',
		instaUrl: '',
	});
	const [formData, updateFormData] = React.useState(initialFormData);
	const handleChangeData = (e) => {
		updateFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};
	React.useEffect(() => {
		axiosClient()
			.get(`/bloggerById/${id}`)
			.then((res) => {
				updateFormData({
					aboutInfoBlogger: res.data.data.aboutInfoBlogger,
					bloggerPicture: res.data.data.bloggerPicture,
					tweeterUrl: res.data.data.tweeterUrl,

					instaUrl: res.data.data.instaUrl,
					facebookUrl: res.data.data.facebookUrl,
				});
			})
			.catch((error) => {});
	}, []);

	const history = useHistory();
	function handleBlogDetail(event) {
		setisBtndisable(true);
		if (id) {
			axiosClient()
				.put(`/updateBloggerById/${id}`, formData)
				.then((response) => {
					if (response.data.status === '400') {
						setOpen(true);
						setPopupMsg(response.data.message);
						setSnackbarColor('error');
					} else {
						setOpen(true);
						setPopupMsg(response.data.message);
						setSnackbarColor('success');
						setisBtndisable(false);

						// history.push("/");
					}
				})
				.catch((error) => {
					alert('server error ', error);
				});
		} else {
			axiosClient()
				.post('/addBlogger', formData)
				.then((response) => {
					setisBtndisable(false);

					if (response.data.status === '400') {
						setOpen(true);
						setPopupMsg(response.data.message);
						setSnackbarColor('error');
					} else {
						setOpen(true);
						setPopupMsg(response.data.message);
						setSnackbarColor('success');
						updateFormData(initialFormData);
						localStorage.setItem('bloggerId', response.data.data._id);
						history.push('/postBlog');
					}
				})
				.catch((error) => {
					alert('server error signin', error);
					setisBtndisable(false);
				});
		}
	}

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};
	return (
		<React.Fragment>
			<PrimaryNavBar />
			<NavBar />
			<Grid container justify='center'>
				<Grid
					item
					xs={11}
					md={6}
					lg={5}
					style={{
						backgroundColor: 'white',
						height: 'auto',
						marginTop: '70px',
						boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
					}}
				>
					<Grid container justify='center' style={{ marginTop: '20px' }}>
						<h3>About Info</h3>
					</Grid>
					<Grid
						container
						item
						justify='center'
						xs={12}
						style={{ marginTop: '40px' }}
					>
						<Grid container item xs={11} md={8} lg={8} justify='center'>
							<div id='app'>
								<img
									className='profilePic'
									style={{
										width: '120px',
										height: '120px',
										padding: '10px',

										borderRadius: '50%',
									}}
									src={image || formData.bloggerPicture || userPic}
									alt='preview'
								/>

								<div>
									<button
										className='profilebtn'
										style={{
											borderRadius: '5px',
											color: 'black',
											backgroundColor: isBtndisable ? '#2f4f4f' : '#EFEFEF',
										}}
										type='submit'
										variant='contained'
										disabled={isBtndisable}
										onClick={() =>
											selectFiles({ accept: 'image/*' }, (FileUpload) => {
												handleProfileImages(FileUpload);
											})
										}
									>
										{isBtndisable ? ' Uploading...' : 'Upload Picture'}
									</button>
								</div>
							</div>
						</Grid>
						<Grid item xs={11} md={8} lg={9} style={{ marginTop: '10px' }}>
							<TextField
								id='outlined-multiline-static'
								label='Enter info *'
								value={formData.aboutInfoBlogger}
								name='aboutInfoBlogger'
								fullWidth
								onChange={handleChangeData}
								multiline
								rows={6}
								variant='outlined'
							/>
						</Grid>
						<Grid item xs={11} md={8} lg={9} style={{ marginTop: '10px' }}>
							<TextField
								variant='outlined'
								required
								fullWidth
								id='facebookUrl'
								placeholder='Enter your facebook Url'
								value={formData.facebookUrl}
								name='facebookUrl'
								autoComplete='facebookUrl'
								onChange={handleChangeData}
							/>
						</Grid>
						<Grid item xs={11} md={8} lg={9} style={{ marginTop: '10px' }}>
							<TextField
								variant='outlined'
								required
								fullWidth
								placeholder='Enter your instagram Url'
								id='instaUrl'
								value={formData.instaUrl}
								name='instaUrl'
								autoComplete='instaUrl'
								onChange={handleChangeData}
							/>
						</Grid>
						<Grid item xs={11} md={8} lg={9} style={{ marginTop: '10px' }}>
							<TextField
								variant='outlined'
								required
								fullWidth
								placeholder='Enter your tweeter Url'
								id='tweeterUrl'
								value={formData.tweeterUrl}
								name='tweeterUrl'
								autoComplete='tweeterUrl'
								onChange={handleChangeData}
							/>
						</Grid>
						<Grid item xs={6} style={{ marginTop: '30px' }}>
							<Button
								style={{
									background: isBtndisable ? '#2f4f4f' : '#1979C3',

									color: 'white',
									marginBottom: '40px',
								}}
								type='submit'
								fullWidth
								variant='contained'
								disabled={isBtndisable}
								onClick={handleBlogDetail}
							>
								{isBtndisable ? 'Please Wait...' : id ? 'Update' : 'Submit'}
							</Button>
						</Grid>
					</Grid>
				</Grid>

				<Snackbar
					anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
					open={open}
					autoHideDuration={5000}
					onClose={handleClose}
				>
					<Alert onClose={handleClose} severity={snackbarColor}>
						{popupMsg}
					</Alert>
				</Snackbar>
			</Grid>
			<div style={{ marginTop: '30px' }}></div>
		</React.Fragment>
	);
}
