import React, { useContext } from "react";

import Container from "@material-ui/core/Container";
import Load from "../../Components/Loader/loader";
// import "./courseCheckout.css";
import { useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";

import JobCard from "../../Components/jobCards/jobCard";
import Button from "@material-ui/core/Button";
import { CompanyContext } from "../../Context/companyContext";
import PrimaryNavBar from "../../Components/PrimaryNavBar/PrimaryNavBar";
import NavBar from "../../Components/NavBar/NavBar";
import { CourseContext } from "../../Context/CourseContext";
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";

import PaypalBtn from "../../Components/paypal";
import { PostApiContext } from "../../Context/PostApiContext";
import { HomeContext } from "../../Context/HomeContext";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LabelIcon from "@material-ui/icons/Label";
import MovieIcon from "@material-ui/icons/Movie";
import EmptyVideo from "../../assets/images/emptyVideo.jpg";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import DescriptionIcon from "@material-ui/icons/Description";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import CourseVideo from "../../Components/courseVideo/courseVideo";
import QuizComp from "../../Components/QuizComp/quizComp";
import Certificate from "../../Components/certificate/certificate";
import "./quiz.css";
import Modal from "@material-ui/core/Modal";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  root: {
    width: "100%",
    marginTop: "15px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(17),

    flexBasis: "33.33%",
    flexShrink: 0,
  },
  heading2: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    // color: "#023e8a",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  rightAns: {
    backgroundColor: "#caffb9",
    marginTop: "20px",
    border: "1px solid gainsboro",
    borderRadius: "5px",
    padding: "10px",
    color: "black",

    /* background-color: rgb(250, 250, 255); */
    cursor: "pointer",
  },

  wrongAns: {
    backgroundColor: "#F6B6BB",
    marginTop: "20px",
    border: "1px solid gainsboro",
    borderRadius: "5px",
    padding: "10px",
    color: "black",
    /* background-color: rgb(250, 250, 255); */
    cursor: "pointer",
  },
  rootmodal: {
    height: 300,
    flexGrow: 1,
    minWidth: 300,
    transform: "translateZ(0)",
    // The position fixed scoping doesn't work in IE 11.
    // Disable this demo to preserve the others.
    "@media all and (-ms-high-contrast: none)": {
      display: "none",
    },
  },
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function CompanyProfile() {
  const classes = useStyles();
  const rootRef = React.useRef(null);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const { id } = useParams();
  const [value, setValue] = React.useState(4);
  const { courseIds } = useContext(HomeContext);

  const [alert, setAlert] = React.useState(false);
  const { Loader, onlineCourseCheckout, setOnlineCourseId, courseModules } =
    useContext(CourseContext);

  const { onlineCourseThumbnail, courseName, price, courseDescription } =
    onlineCourseCheckout;

  const [isPurchased, setIsPurchased] = React.useState(false);
  const [videoUrl, setVideoUrl] = React.useState("");
  const [name, setName] = React.useState("");

  const { userName } = useContext(PostApiContext);
  // console.log("userNAme:",userName)
  const [moduleCount, setModuleCount] = React.useState(0);
  const [open, setOpen] = React.useState(true);
  const [isMcqCorrect, setisMcqCorrect] = React.useState({});
  const [choosedOption, setChoosedOption] = React.useState({});
  const [alpha, setAlpha] = React.useState([]);
  React.useEffect(() => {
    window.scrollTo(0, 0);
    setOnlineCourseId(id);
    // setVideoUrl(courseModules[0].courseVideos[0].videoURL);
  }, []);
  const history = useHistory();

  function handleCheckout() {
    setAlert(true);
    // history.push("/courseVideo");
  }
  function handleVideo(url, name) {
    window.scrollTo(0, 0);

    setDisplayVideo(true);
    setVideoUrl(url);
    setName(name);
  }

  function handleCheckMcq(options) {
    setChoosedOption(options);
    // setChoosedOption(true);

    // options.isCorrect ? setisMcqCorrect(true) : setisMcqCorrect(false);
    // setisMcqCorrect(true);
    // setIsClicked(true);
  }
  function handleSubmitQuiz() {
    setScore(choosedOption.isCorrect + 1);
  }

  const handleNavigate = () => {
    history.push("/courseVideo");
  };

  const [quiz, setQuiz] = React.useState([]);
  const [quizTitle, setQuizTitle] = React.useState("");
  const [currentQuestion, setCurrentQuestion] = React.useState(0);
  const [showScore, setShowScore] = React.useState(false);
  const [displayVideo, setDisplayVideo] = React.useState(true);

  const [score, setScore] = React.useState(0);
  function handleQuiz(quizz) {
    window.scrollTo(0, 0);

    setScore(0);
    setDisplayVideo(false);
    setCurrentQuestion(0);
    setShowScore(false);
    setQuiz(quizz.questions);
    setQuizTitle(quizz.quizTitle);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <PrimaryNavBar />
      <NavBar />
      <Container style={{ marginTop: "40px", marginBottom: "190px" }}>
        {Loader ? (
          <Grid
            container
            justify="space-between"
            xs={12}
            container
            direction="row"
          >
            <Grid item container>
              <h3>
                {" "}
                <b> {courseName} </b>
              </h3>
            </Grid>
            <Grid
              xs={12}
              md={6}
              lg={6}
              item
              container
              style={{ marginTop: "5px", marginBottom: "30px" }}
            >
              <Grid item xs={12} md={12} lg={12}>
                {displayVideo ? (
                  <>
                    <h5 style={{ textAlign: "center", marginTop: "20px" }}>
                      {name}
                    </h5>
                    {videoUrl ? (
                      <CourseVideo
                        videoUrl={videoUrl}
                        courseDescription={courseDescription}
                      />
                    ) : (
                      <img
                        src={onlineCourseThumbnail}
                        width="100%"
                        height="300px"
                      />
                    )}
                  </>
                ) : (
                  <QuizComp
                    quiz={quiz}
                    currentQuestion={currentQuestion}
                    quizTitle={quizTitle}
                    showScore={showScore}
                    score={score}
                    setScore={setScore}
                    setCurrentQuestion={setCurrentQuestion}
                    setShowScore={setShowScore}
                  />
                )}
              </Grid>
            </Grid>

            <Grid
              class="scrollbar"
              id="style-8"
              item
              xs={12}
              sm={6}
              md={5}
              lg={4}
              style={{ marginTop: "30px" }}
            >
              <h5>
                <b>Modules:</b>
              </h5>
              {courseModules.map((module) => (
                <div className={classes.root}>
                  <Accordion
                    expanded={expanded === module._id}
                    onChange={handleChange(module._id)}
                    id={module._id}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography className={classes.heading}>
                        <b> {module.moduleName}</b>
                      </Typography>
                      <Typography className={classes.secondaryHeading}>
                        {module.moduleDescription}
                      </Typography>
                    </AccordionSummary>

                    {module.courseVideos.map((video) => (
                      <AccordionDetails>
                        <Typography
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleVideo(video.videoURL, video.videoTitle)
                          }
                        >
                          <MovieIcon style={{ color: "#e63946" }} />{" "}
                          {video.videoTitle}
                        </Typography>
                      </AccordionDetails>
                    ))}
                    {module.quiz ? (
                      <AccordionDetails>
                        <Typography
                          style={{ cursor: "pointer" }}
                          onClick={() => handleQuiz(module.quiz)}
                        >
                          <DescriptionIcon style={{ color: "gray" }} />
                          {module.quiz.quizTitle}
                        </Typography>
                      </AccordionDetails>
                    ) : null}

                    {module.subModules.map((sub) => (
                      <div style={{ backgroundColor: "#F3F4F7" }}>
                        {/* <Accordion
                            expanded={expanded === sub._id}
                            onChange={handleChanges(sub._id)}
                            id={sub._id}
                          > */}
                        <AccordionSummary
                        // expandIcon={<ExpandMoreIcon />}
                        // aria-controls="panel2bh-content"
                        // id="panel2bh-header"
                        >
                          <Typography
                            className={classes.heading2}
                            style={{ marginLeft: "15px" }}
                          >
                            <FiberManualRecordIcon
                              style={{ fontSize: "10px" }}
                            />{" "}
                            <b> {sub.subModuleName}</b>
                          </Typography>
                          <Typography className={classes.secondaryHeading}>
                            {sub.subModuleDescription}
                          </Typography>
                        </AccordionSummary>
                        {sub.courseVideos.map((video) => (
                          <AccordionDetails style={{ marginLeft: "50px" }}>
                            <Typography
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                handleVideo(video.videoURL, video.videoTitle)
                              }
                            >
                              <MovieIcon style={{ color: "#e63946" }} />{" "}
                              {video.videoTitle}
                              {/* <h2>hi g</h2> */}
                            </Typography>
                          </AccordionDetails>
                        ))}
                        {/* {sub.quiz ? (
                          <AccordionDetails style={{ marginLeft: "50px" }}>
                            <Typography
                              style={{ cursor: "pointer" }}
                              onClick={() => handleQuiz(sub.quiz)}
                            >
                              <DescriptionIcon style={{ color: "gray" }} />
                              {sub.quiz.QuizTitle}
                            </Typography>
                          </AccordionDetails>
                        ) : null} */}
                        {/* </Accordion> */}
                      </div>
                    ))}
                  </Accordion>
                </div>
              ))}
            </Grid>
            {/* {showScore ? (
              // <Grid item xs={12} style={{marginTop:"40px"}}>
              //  <Certificate name={userName} />

              //    </Grid>
              <div className={classes.root} ref={rootRef}>
                <Modal
                  disablePortal
                  disableEnforceFocus
                  disableAutoFocus
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="server-modal-title"
                  aria-describedby="server-modal-description"
                  className={classes.modal}
                  container={() => rootRef.current}
                >
                  <div>
         
                    <Certificate name={userName} />
                  </div>
                </Modal>
              </div>
            ) : null} */}

            <SweetAlert
              show={alert}
              title="You have to buy course to get access!"
              onConfirm={handleNavigate}
              // onCancel={this.onCancel}
            >
              if you already PURCHASED, go to my courses
            </SweetAlert>
          </Grid>
        ) : (
          <div style={{ margin: "100px", marginBottom: "400px" }}>
            <Load />
          </div>
        )}
      </Container>
    </React.Fragment>
  );
}
