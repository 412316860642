import { Grid } from '@material-ui/core';
import React from 'react';

export default function feed() {
  return (
    <div>
      <form
        // style="border:1px solid #ccc;padding:3px;text-align:center;"
        style={{
          border: '1px solid gray',
          padding: '5px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-evenly'
          // textAlign: 'center'
        }}
        action="https://feedburner.google.com/fb/a/mailverify"
        method="post"
        target="popupwindow"
        onsubmit="window.open('https://feedburner.google.com/fb/a/mailverify?uri=nursingworldnigeria/AZsR', 'popupwindow', 'scrollbars=yes,width=550,height=520');return true"
      >
        <p style={{ textAlign: 'center' }}>Enter your email address:</p>

        <input
          type="text"
          style={{ height: '35px', width: '40%' }}
          name="email"
        />

        <input type="hidden" value="nursingworldnigeria/AZsR" name="uri" />
        <input type="hidden" name="loc" value="en_US" />
        <input
          style={{
            height: '35px',
            color: 'white',
            backgroundColor: '#CC0000',
            border: 'none'
          }}
          type="submit"
          value="Subscribe"
        />
      </form>
    </div>
  );
}
