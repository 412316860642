import React, { useState, createContext } from 'react';
import axiosClient from '../helper';

export const CourseContext = createContext();

const CourseProvider = ({ children }) => {
	const [course, setCourse] = useState({});
	const [courseCheckout, setCourseCheckout] = useState({});
	const [onlineCourseCheckout, setOnlineCourseCheckout] = useState({});
	const [onlineCourseId, setOnlineCourseId] = useState('');

	const [courseId, setCourseId] = useState('');
	const [courseCheckoutId, setCourseCheckoutId] = useState('');

	const [courseVideo, setCourseVideo] = useState([]);
	const [onlineCourses, setOnlineCourses] = useState([]);
	const [courseModules, setCourseModules] = React.useState([]);

	const [myCourses, setMyCourses] = useState([]);
	const [myCoursesId, setMyCourseId] = useState([]);

	const [Loader, setLoader] = React.useState(false);

	// React.useEffect(() => {
	//   setLoader(false);

	//   if (myCoursesId !== "") {
	//     axiosClient()
	//       .get(`/getMyCourseVideos/${myCoursesId}`)
	//       .then((res) => {
	//         setMyCourses(res.data.data);

	//         setLoader(true);
	//       })
	//       .catch((error) => {
	//       });
	//   }
	// }, [myCoursesId]);

	React.useEffect(() => {
		setLoader(false);

		axiosClient()
			.get(`/GetAllOnlineCourse`)
			.then((res) => {
				setOnlineCourses(res.data.data);
				setLoader(true);
			})
			.catch((error) => {});
	}, []);

	React.useEffect(() => {
		setLoader(false);

		if (courseCheckoutId !== '') {
			axiosClient()
				.get(`/getCourseVideoById/${courseCheckoutId}`)
				.then((res) => {
					setCourseCheckout(res.data.data);

					setLoader(true);
				})
				.catch((error) => {});
		}
	}, [courseCheckoutId]);

	React.useEffect(() => {
		setLoader(false);

		if (onlineCourseId !== '') {
			axiosClient()
				.get(`/onlineCourseById/${onlineCourseId}`)
				.then((res) => {
					setOnlineCourseCheckout(res.data.data);
					setCourseModules(res.data.data.modules);

					setLoader(true);
				})
				.catch((error) => {});
		}
	}, [onlineCourseId]);

	React.useEffect(() => {
		setLoader(false);

		if (courseId !== '') {
			axiosClient()
				.get(`/getCourseById/${courseId}`)
				.then((res) => {
					setCourse(res.data.data);

					setLoader(true);
				})
				.catch((error) => {});
		}
	}, [courseId]);

	React.useEffect(() => {
		setLoader(false);

		axiosClient()
			.get(`/getAllCourseVideos`)
			.then((res) => {
				setCourseVideo(res.data.data);
				setLoader(true);
			})
			.catch((error) => {});
	}, []);
	return (
		<CourseContext.Provider
			value={{
				onlineCourses,
				course,
				courseCheckout,
				myCourses,
				setMyCourseId,
				setCourseCheckoutId,
				setCourseId,
				onlineCourseCheckout,
				courseModules,

				setOnlineCourseId,
				courseVideo,
				Loader,
			}}
		>
			{children}
		</CourseContext.Provider>
	);
};

export default CourseProvider;
