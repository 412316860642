import axios from "axios";

let BASE_URL = "";
console.log("process.env.BASE_URL_DEV:", process.env.REACT_APP_BASE_URL_DEV);
if (process.env.NODE_ENV === "development") {
  // BASE_URL = process.env.REACT_APP_BASE_URL_DEV;

  // BASE_URL = "http://4eba-182-185-231-47.ngrok.io";
  BASE_URL = process.env.REACT_APP_BASE_URL_PROD;
}

if (process.env.NODE_ENV === "production") {
  BASE_URL = process.env.REACT_APP_BASE_URL_PROD;
}
export default function axiosClient() {
  let defaultOptions = {
    baseURL: BASE_URL,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      accept: "application/json",
    },
  };

  let instance = axios.create(defaultOptions);

  //

  //Set the AUTH token for any request
  instance.interceptors.request.use(function (config) {
    // const token = localStorage.getItem("token");
    // config.headers.Authorization = token;
    config.headers.common = {
      "x-auth-token": `${localStorage.getItem("token")}`,
    };
    return config;
  });

  return instance;
}
