import React, { useContext } from 'react';

import { Grid, Container } from '@material-ui/core';

import { CourseContext } from '../../Context/CourseContext';
import ReactPlayer from 'react-player';

import PrimaryNavBar from '../../Components/PrimaryNavBar/PrimaryNavBar';
import NavBar from '../../Components/NavBar/NavBar';
import Load from '../../Components/Loader/loader';
import { useParams } from 'react-router-dom';

import { useHistory } from 'react-router-dom';
import { PostApiContext } from '../../Context/PostApiContext';
import { HomeContext } from '../../Context/HomeContext';

export default function OnlineCourse() {
  // const { id } = useParams();
  const [value, setValue] = React.useState(4);

  const { courseIds, setIsCoursePurchased, isCoursePurchased, Loader } = useContext(HomeContext);
  const { _id } = courseIds;

  // const { myCourses, Loader, setMyCourseId } = useContext(CourseContext);

  React.useEffect(() => {
    setIsCoursePurchased(!isCoursePurchased);

    window.scrollTo(0, 0);
    // setMyCourseId(courseIds);
  }, []);
  const history = useHistory();

  //   function handleCheckout(id) {
  //     console.log("id:", id);
  //     localStorage.getItem("token")
  //       ? history.push(`/courseCheckout/${id}`)
  //       : history.push("/SignIn");
  //   }
  function handleMyCourseDetail(id) {
    history.push(`/myCourseDetail/${id}`);
  }
  return (
    <React.Fragment>
      <PrimaryNavBar />
      <NavBar />
      <Container>
        <h2 style={{ marginTop: '40px' }} className="super">
          My Courses
        </h2>
        {courseIds.length >= 1 ? (
          <Grid container>
            <Grid item container spacing={3} style={{ marginTop: '50px', marginBottom: '150px' }}>
              {courseIds.map((course) => (
                <Grid item xs={12} md={6} lg={4}>
                  <img
                    className="newsimg"
                    style={{ cursor: 'pointer' }}
                    src={course.onlineCourseThumbnail}
                    width="100%"
                    height="200px"
                    onClick={() => handleMyCourseDetail(course._id)}
                  />
                  {/* <ReactPlayer
                    style={{ cursor: "pointer" }}
                    controls="true"
                    light={course.videoThumbnail}
                    className="videoplayer"
                    url={course.videoURL}
                    // onClick={() => handleCheckout(course._id)}
                  /> */}
                  <h4
                    style={{
                      marginTop: '5px'
                    }}
                  >
                    <b> {course.courseName}</b>
                  </h4>
                  {/* <Box component="fieldset" mb={3} borderColor="transparent">
                    <Rating name="read-only" value={value} readOnly />
                  </Box>
                  <h4>$ {course.price}</h4> */}
                </Grid>
              ))}
            </Grid>
          </Grid>
        ) : (
          <Grid container justify="center" style={{ marginTop: '150px', marginBottom: '350px' }}>
            <h2>YOU DONT HAVE ANY COURSES YET!</h2>
          </Grid>
        )}
      </Container>
    </React.Fragment>
  );
}
