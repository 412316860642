import React, { useState, createContext } from 'react';
import axiosClient from '../helper';

export const NewsContext = createContext();

const NewsProvider = ({ children }) => {
	const [NewsId, setNewsId] = useState('');
	const [NewsDetail, setNewsDetail] = useState({});
	const [Loader, setLoader] = React.useState(false);
	const [companyNewsId, setCompanyNewsId] = useState('');
	const [companyNews, setCompanyNews] = useState([]);
	const [companyInfo, setCompanyInfo] = useState('');
	const [relatedNews, setRelatedNews] = useState([]);

	React.useEffect(() => {
		setLoader(false);

		if (companyNewsId !== '') {
			axiosClient()
				.get(`/getNewsBycompanyId/${companyNewsId}`)
				.then((res) => {
					setCompanyNews(res.data.data);

					setCompanyInfo(res.data.company);
					setLoader(true);
				})
				.catch((error) => {});
		}
	}, [companyNewsId]);

	React.useEffect(() => {
		setLoader(false);
		if (NewsId !== '') {
			axiosClient()
				.get(`/getNewsById/${NewsId}`)
				.then((res) => {
					setNewsDetail(res.data.data);
					setRelatedNews(res.data.relatedNews);

					setLoader(true);
				})
				.catch((error) => {});
		}
	}, [NewsId]);

	return (
		<NewsContext.Provider
			value={{
				setNewsId,
				relatedNews,
				NewsDetail,
				Loader,
				setCompanyNewsId,
				companyNews,
				companyInfo,
			}}
		>
			{children}
		</NewsContext.Provider>
	);
};

export default NewsProvider;
