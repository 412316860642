import React, { useContext } from 'react';

import Container from '@material-ui/core/Container';

import BlogCard from '../../Components/BlogCard/BlogCard';
import ProfileCard from '../../Components/ProfileCard/ProfileCard';
import Load from '../../Components/Loader/loader';
import { useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import './Profile.css';
import { BloggerContext } from '../../Context/BloggerContext';
import PrimaryNavBar from '../../Components/PrimaryNavBar/PrimaryNavBar';
import NavBar from '../../Components/NavBar/NavBar';
export default function BloggerProfile() {
  const { setBloggerId, BloggerInfo, BloggerProfile, Loader } =
    useContext(BloggerContext);

  const { id } = useParams();

  React.useEffect(() => {
    window.scrollTo(0, 0);

    setBloggerId(id);
  }, []);

  return (
    <React.Fragment>
      <PrimaryNavBar />
      <NavBar />
      <Container style={{ marginTop: '40px' }}>
        {Loader ? (
          <Grid xs={12} container direction="row" justify="center">
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              style={{
                marginTop: '13px',
                marginBottom: '20px',
                marginLeft: '10px'
              }}
            >
              <ProfileCard BloggerProfile={BloggerProfile} />
            </Grid>
            <Grid
              xs={12}
              md={8}
              lg={8}
              container
              // justify="space-between"
              spacing={2}
              style={{ marginTop: '5px', marginBottom: '30px' }}
            >
              {/* <Grid item xs={12}>
                <h1 className="super">Profile</h1>
              </Grid> */}
              {BloggerInfo.map((blog) => (
                <Grid item xs={12} sm={6} md={4} lg={6}>
                  <div>
                    <BlogCard BlogDetail={blog} />
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        ) : (
          <div style={{ marginTop: '160px', marginBottom: '400px' }}>
            <Load />
          </div>
        )}
      </Container>
    </React.Fragment>
  );
}
