import React from "react";

import { Grid } from "@material-ui/core";

import ProfileCard from "../../Components/ProfileCard/ProfileCard";
import "../BlogPage/blogPage.css";
export default function BlogPage() {
  const [scrolling, setScrolling] = React.useState(false);
  const [scrollTop, setScrollTop] = React.useState(0);
  React.useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
      setScrolling(e.target.documentElement.scrollTop > scrollTop);
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);
  return (
    <React.Fragment>
      <div></div>
      <Grid container justify="center" xs={12} style={{ marginTop: "55px" }}>
        <Grid className="blogcard" item xs={12} md={8} lg={8}>
          <ProfileCard />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
