import React, { useContext } from "react";

import { Grid, Container, SnackbarContent } from "@material-ui/core";
import {
  cLogo1,
  cLogo2,
  cLogo3,
  cLogo4,
  cLogo5,
  cLogo6,
  cLogo7,
  cLogo8,
  cLogo9,
  cLogo10,
  cLogo11,
  cLogo12,
  cLogo13,
  cLogo14,
  cLogo15,
  cLogo16,
  cLogo17,
  cLogo18,
  cLogo19,
  cLogo20,
} from "../../assets/images/contactUsLogos/index";
import Slider from "react-slick";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import axiosClient from "../../helper";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import "./contactUs.css";

import ReCAPTCHA from "react-google-recaptcha";
import PrimaryNavBar from "../../Components/PrimaryNavBar/PrimaryNavBar";
import NavBar from "../../Components/NavBar/NavBar";
import { HomeContext } from "../../Context/HomeContext";

const images = [
  cLogo1,
  cLogo2,
  cLogo3,
  cLogo4,
  cLogo5,
  cLogo6,
  cLogo7,
  cLogo8,
  cLogo9,
  cLogo10,
  cLogo11,
  cLogo12,
  cLogo13,
  cLogo14,
  cLogo15,
  cLogo16,
  cLogo17,
  cLogo18,
  cLogo19,
  cLogo20,
];
const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  autoplay: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,

  responsive: [
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
      },
    },
  ],
};
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function ContactUs() {
  const { clientLogos } = useContext(HomeContext);

  const [open, setOpen] = React.useState(false);
  const [popupMsg, setPopupMsg] = React.useState("");
  const [snackbarColor, setSnackbarColor] = React.useState("");
  const [isBtndisable, setisBtndisable] = React.useState(false);

  const initialFormData = Object.freeze({
    name: "",
    email: "",
    subject: "",
    message: "",
    captcha: "",
  });
  const [formData, updateFormData] = React.useState(initialFormData);
  const handleChangeData = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  function onChange(value) {
    updateFormData({
      ...formData,
      captcha: value,
    });
    console.log("Captcha value:", value);
  }
  let captch;

  const setCaptchaRef = (ref) => {
    if (ref) {
      return (captch = ref);
    }
  };

  function handleContactForm(event) {
    setisBtndisable(true);

    axiosClient()
      .post("/contactUs", formData)
      .then((response) => {
        //getting response after hiting api
        //show error if user is not created

        setisBtndisable(false);

        if (response.data.status === "400") {
          setOpen(true);
          setPopupMsg(response.data.message);
          setSnackbarColor("error");
          //show success msg if user created .
        } else {
          setOpen(true);
          setPopupMsg("Form Submitted Successfully");
          setSnackbarColor("success");
          updateFormData(initialFormData);
          console.log("new data", formData);
          captch.reset();
        }
      })
      .catch((error) => {
        alert("server error signin", error);
      });
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // const onlyNumbers = (e) => {
  //     e.target.value = e.target.value.replace(/[^0-9]/g, "");
  //   };
  return (
    <React.Fragment>
      <PrimaryNavBar />
      <NavBar />
      <Container>
        <Grid container xs={12} justify="space-between">
          <Grid
            item
            container
            xs={12}
            md={5}
            lg={6}
            style={{ marginTop: "80px", marginBottom: "30px" }}
          >
            <Grid item xs={12}>
              <h2 className="super">Contact Us :</h2>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                backgroundColor: "white",

                borderRadius: "3px",
              }}
            >
              <Grid item xs={12} style={{ marginTop: "10px" }}>
                <h6>Name</h6>

                {/* creating text feild for First Name */}
                <TextField
                  autoComplete="name"
                  name="name"
                  variant="outlined"
                  onChange={handleChangeData}
                  value={formData.name}
                  required
                  fullWidth
                  placeholder="Full Name"
                  id="name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} style={{ marginTop: "10px" }}>
                <h6>Email</h6>

                {/* creating text feild for First Name */}
                <TextField
                  autoComplete="email"
                  name="email"
                  variant="outlined"
                  required
                  fullWidth
                  onChange={handleChangeData}
                  value={formData.email}
                  placeholder="Enter Email"
                  id="email"
                />
              </Grid>
              {/* <Grid item xs={12} style={{ marginTop: "10px" }}>
                <h6>Phone </h6>

                creating text feild for First Name
                <TextField
                  autoComplete="phoneNumber"
                  name="phoneNumber"
                  variant="outlined"
                  onInput={(e) => onlyNumbers(e)}
                  required
                  fullWidth
                  placeholder="Enter Phone"
                  id="phoneNumber"
                  autoFocus
                />
              </Grid> */}
              <Grid item xs={12} style={{ marginTop: "10px" }}>
                <h6>Subject</h6>

                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="subject"
                  placeholder="Subject"
                  onChange={handleChangeData}
                  value={formData.subject}
                  name="subject"
                  autoComplete="subject"
                />
              </Grid>
              <Grid item xs={12} style={{ marginTop: "10px" }}>
                <h6>Message</h6>
                <TextField
                  id="outlined-multiline-static"
                  onChange={handleChangeData}
                  value={formData.message}
                  placeholder="Your Message"
                  name="message"
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} style={{ marginTop: "10px" }}>
                <ReCAPTCHA
                  style={{ width: "600px" }}
                  ref={(r) => setCaptchaRef(r)}
                  sitekey="6Lel4Z4UAAAAAOa8LO1Q9mqKRUiMYl_00o5mXJrR"
                  value={formData.captcha}
                  name="captcha"
                  onChange={onChange}
                />
              </Grid>

              <Grid item xs={12} style={{ marginTop: "10px" }}>
                <Button
                  style={{
                    // background: "#1979C3",
                    background: isBtndisable ? "#2f4f4f" : "#1979C3",

                    float: "right",
                    color: "white",
                    marginBottom: "40px",
                  }}
                  type="submit"
                  variant="contained"
                  disabled={isBtndisable}
                  onClick={handleContactForm}
                >
                  {/* Submit */}
                  {isBtndisable ? "Please Wait..." : "Submit"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={6}
            lg={5}
            style={{ marginTop: "80px", marginBottom: "30px" }}
          >
            <Grid item xs={12}>
              <h2 className="super">FEEL FREE TO VISIT US :</h2>
              <h3>Office Address:</h3>

              <div style={{ color: "gray", marginTop: "30px" }}>
                <h5>Lagos: 48 Allen Avenue, Ikeja, Lagos.</h5>
                <h5>
                  Delta: 35 Airport Road, Edjeba Junction, Warri/ South Gate
                  Hotel Asaba.
                </h5>

                <h5>
                  Rivers: 10 Glorious Avenue by Christ Chapel Road, Off
                  Igbo-etche road Portharcourt.
                </h5>
              </div>
              <div style={{ color: "gray", marginTop: "20px" }}>
                <h5>Email: info@nursingworldnigeria.com</h5>
                <h5>GSM: 08131183065</h5>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            container
            justify="center"
            style={{ marginTop: "40px" }}
          ></Grid>
          <Grid item style={{ marginTop: "40px" }}>
            <h2 className="super">Our Clients</h2>
          </Grid>
          <Grid item xs={12}>
            <div style={{ marginTop: "20px" }}>
              <Slider {...settings}>
                {clientLogos.map((image) => (
                  <div>
                    <img
                      className="slick"
                      style={{ cursor: "pointer" }}
                      src={image.clientLogo}
                      width="400px"
                      height="300px"
                    />
                  </div>
                ))}
              </Slider>
            </div>
          </Grid>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity={snackbarColor}>
              {popupMsg}
            </Alert>
          </Snackbar>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
