import React, { useContext } from "react";

import { Grid, Container } from "@material-ui/core";

import { CourseContext } from "../../Context/CourseContext";
import ReactPlayer from "react-player";

import PrimaryNavBar from "../../Components/PrimaryNavBar/PrimaryNavBar";
import NavBar from "../../Components/NavBar/NavBar";
import Load from "../../Components/Loader/loader";
import { useParams } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import { useHistory } from "react-router-dom";
import { PostApiContext } from "../../Context/PostApiContext";
import { HomeContext } from "../../Context/HomeContext";
import "./quiz.css";
export default function OnlineCourse() {
  const { courseIds, setIsCoursePurchased, isCoursePurchased, Loader } =
    useContext(HomeContext);

  //   console.log("courseIds", courseIds);

  const history = useHistory();

  return (
    <React.Fragment>
      <PrimaryNavBar />
      <NavBar />
      <Container>
        <h2 style={{ marginTop: "40px" }} className="super">
          Quiz Title
        </h2>
        <Grid
          container
          xs={12}
          style={{
            border: "1px solid gray",
            marginTop: "30px",
            borderRadius: "5px",
            padding: "10px",
            marginBottom: "40px",
          }}
        >
          <Grid item xs={12}>
            <h4>
              {" "}
              <b className="optags">Q:</b>What is the Color of Water ?
            </h4>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={12} className="options">
              <h5>
                {" "}
                <b className="optags">a. </b>
                White
              </h5>
            </Grid>
            <Grid item xs={12} className="options">
              <h5>
                {" "}
                <b className="optags">b. </b>Blue
              </h5>
            </Grid>
            <Grid item xs={12} className="options">
              <h5>
                {" "}
                <b className="optags">c. </b>Transparent
              </h5>
            </Grid>
            <Grid item xs={12} className="options">
              <h5>
                {" "}
                <b className="optags">d. </b> All of Above
              </h5>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

{
  /* {courseIds.map((course) => (
                <Grid item xs={12} md={6} lg={4}>
                 
                 
               
                  
                </Grid>
              ))} 
              
              
              
              */
}

{
  /* {quiz.map((Quiz) => (
                <Grid
                  item
                  container
                  xs={12}
                  style={{
                    border: "1px solid gray",
                    marginTop: "20px",
                    borderRadius: "5px",
                    padding: "10px",
                    marginBottom: "40px",
                  }}
                >
                  <Grid item xs={12}>
                    <h4>
                      {" "}
                      <b className="optags">Q:</b>
                      {Quiz.description}
                    </h4>
                  </Grid>

                  {Quiz.options.map((options) => (
                    <Grid item container xs={12}>
                      <Grid
                        item
                        xs={12}
                        className="options"
                        // style={{
                        //   backgroundColor: isMcqCorrect ? "green" : "red",
                        // }}
                        // className={
                        //   options.text === choosedOption.text
                        //     ? classes.rightAns
                        //     : "options"
                        // }

                        className={
                          options.text === choosedOption.text
                            ? choosedOption.isCorrect
                              ? classes.rightAns
                              : classes.wrongAns
                            : "options"
                        }
                      >
                        <h5 onClick={() => handleCheckMcq(options)}>
                          {" "}
                          <b className="optags">
                            <FiberManualRecordIcon
                              style={{ fontSize: "10px" }}
                            />{" "}
                          </b>
                          {options.text}{" "}
                          {/* {choosedOption.isCorrect ? (
                            <CheckOutlinedIcon />
                          ) : null} */
}
{
  /* </h5>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              ))}  */
}
{
  /* {quizTitle ? (
                <Button
                  style={{
                    background: "#1979C3",
                    // background: isBtndisable ? "#2f4f4f" : "#1979C3",

                    // float: "right",
                    color: "white",
                    marginBottom: "40px",
                  }}
                  type="submit"
                  variant="contained"
                  // disabled={isBtndisable}
                  onClick={handleSubmitQuiz}
                >
                  Submit
                  {/* {isBtndisable ? "Please Wait..." : "Submit"} */
}
{
  /* </Button>
              ) : null}  */
}
