import React, { useRef, useEffect } from 'react';
import axiosClient from '../helper';
import { useParams } from 'react-router-dom';
import Modal from './success modals/success';
import SweetAlert from 'react-bootstrap-sweetalert';
import success from './success modals/success';
export default function Paypal(props) {
	const paypalRef = useRef();
	// const [success, setSuccess] = React.useState(false);
	// const [error, setError] = React.useState(false);

	// function handleSuccess() {
	//   setSuccess(false);
	// }
	useEffect(() => {
		const { price, _id } = props.courseDetails;

		window.paypal
			.Buttons(
				// { style: { color: "blue", shape: "pill" } },
				{
					style: { color: 'blue', shape: 'pill' },
					createOrder: (data, actions, err) => {
						return actions.order.create({
							// intent: "CAPTURE",
							purchase_units: [
								{
									amount: {
										currency_code: 'USD',
										value: parseFloat(price),
									},
								},
							],
						});
					},

					onApprove: async (data, actions) => {
						const order = await actions.order.capture();
						// props.setError("success");
						props.setSuccess(true);
						const payment = {
							amount: order.purchase_units[0].amount.value,
							status: order.status,
							payerId: order.payer.payer_id,
							paymentId: order.purchase_units[0].payments.captures[0].id,
							createdAt:
								order.purchase_units[0].payments.captures[0].create_time,
							courseId: _id,
						};
						axiosClient()
							.post('/addPayment', payment)
							.then((response) => {
								props.setIsPurchased(true);
								// setisBtndisable(false);
								// if (response.data.status === "400") {
								//   setOpen(true);
								//   setPopupMsg(response.data.message);
								//   setSnackbarColor("error");
								// } else {
								//   setOpen(true);
								//   setPopupMsg(response.data.message);
								//   setSnackbarColor("success");
								// updateFormData(initialFormData);
								// }
							})
							.catch((error) => {
								alert('server error Payment Api', error);
							});
					},
					onError: (err) => {
						// props.setError("error");
					},
				}
			)
			.render(paypalRef.current);
	}, []);
	return (
		<div>
			<div ref={paypalRef}></div>

			{/* <SweetAlert
          show={success}
          // type={error}
          title="Transaction Successful!"
          // onConfirm={() => setSuccess(false)}
          onConfirm={handleSuccess}

          // onCancel={this.onCancel}
        >
          Go to my courses
        </SweetAlert> */}
		</div>
	);
}
