import React, { useContext } from "react";

import Avatar from "@material-ui/core/Avatar";
//importing button from material ui
import Button from "@material-ui/core/Button";
//importing cssbase line from material ui
import CssBaseline from "@material-ui/core/CssBaseline";
//importing TextFeilds from material ui
import TextField from "@material-ui/core/TextField";
//importing Formlables from material ui
import FormControlLabel from "@material-ui/core/FormControlLabel";
//importing checkboxes from material ui
import Checkbox from "@material-ui/core/Checkbox";
//importing link from material ui
import Link from "@material-ui/core/Link";
//importing grid from material ui for sizing
import Grid from "@material-ui/core/Grid";
//importing box from material ui
import Load from "../../Components/Loader/spinLoader";
//importing icon from material ui
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
//importing typography from material ui for some headings or lables
import Typography from "@material-ui/core/Typography";
//importing styles from material ui for styling
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useHistory } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login";

import MuiAlert from "@material-ui/lab/Alert";
import axiosClient from "../../helper";
import Snackbar from "@material-ui/core/Snackbar";
import FacebookIcon from "@material-ui/icons/Facebook";
import axios from "axios";
import "./SignIn.css";
import { PostApiContext } from "../../Context/PostApiContext";
import PrimaryNavBar from "../../Components/PrimaryNavBar/PrimaryNavBar";
import NavBar from "../../Components/NavBar/NavBar";
import { HomeContext } from "../../Context/HomeContext";
const useStyles = makeStyles((theme) => ({
  // styling the form
  paper: {
    // giving some space from top
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    // align items to the center
    alignItems: "center",
    //chaning the background color of the bacground
    backgroundColor: "white",
    //giving some opacity to the background for look
    // opacity:'0.9',
    //giving space to feilds from the sides.
    padding: "20px",
    //curve the corners of the form
    borderRadius: "5px",
    //setting the height of the form
    height: "auto",
    //giving some drop shadow for the look.
    // boxShadow: "0px 0px 30px  ",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
    marginBottom: "30px",
  },
  //styling the icon of material ui.
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#1979C3",
  },
  // adjusting the size of form
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  // adjusting the size of submitt button
  submit: {
    margin: theme.spacing(9, 0, 2),
  },
}));
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function SignIn() {
  const { setSignInData, isBtndisable } = useContext(PostApiContext);
  const [loader, setLoader] = React.useState(true);
  const [opens, setOpenss] = React.useState(false);
  const [popupMsg, setPopupMsg] = React.useState("");
  const [snackbarColor, setSnackbarColor] = React.useState("");

  const classes = useStyles();
  const initialFormData = Object.freeze({
    email: "",
    password: "",
  });
  //getting data from the initial form
  const [signInData, updateSignInData] = React.useState(initialFormData);
  const handleChangeData = (e) => {
    updateSignInData({
      ...signInData,
      [e.target.name]: e.target.value.trim(),
    });
  };
  const history = useHistory();
  function handleregister() {
    history.push("/Signup");
  }
  function handleSignIn(event) {
    event.preventDefault();

    setSignInData(signInData);
  }
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenss(false);
  };
  let BASE_URL = "";

  if (process.env.NODE_ENV === "development") {
    BASE_URL = process.env.REACT_APP_BASE_URL_DEV;
  }

  if (process.env.NODE_ENV === "production") {
    BASE_URL = process.env.REACT_APP_BASE_URL_PROD;
  }

  const { setUserName } = useContext(HomeContext);
  const responseSuccessGoogle = (response) => {
    setLoader(false);

    axios({
      method: "POST",
      url: `${BASE_URL}/googleLogin`,

      // url: 'http://192.168.0.65:5000/googleLogin',
      data: { tokenId: response.tokenId },
    }).then((response) => {
      setUserName(response.data.data);
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("companyId", response.data.data.companyId);
      localStorage.setItem("bloggerId", response.data.data.bloggerId);
      setLoader(true);

      history.push("/");
    });
  };
  const responseErrorGoogle = (response) => {};
  const responseFacebook = (response) => {
    axios({
      method: "POST",
      // url: 'https://nursing-backend.herokuapp.com/facebookLogin',
      url: `${BASE_URL}/facebookLogin`,

      // url: 'http://localhost:5000/facebookLogin',
      data: { accessToken: response.accessToken, userID: response.userID },
    }).then((response) => {
      setUserName(response.data.data);
      console.log("check facebook");

      localStorage.setItem("token", response.data.token);
      localStorage.setItem("companyId", response.data.data.companyId);
      localStorage.setItem("bloggerId", response.data.data.bloggerId);
      history.push("/");
    });
  };
  return (
    <div>
      <PrimaryNavBar />
      <NavBar />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          {/* creating textfeilds for the form where user can enter his login details */}
          <form className={classes.form} noValidate>
            {/* creating text feild for email */}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              value={signInData.email}
              name="email"
              autoComplete="email"
              autoFocus
              onChange={handleChangeData}
            />
            {/* creating text feild for password */}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              value={signInData.password}
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handleChangeData}
            />
            {/* putting the checkbox  */}
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            {/* placing the signin button */}
            <Button
              style={{
                background: isBtndisable ? "#2F4F4F" : "#1979C3",
                color: "white",
              }}
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              disabled={isBtndisable}
              className={classes.submit}
              //clicking the button navigate you to signin page.
              onClick={handleSignIn}
            >
              {isBtndisable ? "Signing In..." : "Sign In"}
            </Button>

            {loader ? (
              <Grid xs={12} item container justify="center">
                <Grid item xs={12} className="socialbtn">
                  <GoogleLogin
                    clientId="412635427554-cedj1ohrbjplv7r156ka0aacl309j8v2.apps.googleusercontent.com"
                    buttonText="Login"
                    onSuccess={responseSuccessGoogle}
                    onFailure={responseErrorGoogle}
                    cookiePolicy={"single_host_origin"}
                    className="btnGoogle"
                  >
                    <span>&nbsp;&nbsp;Sign In with Google</span>
                  </GoogleLogin>
                </Grid>
                <Grid item xs={12} className="socialbtn">
                  <FacebookLogin
                    appId="448536963342308"
                    autoLoad={false}
                    cssClass="btnFacebook"
                    icon={<i> {<FacebookIcon />}</i>}
                    textButton="&nbsp;&nbsp;Sign In with Facebook"
                    callback={responseFacebook}
                  />
                </Grid>
                <Grid item xs></Grid>
                <Grid item>
                  {/* creating link for signup if you dont have a account */}
                  <Link href="#" variant="body2" onClick={handleregister}>
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            ) : (
              <div>
                <Load />
              </div>
            )}

            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              open={opens}
              autoHideDuration={5000}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity={snackbarColor}>
                {popupMsg}
              </Alert>
            </Snackbar>
          </form>
        </div>
      </Container>
    </div>
  );
}
