import React, { useContext } from 'react';

import { Grid, Container } from '@material-ui/core';

import { CourseContext } from '../../Context/CourseContext';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import PrimaryNavBar from '../../Components/PrimaryNavBar/PrimaryNavBar';
import NavBar from '../../Components/NavBar/NavBar';
import Load from '../../Components/Loader/loader';
import { useParams } from 'react-router-dom';
import Feed from '../../Components/feed';
export default function OnlineCourse() {
  const { id } = useParams();
  const { course, setCourseId, Loader } = useContext(CourseContext);
  const { courseName, courseDescription } = course;

  function handleFacebookRedirect() {
    window.open('https://www.facebook.com/NURSINGNIGERIA');
  }
  function handleLinkedInRedirect() {
    window.open('https://www.linkedin.com/in/nursingworld-nigeria-90668385/');
  }

  React.useEffect(() => {
    setCourseId(id);
  }, [id]);

  return (
    <React.Fragment>
      <PrimaryNavBar />
      <NavBar />
      <Container>
        <Grid container style={{ marginTop: '20px' }}>
          {' '}
          {Loader ? (
            <h2>{courseName}</h2>
          ) : (
            <div style={{ marginTop: '20px' }}>
              <Load />
            </div>
          )}
          <Grid
            item
            container
            justify="center"
            xs={12}
            style={{
              marginTop: '20px',
              padding: '10px',
              border: '2px solid gray',
              height: 'auto'
            }}
          >
            <Grid item xs={12} md={6} lg={6}>
              <Feed />
            </Grid>
            {/* <Grid item xs={12} md={5} lg={4}>
              <h6 style={{ color: "#CC0000" }}>
                <b>SUBSCRIBE TO FREE JOB ALERT</b>
              </h6>
              <TextField
                className="text"
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Enter Email to Subscribe"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12} md={1} lg={1}>
              <Button
                className="btnnn"
                style={{
                  background: "#CC0000",
                  color: "white",
                  height: "38px",
                  fontSize: "small",
                  marginTop: "27px",
                }}
                type="submit"
                fullWidth
                variant="contained"
              >
                SUBSCRIBE
              </Button>
            </Grid> */}
            <Grid item container justify="center" xs={12} md={6} lg={6}>
              <h6 className="mh" style={{ color: '#1979C3' }}>
                <b>FOLLOW US ON SOCIAL MEDIA</b>
              </h6>
              <Grid container justify="center" spacing={1}>
                <Grid item>
                  {' '}
                  <FacebookIcon
                    onClick={handleFacebookRedirect}
                    className="icons"
                    style={{ color: '#4867AA', cursor: 'pointer' }}
                  />
                </Grid>
                <Grid item>
                  <TwitterIcon
                    onClick={handleFacebookRedirect}
                    className="icons"
                    style={{ color: '#1DA1F2', cursor: 'pointer' }}
                  />
                </Grid>
                <Grid item>
                  {' '}
                  <LinkedInIcon
                    className="icons"
                    onClick={handleLinkedInRedirect}
                    style={{ color: '#0077B5', cursor: 'pointer' }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <h4 style={{ marginTop: '20px' }} className="super">
              Course Details:
            </h4>
          </Grid>
          {Loader ? (
            <Grid item xs={12}>
              <p style={{ textAlign: 'justify' }}>
                {' '}
                <p
                  dangerouslySetInnerHTML={{
                    __html: courseDescription
                  }}
                ></p>
              </p>
            </Grid>
          ) : (
            <Grid
              container
              justify="center"
              style={{ marginTop: '80px', marginBottom: '300px' }}
            >
              <Load />
            </Grid>
          )}
        </Grid>
      </Container>
    </React.Fragment>
  );
}
