import React, { useContext } from 'react';
import PrimaryNavBar from '../Components/PrimaryNavBar/PrimaryNavBar';
import NavBar from '../Components/NavBar/NavBar';
import page from '../assets/images/404.png';
import IconButton from '@material-ui/core/IconButton';
import { Button, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

export default function Page404() {
  // const history = useHistory();

  // function handleHome() {
  //   history.push('/');
  // }
  return (
    <>
      <PrimaryNavBar />
      {/* <NavBar /> */}
      <div>
        <img
          style={{ marginTop: '-80px' }}
          src={page}
          alt="preview"
          width="100%"
          height="900px"
        />
      </div>
      {/* <Grid style={{ textAlign: 'center' }}>
        <Button
          style={{
            border: '2px solid skyblue',
            position: 'absolute',
            marginRight: '30px',
            top: '110%'
          }}
          onClick={handleHome}
        >
          Back to home
        </Button>
      </Grid> */}
    </>
  );
}
