import React, { useContext } from "react";

import { useParams } from "react-router-dom";
import JobCard from "../../Components/jobCards/jobCard";
import Load from "../../Components/Loader/loader";
import { Container, Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import FacebookIcon from "@material-ui/icons/Facebook";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import { useHistory } from "react-router-dom";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { CompanyContext } from "../../Context/companyContext";
import { HomeContext } from "../../Context/HomeContext";
import PrimaryNavBar from "../../Components/PrimaryNavBar/PrimaryNavBar";
import NavBar from "../../Components/NavBar/NavBar";
import Carousel from "react-bootstrap/Carousel";
import Feed from "../../Components/feed";
import { Helmet } from "react-helmet";
import "./detailPage.css";

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
export default function DetailPage() {
  const {
    jobs,
    setJobLocation,
    setjobSpecialties,
    setJobType,
    link,
    setJobTypeR,
    setJobPageNo,
  } = useContext(HomeContext);

  const { setJobId, setCompanyId, jobInfo, Loader, relatedJobs } =
    useContext(CompanyContext);

  const {
    jobDetail,
    jobLocation,
    jobTitle,
    NursingSpecialties,
    jobType,
    categoryName,
  } = jobInfo;

  const { id } = useParams();

  const history = useHistory();

  function handleHome() {
    history.push("/");
  }
  function handleCompanyProfile(companyId) {
    setCompanyId(companyId);

    history.push(`/CompanyProfile/${companyId}`);
  }
  function handleJobLocation(jobLocation) {
    setJobLocation(jobLocation);

    history.push(`/ViewAllJobs/${jobLocation}`);
  }
  function handleJobSpecialties(JobSpecialties) {
    setjobSpecialties(JobSpecialties);
    history.push(`/ViewAllJobs/${JobSpecialties}`);
  }
  function handleJobType(jobTypes) {
    setJobType(jobTypes);
    history.push(`/ViewAllJobs/${jobTypes}`);
  }
  function handleViewAllJobs(jobType) {
    setJobPageNo(1);
    setJobTypeR(jobType);

    history.push(`/ViewAllJobs`);
  }
  React.useEffect(() => {
    window.scrollTo(0, 0);
    setJobId(id);
  }, [id]);
  function handleRedirect(url) {
    window.open(url);
  }
  const shareUrl = window.location.href;

  return (
    <React.Fragment>
      <PrimaryNavBar />
      <NavBar /> <div className="application"></div>
      <Container>
        {Loader ? (
          <Grid
            container
            justify="center"
            xs={12}
            style={{ marginTop: "55px" }}
          >
            <Grid item xs={11} md={12} lg={12}>
              <Grid
                item
                container
                justify="center"
                xs={12}
                spacing={1}
                style={{
                  marginTop: "10px",
                  padding: "5px",
                  border: "2px solid gray",
                  height: "auto",
                }}
              >
                <Grid item xs={12} md={5} lg={4} style={{ marginTop: "20px" }}>
                  <TextField
                    className="text"
                    variant="outlined"
                    required
                    fullWidth
                    id="search"
                    placeholder="Company Name or Job Title"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment>
                          <IconButton>
                            <BusinessCenterIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    name="search"
                  />
                </Grid>
                <Grid item xs={12} md={5} lg={4} style={{ marginTop: "20px" }}>
                  <TextField
                    className="text"
                    variant="outlined"
                    required
                    fullWidth
                    id="location"
                    placeholder="Location"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment>
                          <IconButton>
                            <LocationOnIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    name="location"
                  />
                </Grid>
                <Grid item xs={12} md={1} lg={2}>
                  <Button
                    className="btnnn"
                    style={{
                      background: "orange",
                      color: "white",
                      height: "38px",
                      fontSize: "small",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                    type="submit"
                    fullWidth
                    variant="contained"
                  >
                    SEARCH JOBS &nbsp; <SearchIcon />
                  </Button>
                </Grid>
              </Grid>
              {Loader ? (
                <h2 className="newsTitle" style={{ marginTop: "20px" }}>
                  {jobTitle}{" "}
                </h2>
              ) : (
                <div style={{ marginTop: "40px" }}>
                  <Load />
                </div>
              )}
              <Grid container item justify="space-between">
                <Grid item>
                  <h4
                    className="JobH"
                    style={{ marginTop: "15px", cursor: "pointer" }}
                    onClick={() => handleJobLocation(jobLocation)}
                  >
                    <b>Location:</b> {jobLocation}
                  </h4>
                </Grid>
                <Grid item>
                  <h4
                    className="JobH"
                    style={{ marginTop: "15px", cursor: "pointer" }}
                    onClick={() => handleJobType(jobType)}
                  >
                    <b>Job Type:</b> {jobType}
                  </h4>
                </Grid>
                <Grid item>
                  <h4
                    className="JobH"
                    style={{ marginTop: "15px", cursor: "pointer" }}
                    onClick={() => handleJobSpecialties(NursingSpecialties)}
                  >
                    <b>Specialties:</b> {NursingSpecialties}
                  </h4>
                </Grid>
              </Grid>

              <h5 style={{ marginTop: "20px" }}>Details:</h5>
              {Loader ? (
                <p style={{ textAlign: "justify" }}>
                  {" "}
                  <p
                    dangerouslySetInnerHTML={{
                      __html: jobDetail,
                    }}
                  ></p>
                </p>
              ) : (
                <div>
                  <Load />
                </div>
              )}

              <Grid
                item
                container
                justify="space-evenly"
                style={{ marginTop: "20px" }}
              >
                <Grid item>
                  <FacebookShareButton
                    url={shareUrl}
                    title={jobTitle}
                    className="Demo__some-network__share-button"
                  >
                    <FacebookIcon
                      className="icons"
                      style={{ color: "#4867AA" }}
                    />
                  </FacebookShareButton>
                </Grid>
                <Grid item>
                  <TwitterShareButton
                    url={shareUrl}
                    title={jobTitle}
                    className="Demo__some-network__share-button"
                  >
                    <TwitterIcon
                      className="icons"
                      style={{ color: "#1DA1F2" }}
                    />
                  </TwitterShareButton>
                </Grid>
                <Grid item>
                  <LinkedinShareButton
                    url={shareUrl}
                    title={jobTitle}
                    className="Demo__some-network__share-button"
                  >
                    <LinkedInIcon
                      className="icons"
                      style={{ color: "#0077B5" }}
                    />
                  </LinkedinShareButton>
                </Grid>
                <Grid item>
                  <WhatsappShareButton
                    url={shareUrl}
                    title={jobTitle}
                    className="Demo__some-network__share-button"
                  >
                    <WhatsAppIcon
                      className="icons"
                      style={{ color: "#279D31" }}
                    />
                  </WhatsappShareButton>
                </Grid>
                <Grid item>
                  <EmailShareButton
                    url={shareUrl}
                    title={jobTitle}
                    className="Demo__some-network__share-button"
                  >
                    <MailOutlineIcon
                      className="icons"
                      style={{ color: "#CC0000" }}
                    />
                  </EmailShareButton>
                </Grid>
              </Grid>
              <h4>Advertisments :</h4>
              <Carousel
                className="carousal"
                style={{
                  height: "300px",
                  borderRadius: "5px",
                  boxShadow: "0 0 5px",
                }}
                fade="true"
                fade-interval="5000"
                interval="2000"
              >
                {link.map((Link) => (
                  <Carousel.Item>
                    <img
                      className="carousal"
                      style={{ cursor: "pointer", borderRadius: "5px" }}
                      className="d-block w-100"
                      src={Link.linkPicture}
                      onClick={() => handleRedirect(Link.linkUrl)}
                      alt="First slide"
                      width="100%"
                      height="300px"
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
              <Grid item container spacing={2}>
                <Grid item>
                  <Button
                    className="btnnn"
                    style={{
                      background: "gray",
                      color: "white",
                      height: "38px",
                      fontSize: "small",
                      marginTop: "37px",
                    }}
                    type="submit"
                    variant="contained"
                    onClick={handleHome}
                  >
                    <DoubleArrowIcon
                      style={{ transform: "rotate(180deg)", color: "red" }}
                    />{" "}
                    &nbsp; back to home
                  </Button>
                </Grid>
                <Grid item>
                  {jobInfo.companyId ? (
                    <Button
                      className="btnnn"
                      style={{
                        background: "black",
                        color: "white",
                        height: "38px",
                        fontSize: "small",
                        marginTop: "37px",
                      }}
                      type="submit"
                      variant="contained"
                      onClick={() =>
                        handleCompanyProfile(jobInfo?.companyId._id)
                      }
                    >
                      View All Vacancies at {jobInfo.companyId?.companyName}{" "}
                      &nbsp; <DoubleArrowIcon style={{ color: "red" }} />
                    </Button>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              justify="center"
              xs={12}
              style={{
                marginTop: "20px",
                padding: "10px",
                border: "2px solid gray",
                height: "auto",
              }}
            >
              {/* <Grid item xs={12} md={5} lg={4} style={{ marginTop: '10px' }}>
                <h6 style={{ color: '#CC0000' }}>
                  <b>SUBSCRIBE TO FREE JOB ALERT</b>
                </h6>
                <TextField
                  className="text"
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Enter Email to Subscribe"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12} md={1} lg={1}>
                <Button
                  className="btnnn"
                  style={{
                    background: '#CC0000',
                    color: 'white',
                    height: '38px',
                    fontSize: 'small',
                    marginTop: '37px'
                  }}
                  type="submit"
                  fullWidth
                  variant="contained"
                >
                  SUBSCRIBE
                </Button>
              </Grid> */}
              <Grid item xs={12} md={6} lg={6}>
                <Feed />
              </Grid>
              <Grid item container justify="center" xs={12} md={6} lg={6}>
                <h6
                  className="mh"
                  style={{ color: "#1979C3", marginTop: "10px " }}
                >
                  <b>FOLLOW US ON SOCIAL MEDIA</b>
                </h6>
                <Grid
                  container
                  justify="center"
                  spacing={1}
                  style={{ marginBottom: "15px" }}
                >
                  <Grid item>
                    {" "}
                    <FacebookIcon
                      className="icons"
                      style={{ color: "#4867AA", cursor: "pointer" }}
                    />
                  </Grid>
                  <Grid item>
                    <TwitterIcon
                      className="icons"
                      style={{ color: "#1DA1F2", cursor: "pointer" }}
                    />
                  </Grid>
                  <Grid item>
                    {" "}
                    <LinkedInIcon
                      className="icons"
                      style={{ color: "#0077B5", cursor: "pointer" }}
                    />
                  </Grid>
                  <Grid item>
                    <WhatsAppIcon
                      className="icons"
                      style={{ color: "#279D31" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              justify="center"
              className="banner"
              xs={12}
              md={12}
              lg={12}
              style={{
                marginTop: "20px",
                display: "flex",

                justifyContent: "space-between",
                cursor: "pointer",
              }}
            >
              <h4 className="font" className="bannerfont">
                Related Jobs
              </h4>
              <h4
                className="font"
                className="bannerfont"
                onClick={() => handleViewAllJobs(jobType)}
              >
                View More <ArrowRightAltIcon />
              </h4>
              {/* <h5
                className="font"
                className="bannerfont"
                onClick={handleViewAllJobs}
              >
                View All {jobType}
              </h5> */}
            </Grid>
            <Grid item container justify="center">
              <Grid
                container
                spacing={3}
                style={{ marginTop: "20px", marginBottom: "30px" }}
              >
                {relatedJobs.map((Job) => (
                  <Grid item xs={11} md={6} lg={6}>
                    <JobCard Jobs={Job} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <div style={{ marginTop: "100px", marginBottom: "400px" }}>
            <Load />
          </div>
        )}
      </Container>
    </React.Fragment>
  );
}
