import React, { useContext, Component } from "react";

import CarouselCard from "../../Components/Carousel/Carousel";
import { Grid, Typography } from "@material-ui/core";
import JobCard from "../../Components/jobCards/jobCard";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";
import Card3 from "./../../Components/Card3/Card3";
import Card2 from "./../../Components/Card3/Card2";
import Card1 from "./../../Components/Card3/Card1";

import Button from "@material-ui/core/Button";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import NewsCard from "../../Components/NewsCards/newsCards";
import BlogCard from "../../Components/BlogCard/BlogCard";
import ReactPlayer from "react-player";
import "./HomePage.css";
import Container from "@material-ui/core/Container";
import Load from "../../Components/Loader/loader";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import Slider from "react-slick";
import { HomeContext } from "../../Context/HomeContext";
import PrimaryNavBar from "../../Components/PrimaryNavBar/PrimaryNavBar";
import NavBar from "../../Components/NavBar/NavBar";
import GoogleAd from "../../Components/GoogleAdsen";
import Feed from "../../Components/feed";
export default function HomePage() {
  const PAGE_NO = 1;
  const {
    Blog,
    news,
    jobs,
    Loader,
    videos,
    blogMsg,
    setNewsPageNo,
    setJobPageNo,
    setBlogPageNo,
    scholarshipNews,
    admissionFormNews,
    setIsHomePage,
    isHomePage,
    setJobTypeR,
    setNewsType,
    setJobType,
    setjobSpecialties,
    setJobLocation,
    nursingCouncilUpdates,
  } = useContext(HomeContext);

  const history = useHistory();
  React.useEffect(() => {
    console.log("hello");
    window.scrollTo(0, 0);
    setIsHomePage(!isHomePage);
    setJobLocation("");
    setNewsPageNo(PAGE_NO);
    setJobPageNo(PAGE_NO);
    setJobTypeR("");
    setJobType("");
    setNewsType("");
    setjobSpecialties("");
    setBlogPageNo(PAGE_NO);
  }, []);

  const card3 = [1, 2, 3];

  function handleViewAllNews() {
    history.push("/ViewAllNews");
  }
  function handleViewAllJobs() {
    history.push("/ViewAllJobs");
  }
  function handleViewAllBlogs() {
    history.push("/ViewAllBlogs");
  }

  function handleFacebookRedirect() {
    window.open("https://www.facebook.com/NURSINGNIGERIA");
  }
  function handleLinkedInRedirect() {
    window.open("https://www.linkedin.com/in/nursingworld-nigeria-90668385/");
  }
  const settings = {
    dots: true,
    infinite: true,

    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    Arrow: true,
  };
  console.log("scholarshipNews", scholarshipNews);
  return (
    <React.Fragment>
      <PrimaryNavBar />
      <NavBar />

      <Container>
        <div className="ad-class" style={{ height: 0 }}>
          <GoogleAd slot="3289423240033040" />
        </div>
        <Grid container justify="center" spacing={3}>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            style={{
              marginTop: "20px",
            }}
          >
            <CarouselCard />
          </Grid>
          <form
            style={{ width: "100%" }}
            action="https://feedburner.google.com/fb/a/mailverify"
            method="post"
            target="popupwindow"
            onsubmit="window.open(‘https://feedburner.google.com/fb/a/mailverify?uri=nursingworldnigeria/AZsR’, ‘popupwindow’, ‘scrollbars=yes,width=550,height=520’);return true"
          >
            <Grid
              item
              container
              justify="center"
              xs={12}
              style={{
                marginTop: "10px",
                padding: "10px",
                border: "2px solid gray",
                height: "auto",
              }}
            >
              {/* <Grid item xs={12} md={5} lg={4}>
                <h6 style={{ color: '#CC0000' }}>
                  <b>SUBSCRIBE TO FREE JOB ALERT</b>
                </h6>
                <TextField
                  className="text"
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Enter Email to Subscribe"
                  name="email"
                  autoComplete="email"
                />
                <input
                  type="hidden"
                  value="nursingworldnigeria/AZsR’"
                  name="uri"
                />
                <input type="hidden" name="loc" value="en_US" />
              </Grid> */}
              {/* <Grid item xs={12} md={1} lg={1}>
                <Button
                  className="btnnn"
                  style={{
                    background: '#CC0000',
                    color: 'white',
                    height: '38px',
                    fontSize: 'small',
                    marginTop: '27px'
                  }}
                  type="submit"
                  fullWidth
                  variant="contained"
                >
                  SUBSCRIBE
                </Button>
              </Grid> */}
              <Grid item xs={12} md={6} lg={6}>
                <Feed />
              </Grid>
              <Grid item container justify="center" xs={12} md={6} lg={6}>
                <h6 className="mh" style={{ color: "#1979C3" }}>
                  <b>FOLLOW US ON SOCIAL MEDIA</b>
                </h6>
                <Grid container justify="center" spacing={1}>
                  <Grid item>
                    {" "}
                    <FacebookIcon
                      onClick={handleFacebookRedirect}
                      className="icons"
                      style={{ color: "#4867AA", cursor: "pointer" }}
                    />
                  </Grid>
                  <Grid item>
                    <TwitterIcon
                      onClick={handleFacebookRedirect}
                      className="icons"
                      style={{ color: "#1DA1F2", cursor: "pointer" }}
                    />
                  </Grid>
                  <Grid item>
                    {" "}
                    <LinkedInIcon
                      className="icons"
                      onClick={handleLinkedInRedirect}
                      style={{ color: "#0077B5", cursor: "pointer" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
          <Grid
            container
            className="banner"
            xs={12}
            md={12}
            lg={12}
            style={{
              marginTop: "20px",
              display: "flex",

              justifyContent: "space-between",
              cursor: "pointer",
            }}
          >
            <h4 className="bannerfont">CONTRIBUTORS</h4>
            <h5
              className="font"
              className="bannerfont"
              onClick={handleViewAllBlogs}
            >
              {" "}
              ALL <ArrowRightAltIcon />
            </h5>
          </Grid>

          {Loader ? (
            <Grid container justify="center" spacing={3}>
              {Blog.length === 0 ? (
                <>
                  <Grid container justify="center">
                    <Typography
                      style={{ marginTop: "40px", marginBottom: "30px" }}
                      variant="h5"
                    >
                      <b> {blogMsg} !</b>
                    </Typography>
                  </Grid>
                </>
              ) : (
                <>
                  {Blog.map((blog) => (
                    <Grid
                      item
                      xs={11}
                      sm={6}
                      md={4}
                      lg={4}
                      style={{ marginTop: "20px" }}
                    >
                      <BlogCard BlogDetail={blog} />
                    </Grid>
                  ))}{" "}
                </>
              )}
            </Grid>
          ) : (
            <div style={{ marginTop: "40px" }}>
              <Load />
            </div>
          )}

          <Grid
            container
            justify="center"
            className="banner"
            xs={12}
            md={12}
            lg={12}
            style={{
              marginTop: "20px",
              display: "flex",

              justifyContent: "space-between",
              cursor: "pointer",
            }}
          >
            <h4 className="font" className="bannerfont">
              NEWS
            </h4>
            <h5
              className="font"
              className="bannerfont"
              onClick={handleViewAllNews}
            >
              ALL <ArrowRightAltIcon />
            </h5>
          </Grid>

          {Loader ? (
            <Grid container spacing={3} justify="center">
              {news.map((news) => (
                <Grid
                  item
                  xs={11}
                  sm={6}
                  md={4}
                  lg={4}
                  style={{ marginTop: "20px" }}
                >
                  {" "}
                  <Slider {...settings}>
                    <div>
                      <NewsCard News={news} />
                    </div>
                  </Slider>
                </Grid>
              ))}
            </Grid>
          ) : (
            <div style={{ marginTop: "40px" }}>
              {" "}
              <Load />
            </div>
          )}

          <Grid
            container
            justify="center"
            className="banner"
            xs={12}
            md={12}
            lg={12}
            style={{
              marginTop: "20px",
              display: "flex",

              justifyContent: "space-between",
              cursor: "pointer",
            }}
          >
            <h4 className="font" className="bannerfont">
              JOBS
            </h4>
            <h5
              className="font"
              className="bannerfont"
              onClick={handleViewAllJobs}
            >
              ALL <ArrowRightAltIcon />
            </h5>
          </Grid>

          {Loader ? (
            <Grid item container justify="space-between" spacing={5}>
              {jobs.map((Job) => (
                <Grid
                  className="jobcarda"
                  item
                  xs={12}
                  md={6}
                  lg={6}
                  style={{
                    marginTop: "20px",
                  }}
                >
                  <Grid item>
                    {" "}
                    <JobCard Jobs={Job} />
                  </Grid>
                </Grid>
              ))}
            </Grid>
          ) : (
            <div style={{ marginTop: "40px" }}>{/* <Load /> */}</div>
          )}
          {videos.length === 3 ? (
            <div style={{ padding: 20 }}>
              {Loader ? (
                <Grid item container justifyContent="flex-start" spacing={1}>
                  {videos.map((video) => (
                    <Grid item xs={12} md={6} lg={4}>
                      <h5
                        style={{
                          marginTop: "5px",
                          backgroundColor: "#1979C3",
                          color: "white",
                          padding: "5px",
                          width: "100%",
                        }}
                      >
                        {video.videoTitle}
                      </h5>
                      <ReactPlayer
                        style={{ cursor: "pointer" }}
                        controls="true"
                        className="videoplayer"
                        url={video.videoURL}
                      />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <div style={{ marginTop: "40px" }}>
                  <Load />
                </div>
              )}
            </div>
          ) : null}

          <Grid container justify="center" style={{ marginTop: "30px" }}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Card3 scholarshipNews={scholarshipNews} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Card2 admissionFormNews={admissionFormNews} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Card1 nursingCouncilUpdates={nursingCouncilUpdates} />
            </Grid>
          </Grid>
        </Grid>
        <div style={{ marginTop: "40px" }}></div>
      </Container>
    </React.Fragment>
  );
}
