import React, { useContext } from 'react';

import image from '../../assets/images/news4.jpg';
import Container from '@material-ui/core/Container';
import BlogCard from '../../Components/BlogCard/BlogCard';
import Load from '../../Components/Loader/loader';
import { useParams } from 'react-router-dom';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import SearchIcon from '@material-ui/icons/Search';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { useHistory } from 'react-router-dom';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { Grid } from '@material-ui/core';
import { HomeContext } from '../../Context/HomeContext.js';
import { BlogsContext } from '../../Context/BlogContext';
import ProfileCard from '../../Components/ProfileCard/ProfileCard';
import { BloggerContext } from '../../Context/BloggerContext';
import PrimaryNavBar from '../../Components/PrimaryNavBar/PrimaryNavBar';
import NavBar from '../../Components/NavBar/NavBar';
import Carousel from 'react-bootstrap/Carousel';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'react-share';
export default function DetailPage() {
  const { Blog, link } = useContext(HomeContext);
  const { setBlogsId, BlogsDetail, Loader, bloggerInfo, relatedBlogs } =
    useContext(BlogsContext);
  const { setBloggerId } = useContext(BloggerContext);
  const { id } = useParams();

  React.useEffect(() => {
    window.scrollTo(0, 0);
    setBlogsId(id);
    // setBloggerId(id);
  }, [id]);
  function handleRedirect(url) {
    window.open(url);
  }
  const shareUrl = window.location.href;

  return (
    <React.Fragment>
      <PrimaryNavBar />
      <NavBar />{' '}
      <Container>
        {Loader ? (
          <Grid
            container
            justify="center"
            xs={12}
            style={{ marginTop: '55px' }}
          >
            <Grid item xs={11} md={12} lg={12}>
              <h2 className="newsTitle">{BlogsDetail.title} </h2>
              <h6>{new Date(BlogsDetail.date).toDateString()}</h6>
              <Grid
                item
                container
                justify="space-between"
                xs={12}
                style={{ marginTop: '20px' }}
              >
                <Grid item xs={12} md={8} lg={8}>
                  <img
                    className="newsImg"
                    style={{ height: '465px' }}
                    src={BlogsDetail.blogPicture || image}
                    width="100%"
                    height="440px"
                  />
                  <h5 style={{ marginTop: '20px' }}>Details:</h5>
                  <p style={{ textAlign: 'justify' }}>
                    {' '}
                    <p
                      dangerouslySetInnerHTML={{
                        __html: BlogsDetail.description
                      }}
                    ></p>
                  </p>
                  {/* {link.map((Link) => (
                    <Grid item xs={12} style={{ marginTop: "15px" }}>
                      <a
                        style={{ color: "red" }}
                        href={Link.linkUrl}
                        target="_blank"
                      >
                        {Link.linkTitle}
                      </a>
                    </Grid>
                  ))} */}
                  <Grid
                    item
                    container
                    justify="space-evenly"
                    style={{ marginTop: '20px' }}
                  >
                    <Grid item>
                      <FacebookShareButton
                        url={shareUrl}
                        title={BlogsDetail.title}
                        className="Demo__some-network__share-button"
                      >
                        <FacebookIcon
                          className="icons"
                          style={{ color: '#4867AA' }}
                        />
                      </FacebookShareButton>
                    </Grid>
                    <Grid item>
                      <TwitterShareButton
                        url={shareUrl}
                        title={BlogsDetail.title}
                        className="Demo__some-network__share-button"
                      >
                        <TwitterIcon
                          className="icons"
                          style={{ color: '#1DA1F2' }}
                        />
                      </TwitterShareButton>
                    </Grid>
                    <Grid item>
                      <LinkedinShareButton
                        url={shareUrl}
                        title={BlogsDetail.title}
                        className="Demo__some-network__share-button"
                      >
                        <LinkedInIcon
                          className="icons"
                          style={{ color: '#0077B5' }}
                        />
                      </LinkedinShareButton>
                    </Grid>
                    <Grid item>
                      <WhatsappShareButton
                        url={shareUrl}
                        title={BlogsDetail.title}
                        className="Demo__some-network__share-button"
                      >
                        <WhatsAppIcon
                          className="icons"
                          style={{ color: '#279D31' }}
                        />
                      </WhatsappShareButton>
                    </Grid>
                    <Grid item>
                      <EmailShareButton
                        url={shareUrl}
                        title={BlogsDetail.title}
                        className="Demo__some-network__share-button"
                      >
                        <MailOutlineIcon
                          className="icons"
                          style={{ color: '#CC0000' }}
                        />
                      </EmailShareButton>
                    </Grid>
                  </Grid>
                  <h4>Advertisments :</h4>
                  <Carousel
                    className="carousal"
                    style={{
                      height: '300px',
                      borderRadius: '5px',
                      boxShadow: '0 0 5px'
                    }}
                    fade="true"
                    fade-interval="5000"
                    interval="2000"
                  >
                    {link.map((Link) => (
                      <Carousel.Item>
                        <img
                          className="carousal"
                          style={{ cursor: 'pointer', borderRadius: '5px' }}
                          className="d-block w-100"
                          src={Link.linkPicture}
                          onClick={() => handleRedirect(Link.linkUrl)}
                          alt="First slide"
                          width="100%"
                          height="300px"
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <ProfileCard BloggerProfile={bloggerInfo} />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              justify="center"
              className="banner"
              xs={12}
              md={12}
              lg={12}
              style={{
                marginTop: '20px',
                display: 'flex',

                justifyContent: 'space-between',
                cursor: 'pointer'
              }}
            >
              <h4 className="font" className="bannerfont">
                Related Blogs
              </h4>
            </Grid>
            <Grid item container justify="center">
              <Grid
                container
                spacing={3}
                justify="center"
                style={{ marginTop: '20px', marginBottom: '30px' }}
              >
                {relatedBlogs.map((blog) => (
                  <Grid item xs={11} sm={6} md={4} lg={4}>
                    {' '}
                    <div>
                      <BlogCard BlogDetail={blog} />
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Grid>{' '}
          </Grid>
        ) : (
          <div style={{ marginTop: '100px', marginBottom: '400px' }}>
            <Load />
          </div>
        )}
      </Container>
    </React.Fragment>
  );
}
