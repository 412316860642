import React, { useContext, useEffect } from "react";

import Container from "@material-ui/core/Container";
import Load from "../../Components/Loader/loader";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";

import ReactPlayer from "react-player";

import { Grid } from "@material-ui/core";
import { HomeContext } from "../../Context/HomeContext.js";
import PrimaryNavBar from "../../Components/PrimaryNavBar/PrimaryNavBar";
import NavBar from "../../Components/NavBar/NavBar";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
      marginBottom: "30px",
    },
  },
}));
export default function ViewAllNews() {
  const { videos, setVideoPageNo, Loader } = useContext(HomeContext);
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    setVideoPageNo(value);
  };

  return (
    <React.Fragment>
      <PrimaryNavBar />
      <NavBar />
      <Container style={{ marginTop: "40px" }}>
        <Grid container justify="center" spacing={3}>
          <Grid item container justify="center">
            <h1 className="super">All Videos</h1>
          </Grid>
          {Loader ? (
            <Grid
              container
              spacing={3}
              justify="center"
              style={{ marginTop: "20px", marginBottom: "30px" }}
            >
              {videos.map((video) => (
                <Grid item xs={12} md={6} lg={4}>
                  <ReactPlayer
                    controls="true"
                    className="videoplayer"
                    url={video.videoURL}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid
              item
              container
              justify="center"
              style={{
                marginTop: "60px",
                marginBottom: "500px",
              }}
            >
              <Load />
            </Grid>
          )}
          <div className={classes.root}>
            <Pagination color="primary" onChange={handleChange} />
          </div>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
