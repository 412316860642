import React, { useContext } from 'react';

import image from '../../assets/images/news4.jpg';
import Load from '../../Components/Loader/loader';
import NewsCard from '../../Components/NewsCards/newsCards';
import Button from '@material-ui/core/Button';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TextField from '@material-ui/core/TextField';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import SearchIcon from '@material-ui/icons/Search';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { useHistory } from 'react-router-dom';
import './NewsDetail.css';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import { useParams } from 'react-router-dom';
import { Container, Grid } from '@material-ui/core';
import { HomeContext } from '../../Context/HomeContext.js';
import { NewsContext } from '../../Context/NewsContext';
import PrimaryNavBar from '../../Components/PrimaryNavBar/PrimaryNavBar';
import NavBar from '../../Components/NavBar/NavBar';
import Carousel from 'react-bootstrap/Carousel';
import Feed from '../../Components/feed';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'react-share';
export default function DetailPage() {
  const { setNewsId, NewsDetail, Loader, setCompanyNewsId, relatedNews } =
    useContext(NewsContext);

  const { news, link, setNewsType, setNewsPageNo } = useContext(HomeContext);

  const history = useHistory();
  const { id } = useParams();

  function handleHome() {
    history.push('/');
  }
  function handleCompanyProfile(companyId) {
    setCompanyNewsId(companyId);

    history.push(`/CompanyNews/${companyId}`);
  }
  React.useEffect(() => {
    window.scrollTo(0, 0);
    setNewsId(id);
  }, [id]);
  function handleViewAllNews(type) {
    setNewsPageNo(1);
    setNewsType(type);
    history.push('/ViewAllNews');
  }
  function handleRedirect(url) {
    window.open(url);
  }
  const shareUrl = window.location.href;

  return (
    <React.Fragment>
      <PrimaryNavBar />
      <NavBar />{' '}
      <Container>
        {Loader ? (
          <Grid
            container
            justify="center"
            xs={12}
            style={{ marginTop: '55px' }}
          >
            <Grid
              item
              container
              justify="center"
              xs={12}
              spacing={1}
              style={{
                marginTop: '10px',
                padding: '5px',
                border: '2px solid gray',
                height: 'auto'
              }}
            >
              <Grid item xs={12} md={5} lg={4} style={{ marginTop: '20px' }}>
                <TextField
                  className="text"
                  variant="outlined"
                  required
                  fullWidth
                  id="search"
                  placeholder="News Title"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <IconButton>
                          <BusinessCenterIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  name="search"
                />
              </Grid>
              <Grid item xs={12} md={5} lg={4} style={{ marginTop: '20px' }}>
                <TextField
                  className="text"
                  variant="outlined"
                  required
                  fullWidth
                  id="location"
                  placeholder="News Type"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <IconButton>
                          <LocationOnIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  name="location"
                />
              </Grid>
              <Grid item xs={12} md={1} lg={2}>
                <Button
                  className="btnnn"
                  style={{
                    background: 'orange',
                    color: 'white',
                    height: '38px',
                    fontSize: 'small',
                    marginTop: '20px',
                    marginBottom: '20px'
                  }}
                  type="submit"
                  fullWidth
                  variant="contained"
                >
                  SEARCH NEWS &nbsp; <SearchIcon />
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={11} md={12} lg={12} style={{ paddingTop: '20px' }}>
              <h2 className="newsTitle">{NewsDetail.newsTitle} </h2>
              <h6>{new Date(NewsDetail.date).toDateString()}</h6>
              {NewsDetail.newsPicture ? (
                <img
                  className="newsImg"
                  style={{ marginTop: '15px', height: '450px' }}
                  src={NewsDetail.newsPicture}
                  width="100%"
                />
              ) : null}

              <Grid
                item
                container
                justify="center"
                xs={12}
                style={{
                  marginTop: '10px',
                  padding: '10px',
                  border: '2px solid gray',

                  height: 'auto'
                }}
              >
                {/* <Grid item xs={12} md={5} lg={4} style={{ marginTop: '10px' }}>
                  <h6 style={{ color: '#CC0000' }}>
                    <b>SUBSCRIBE TO FREE JOB ALERT</b>
                  </h6>
                  <TextField
                    className="text"
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Enter Email to Subscribe"
                    name="email"
                    autoComplete="email"
                  />
                </Grid>
                <Grid item xs={12} md={1} lg={1}>
                  <Button
                    className="btnnn"
                    style={{
                      background: '#CC0000',
                      color: 'white',
                      height: '38px',
                      fontSize: 'small',
                      marginTop: '37px'
                    }}
                    type="submit"
                    fullWidth
                    variant="contained"
                  >
                    SUBSCRIBE
                  </Button>
                </Grid> */}
                <Grid item xs={12} md={6} lg={6}>
                  <Feed />
                </Grid>
                <Grid item container justify="center" xs={12} md={6} lg={6}>
                  <h6
                    className="mh"
                    style={{ color: '#1979C3', marginTop: '10px ' }}
                  >
                    <b>FOLLOW US ON SOCIAL MEDIA</b>
                  </h6>
                  <Grid
                    container
                    justify="center"
                    spacing={1}
                    style={{ marginBottom: '15px' }}
                  >
                    <Grid item>
                      {' '}
                      <FacebookIcon
                        className="icons"
                        style={{ color: '#4867AA', cursor: 'pointer' }}
                      />
                    </Grid>
                    <Grid item>
                      <TwitterIcon
                        className="icons"
                        style={{ color: '#1DA1F2', cursor: 'pointer' }}
                      />
                    </Grid>
                    <Grid item>
                      {' '}
                      <LinkedInIcon
                        className="icons"
                        style={{ color: '#0077B5', cursor: 'pointer' }}
                      />
                    </Grid>
                    <Grid item>
                      <WhatsAppIcon
                        className="icons"
                        style={{ color: '#279D31' }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <h5 style={{ marginTop: '20px' }} className="super">
                Details:
              </h5>
              {Loader ? (
                <p style={{ textAlign: 'justify' }}>
                  {' '}
                  <p
                    dangerouslySetInnerHTML={{
                      __html: NewsDetail.newsDetail
                    }}
                  ></p>
                </p>
              ) : (
                <div>
                  <Load />
                </div>
              )}

              <Grid
                item
                container
                justify="space-evenly"
                style={{ marginTop: '20px' }}
              >
                <Grid item>
                  <FacebookShareButton
                    url={shareUrl}
                    title={NewsDetail.newsTitle}
                    className="Demo__some-network__share-button"
                  >
                    <FacebookIcon
                      className="icons"
                      style={{ color: '#4867AA' }}
                    />
                  </FacebookShareButton>
                </Grid>
                <Grid item>
                  <TwitterShareButton
                    url={shareUrl}
                    title={NewsDetail.newsTitle}
                    className="Demo__some-network__share-button"
                  >
                    <TwitterIcon
                      className="icons"
                      style={{ color: '#1DA1F2' }}
                    />
                  </TwitterShareButton>
                </Grid>
                <Grid item>
                  <LinkedinShareButton
                    url={shareUrl}
                    title={NewsDetail.newsTitle}
                    className="Demo__some-network__share-button"
                  >
                    <LinkedInIcon
                      className="icons"
                      style={{ color: '#0077B5' }}
                    />
                  </LinkedinShareButton>
                </Grid>
                <Grid item>
                  <WhatsappShareButton
                    url={shareUrl}
                    title={NewsDetail.newsTitle}
                    className="Demo__some-network__share-button"
                  >
                    <WhatsAppIcon
                      className="icons"
                      style={{ color: '#279D31' }}
                    />
                  </WhatsappShareButton>
                </Grid>
                <Grid item>
                  <EmailShareButton
                    url={shareUrl}
                    title={NewsDetail.newsTitle}
                    className="Demo__some-network__share-button"
                  >
                    <MailOutlineIcon
                      className="icons"
                      style={{ color: '#CC0000' }}
                    />
                  </EmailShareButton>
                </Grid>
              </Grid>
              <h4>Advertisments :</h4>
              <Carousel
                className="carousal"
                style={{
                  height: '300px',
                  borderRadius: '5px',
                  boxShadow: '0 0 5px'
                }}
                fade="true"
                fade-interval="5000"
                interval="2000"
              >
                {link.map((Link) => (
                  <Carousel.Item>
                    <img
                      className="carousal"
                      style={{ cursor: 'pointer', borderRadius: '5px' }}
                      className="d-block w-100"
                      src={Link.linkPicture}
                      onClick={() => handleRedirect(Link.linkUrl)}
                      alt="First slide"
                      width="100%"
                      height="300px"
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
              <Grid item container spacing={2}>
                <Grid item>
                  <Button
                    className="btnnn"
                    style={{
                      background: 'gray',
                      color: 'white',
                      height: '38px',
                      fontSize: 'small',
                      marginTop: '37px'
                    }}
                    type="submit"
                    variant="contained"
                    onClick={handleHome}
                  >
                    <DoubleArrowIcon style={{ transform: 'rotate(180deg)' }} />{' '}
                    &nbsp; back to home
                  </Button>
                </Grid>
                <Grid item>
                  {NewsDetail.companyId ? (
                    <Button
                      className="btnnn"
                      style={{
                        background: 'black',
                        color: 'white',
                        height: '38px',
                        fontSize: 'small',
                        marginTop: '37px'
                      }}
                      type="submit"
                      variant="contained"
                      onClick={() =>
                        handleCompanyProfile(NewsDetail?.companyId._id)
                      }
                    >
                      View All News at &nbsp; {NewsDetail.companyId.companyName}
                      <DoubleArrowIcon style={{ color: 'red' }} />
                    </Button>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              justify="center"
              className="banner"
              xs={12}
              md={12}
              lg={12}
              style={{
                marginTop: '20px',
                display: 'flex',

                justifyContent: 'space-between',
                cursor: 'pointer'
              }}
            >
              <h5 className="font" className="bannerfont">
                News You Might Be Interested In
              </h5>
              <h5
                className="font"
                className="bannerfont"
                onClick={() => handleViewAllNews(NewsDetail.newsType)}
              >
                View More <ArrowRightAltIcon />
              </h5>
            </Grid>
            <Grid item container justify="center">
              <Grid
                container
                spacing={3}
                // justify="center"
                style={{ marginTop: '20px', marginBottom: '30px' }}
              >
                {relatedNews.map((news) => (
                  <Grid item xs={11} sm={6} md={4} lg={4}>
                    {' '}
                    <div>
                      <NewsCard News={news} />
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <div style={{ marginTop: '100px', marginBottom: '400px' }}>
            <Load />
          </div>
        )}
      </Container>
    </React.Fragment>
  );
}
