import React from "react";

import { Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import axiosClient from "../../helper";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import PrimaryNavBar from "../../Components/PrimaryNavBar/PrimaryNavBar";
import NavBar from "../../Components/NavBar/NavBar";
import { useHistory } from "react-router-dom";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  margin: {
    width: "100%",
  },
}));
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
export default function PostJob() {
  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      ["blockquote", "code-block"],
      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ direction: "rtl" }], // text direction
      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],
      ["link", "image", "video", "file"],

      ["clean"], // remove formatting button
    ],
  };
  const classes = useStyles();
  const [isBtndisable, setisBtndisable] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [popupMsg, setPopupMsg] = React.useState("");
  const [snackbarColor, setSnackbarColor] = React.useState("");
  const [productImage, setProductImage] = React.useState("");

  const educationDropDown = [
    "",
    "First School Leaving Certificate",
    "Secondary School (SSCE)",
    "NCE",
    "BA/BSc/HND",
    "MBA/MSc/MA ",
  ];

  const JobTypes = [
    "Home Care Nurse",
    "School Jobs",
    "NGO/HMO",
    "Government Jobs",
    "Oil and Gas Jobs",
    "Hospital Jobs",
  ];

  const NursingSDropDown = [
    "General Nurses",
    "Midwives",
    "Mental Health Nurses",
    "Public Health Nurses",
    "Public Health Nurses Educator",
    "Nurse Educators",
    "Midwife Educators",
    "Nurse Administration",
    "Orthopaedic Nurses",
    "Nurse Anaesthetists",
    "Perioperative",
    "Ophthalmic Nurses",
    "Accident and Emergency Nurses",
    "Paediatric Nurses",
    "Cardio-thoracic Nurses",
    "Burns and Plastic Nurses",
    "Occupational Health Nurses",
    "Ear, Nose and Throat Nurses",
    "Nursing Degrees(BNSC, PGD, MSC, PHD)",
  ];

  const CHARACTER_LIMIT = 150;
  const initialFormData = Object.freeze({
    jobTitle: "",
    companyEmail: "",
    companyWebsite: "",
    Education: "",
    NursingSpecialties: [],
    jobType: "",
    jobLocation: "",
    Experience: "",
    jobDetail: "",
    jobPicture: "",
    jobDescription: "",
  });
  const [formData, updateFormData] = React.useState(initialFormData);
  const [multiSpecialties, setMultiSpecialties] = React.useState([]);
  const history = useHistory();

  const handleChange = (e) => {
    setMultiSpecialties(e.target.value);
  };

  const handleChangeData = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleJobDetail = (a) => {
    updateFormData({
      ...formData,
      jobDetail: a,
    });
  };
  const handleImages = async (e) => {
    const files = e;

    const data = new FormData();
    data.append("file", files[0]);
    data.append("upload_preset", "productimages");

    const res = await fetch(
      "https://api.cloudinary.com/v1_1/my-cloud-name/image/upload",
      {
        method: "POST",
        body: data,
      }
    );

    let file = await res.json();
    // setdisable(false);

    setProductImage(file.secure_url);
    updateFormData({
      ...formData,
      jobPicture: file.secure_url,
    });
  };
  const handleClick = (event) => {
    event.preventDefault();

    setisBtndisable(true);
    axiosClient()
      .post("/addJobByClient", {
        ...formData,
        NursingSpecialties: multiSpecialties,
      })
      .then((response) => {
        setisBtndisable(false);

        if (response.data.status === "400") {
          setOpen(true);
          setPopupMsg(response.data.message);
          setSnackbarColor("error");
        } else {
          setOpen(true);
          setPopupMsg(response.data.message);

          setSnackbarColor("success");
          updateFormData(initialFormData);
          setTimeout(() => {
            history.push("/");
          }, 3000);
        }
      })
      .catch((error) => {
        alert("server error Post Job Api", error);
      });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <React.Fragment>
      <PrimaryNavBar />
      <NavBar />
      <Grid container justify="center">
        <Grid
          item
          xs={11}
          md={9}
          lg={9}
          style={{
            backgroundColor: "white",
            height: "auto",
            marginTop: "70px",
            boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
          }}
        >
          <Grid container justify="center" style={{ marginTop: "20px" }}>
            <h3>Post A Job</h3>
          </Grid>
          <Grid container justify="center" style={{ marginTop: "40px" }}>
            <Grid item xs={11} md={7} lg={7}>
              <h6>Job Title</h6>
              {/* creating text feild for First Name */}
              <TextField
                autoComplete="jobtitle"
                name="jobTitle"
                variant="outlined"
                required
                fullWidth
                value={formData.jobTitle}
                id="jobtitle"
                placeholder="(Eg. Medical Doctor Vacancy at the Nigerian Red Cross)"
                autoFocus
                onChange={handleChangeData}
              />
            </Grid>

            <Grid item xs={11} md={7} lg={7} style={{ marginTop: "20px" }}>
              <h6>Nursing Specialties</h6>
              <FormControl className={classes.margin}>
                <Select
                  multiple
                  variant="outlined"
                  value={multiSpecialties}
                  onChange={handleChange}
                >
                  {NursingSDropDown.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={11} md={7} lg={7} style={{ marginTop: "20px" }}>
              <h6>Job Type</h6>
              <FormControl className={classes.margin}>
                <NativeSelect
                  id="demo-customized-select-native"
                  name="jobType"
                  value={formData.jobType}
                  onChange={handleChangeData}
                  input={<BootstrapInput />}
                >
                  {JobTypes.map((name) => (
                    <option key={name} value={name}>
                      {name}
                    </option>
                  ))}
                </NativeSelect>
              </FormControl>
            </Grid>
            <Grid item xs={11} md={7} lg={7} style={{ marginTop: "20px" }}>
              <h6>Job Location</h6>
              <FormControl className={classes.margin}>
                <NativeSelect
                  id="demo-customized-select-native"
                  name="jobLocation"
                  value={formData.jobLocation}
                  onChange={handleChangeData}
                  input={<BootstrapInput />}
                >
                  <option aria-label="None" value="" />
                  <option value={"Abia"}>Abia</option>
                  <option value={"Abuja"}>Abuja</option>
                  <option value={"Adamawa"}>Adamawa</option>
                  <option value={"Akwa ibom"}>Akwa ibom</option>
                  <option value={"Anambra"}>Anambra</option>
                  <option value={"Bauchi"}>Bauchi</option>
                  <option value={"Bayelsa"}>Bayelsa </option>
                  <option value={"Benue"}>Benue</option>
                  <option value={"Borno"}>Borno</option>
                  <option value={"Cross River"}>Cross River </option>
                  <option value={"Delta"}>Delta</option>
                  <option value={"Ebonyi"}>Ebonyi </option>
                  <option value={"Edo"}>Edo</option>

                  <option value={"Ekiti"}>Ekiti</option>
                  <option value={"Enugu"}>Enugu</option>
                  <option value={"Gombe"}>Gombe </option>
                  <option value={"Imo"}>Imo</option>
                  <option value={"Jigawa"}>Jigawa</option>
                  <option value={"Kaduna"}>Kaduna</option>
                  <option value={"Kano"}>Kano</option>
                  <option value={"katsina"}>katsina</option>
                  <option value={"Kebbi"}>Kebbi </option>
                  <option value={"Kogi"}>Kogi</option>
                  <option value={"Kwara"}>Kwara</option>
                  <option value={"Lagos"}>Lagos</option>
                  <option value={"Nassarawa"}>Nassarawa</option>
                  <option value={"Niger"}>Niger</option>
                  <option value={"Ogun"}>Ogun</option>
                  <option value={"Ondo"}>Ondo</option>
                  <option value={"Osun"}>Osun</option>
                  <option value={"Oyo"}>Oyo </option>

                  <option value={"Plateau"}>Plateau</option>
                  <option value={"Rivers"}>Rivers</option>
                  <option value={"Sokoto"}>Sokoto</option>
                  <option value={"Taraba"}>Taraba</option>
                  <option value={"Yobe"}>Yobe</option>
                  <option value={"Zamfara"}>Zamfara </option>
                </NativeSelect>
              </FormControl>
            </Grid>
            <Grid item xs={11} md={7} lg={7} style={{ marginTop: "20px" }}>
              <h6>Job Date</h6>
              {/* creating text feild for First Name */}
              <TextField
                type="date"
                autoComplete="date"
                name="endDate"
                variant="outlined"
                required
                fullWidth
                value={formData.endDate}
                id="endDate"
                placeholder="(Eg. Medical Doctor Vacancy at the Nigerian Red Cross)"
                autoFocus
                onChange={handleChangeData}
              />
            </Grid>
            <Grid item xs={11} md={7} lg={7} style={{ marginTop: "20px" }}>
              <h6>Short Description</h6>
              <TextField
                id="outlined-multiline-static"
                label="Description"
                value={formData.jobDescription}
                name="jobDescription"
                fullWidth
                onChange={handleChangeData}
                multiline
                inputProps={{
                  maxlength: CHARACTER_LIMIT,
                }}
                rows={4}
                // helperText="Include condition, features and reason for selling"
                variant="outlined"
              />
            </Grid>

            <Grid item xs={11} md={7} lg={7} style={{ marginTop: "20px" }}>
              <ReactQuill
                style={{ height: 200, marginBottom: 50 }}
                value={formData.jobDetail}
                modules={modules}
                onChange={(a) => handleJobDetail(a)}
              />
            </Grid>
            <Grid container justify="center" style={{ marginTop: "20px" }}>
              <Grid item xs={2}>
                <Button
                  style={{
                    background: isBtndisable ? "#2f4f4f" : "#1979C3",

                    color: "white",
                    marginBottom: "40px",
                  }}
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={isBtndisable}
                  //clicking the button navigate you to signin page.
                  onClick={handleClick}
                >
                  {isBtndisable ? "Please Wait..." : "Post"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={open}
          autoHideDuration={5000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={snackbarColor}>
            {popupMsg}
          </Alert>
        </Snackbar>
      </Grid>
    </React.Fragment>
  );
}
