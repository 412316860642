import React, { useState, createContext } from 'react';
import axiosClient from '../helper';

export const CompanyContext = createContext();

const CompanyProvider = ({ children }) => {
	const [jobs, setJobs] = useState([]);
	const [companyInfo, setCompanyInfo] = useState({});
	const [jobInfo, setJobInfo] = useState({});
	const [companyLogo, setCompanyLogo] = useState([]);

	const [Loader, setLoader] = React.useState(false);
	const [JobByLocation, setJobByLocation] = useState([]);
	const [companyId, setCompanyId] = useState('');
	const [jobLocationId, setJobLocationId] = useState('');
	const [relatedJobs, setRelatedJobs] = useState([]);

	const [jobId, setJobId] = useState('');

	React.useEffect(() => {
		setLoader(false);

		if (companyId !== '') {
			axiosClient()
				.get(`/getAllCompanyJobsById/${companyId}`)
				.then((res) => {
					setJobs(res.data.data.companyJobs);
					setCompanyInfo(res.data.data.companyDetails);
					setLoader(true);
				})
				.catch((error) => {});
		}
	}, [companyId]);

	React.useEffect(() => {
		setLoader(false);

		axiosClient()
			.get(`/getCompanyAndLogo`)
			.then((res) => {
				setCompanyLogo(res.data.data);
				setLoader(true);
			})
			.catch((error) => {});
	}, []);

	React.useEffect(() => {
		setLoader(false);
		if (jobId !== '') {
			axiosClient()
				.get(`/getJobById/${jobId}`)
				.then((res) => {
					setJobInfo(res.data.data);
					setRelatedJobs(res.data.relatedJobs);

					setLoader(true);
				})
				.catch((error) => {});
		}
	}, [jobId]);

	return (
		<CompanyContext.Provider
			value={{
				jobs,
				companyInfo,
				companyId,
				relatedJobs,
				companyLogo,
				setCompanyId,
				setJobLocationId,
				JobByLocation,
				setJobId,
				jobInfo,
				Loader,
			}}
		>
			{children}
		</CompanyContext.Provider>
	);
};

export default CompanyProvider;
