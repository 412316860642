import React, { useContext } from "react";

import { Grid, Container } from "@material-ui/core";
import { HomeContext } from "../../Context/HomeContext";
import Carousel from "react-bootstrap/Carousel";
import Slider from "react-slick";

import {
  cLogo1,
  cLogo2,
  cLogo3,
  cLogo4,
  cLogo5,
  cLogo6,
  cLogo7,
  cLogo8,
  cLogo9,
  cLogo10,
  cLogo11,
  cLogo12,
  cLogo13,
  cLogo14,
  cLogo15,
  cLogo16,
  cLogo17,
  cLogo18,
  cLogo19,
  cLogo20,
} from "../../assets/images/contactUsLogos/index";
import PrimaryNavBar from "../../Components/PrimaryNavBar/PrimaryNavBar";
import NavBar from "../../Components/NavBar/NavBar";
import Certificate from "../../Components/certificate/certificate";
export default function BloggerDetail() {
  const { trainingGallery, clientLogos } = useContext(HomeContext);
  const images = [
    cLogo1,
    cLogo2,
    cLogo3,
    cLogo4,
    cLogo5,
    cLogo6,
    cLogo7,
    cLogo8,
    cLogo9,
    cLogo10,
    cLogo11,
    cLogo12,
    cLogo13,
    cLogo14,
    cLogo15,
    cLogo16,
    cLogo17,
    cLogo18,
    cLogo19,
    cLogo20,
  ];
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,

    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };
  return (
    <React.Fragment>
      <PrimaryNavBar />
      <NavBar />
      <Container>
        <Grid container justify="center">
          <h2 className="super" style={{ marginTop: "40px" }}>
            {" "}
            Training Image Gallery
          </h2>

          <Grid item xs={10}>
            <Carousel
              className="carousal"
              style={{
                height: "500px",
                borderRadius: "5px",
                boxShadow: "0 0 5px",
                marginTop: "30px",
              }}
              fade="true"
              fade-interval="5000"
              interval="1000"
              // controls="false"
            >
              {trainingGallery.map((image) => (
                <Carousel.Item>
                  <img
                    className="carousal"
                    style={{ cursor: "pointer", borderRadius: "5px" }}
                    className="d-block w-100"
                    src={image.galleryImage}
                    alt="First slide"
                    width="100%"
                    height="500px"
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </Grid>
        </Grid>
        <Grid container style={{ marginBottom: "50px" }}>
          <Grid
            item
            container
            justify="center"
            style={{ marginTop: "40px" }}
          ></Grid>
          <Grid item style={{ marginTop: "40px" }}>
            <h2 className="super">Our Clients</h2>
          </Grid>
          <Grid item xs={12}>
            <div style={{ marginTop: "20px" }}>
              <Slider {...settings}>
                {clientLogos.map((image) => (
                  <div>
                    <img
                      className="slick"
                      style={{ cursor: "pointer" }}
                      src={image.clientLogo}
                      width="400px"
                      height="300px"
                    />
                  </div>
                ))}
              </Slider>
            </div>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
