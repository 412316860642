import React, { useContext } from 'react';
import './directory.css';
import './directory.scss';

import { Grid, Container } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Pagination from '@material-ui/lab/Pagination';

import { HomeContext } from '../../Context/HomeContext';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Load from '../../Components/Loader/loader';
import { CompanyContext } from '../../Context/companyContext';
import PrimaryNavBar from '../../Components/PrimaryNavBar/PrimaryNavBar';
import NavBar from '../../Components/NavBar/NavBar';
import { makeStyles } from '@material-ui/core/styles';
import defaultPic from '../../assets/images/download.png';
import Feed from '../../Components/feed';
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
      marginBottom: '30px'
    }
  }
}));
export default function Directory() {
  const classes = useStyles();

  const {
    Company,
    Loader,
    setDirPageNo,
    totalDirectories,
    setAlpha,
    noCFound
  } = useContext(HomeContext);
  const { setCompanyId } = useContext(CompanyContext);
  function handleFacebookRedirect() {
    window.open('https://www.facebook.com/NURSINGNIGERIA');
  }
  function handleLinkedInRedirect() {
    window.open('https://www.linkedin.com/in/nursingworld-nigeria-90668385/');
  }
  const history = useHistory();

  function handleCompanyProfile(companyId) {
    setCompanyId(companyId);

    history.push(`/CompanyProfile/${companyId}`);
  }

  const PAGE_NO = 1;

  const handleChange = (event, value) => {
    setDirPageNo(value);
  };

  const handleAlphabets = (value) => {
    setAlpha(value);
  };
  const TotalDirectories = Math.ceil(totalDirectories / 10);

  return (
    <React.Fragment>
      <PrimaryNavBar />
      <NavBar />
      <Container>
        <Grid container>
          <Grid item style={{ marginTop: '30px' }}>
            <h1 className="super">Directory </h1>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          style={{
            marginTop: '20px',
            padding: '10px',
            border: '2px solid gray',
            height: 'auto'
          }}
        >
          {/* <Grid item xs={12} md={5} lg={4}>
            <h6 style={{ color: '#CC0000' }}>
              <b>SUBSCRIBE TO FREE JOB ALERT</b>
            </h6>
            <TextField
              className="text"
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Enter Email to Subscribe"
              name="email"
              autoComplete="email"
            />
          </Grid>
          <Grid item xs={12} md={1} lg={1}>
            <Button
              className="btnnn"
              style={{
                background: '#CC0000',
                color: 'white',
                height: '38px',
                fontSize: 'small',
                marginTop: '27px'
              }}
              type="submit"
              fullWidth
              variant="contained"
            >
              SUBSCRIBE
            </Button>
          </Grid> */}
          <Grid item xs={12} md={6} lg={6}>
            <Feed />
          </Grid>
          <Grid item container justify="center" xs={12} md={6} lg={6}>
            <h6 className="mh" style={{ color: '#1979C3' }}>
              <b>FOLLOW US ON SOCIAL MEDIA</b>
            </h6>
            <Grid container justify="center" spacing={1}>
              <Grid item>
                {' '}
                <FacebookIcon
                  onClick={handleFacebookRedirect}
                  className="icons"
                  style={{ color: '#4867AA', cursor: 'pointer' }}
                />
              </Grid>
              <Grid item>
                <TwitterIcon
                  onClick={handleFacebookRedirect}
                  className="icons"
                  style={{ color: '#1DA1F2', cursor: 'pointer' }}
                />
              </Grid>
              <Grid item>
                {' '}
                <LinkedInIcon
                  className="icons"
                  onClick={handleLinkedInRedirect}
                  style={{ color: '#0077B5', cursor: 'pointer' }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{ marginTop: '20px' }}>
          <nav class="AlphabetNav">
            <a onClick={(e, a) => handleAlphabets('A')}>A</a>
            <a onClick={(e, a) => handleAlphabets('B')}>B</a>
            <a onClick={(e, a) => handleAlphabets('C')}>C</a>
            <a onClick={(e, a) => handleAlphabets('D')}>D</a>
            <a onClick={(e, a) => handleAlphabets('E')}>E</a>
            <a onClick={(e, a) => handleAlphabets('F')}>F</a>
            <a onClick={(e, a) => handleAlphabets('G')}>G</a>
            <a onClick={(e, a) => handleAlphabets('H')}>H</a>
            <a onClick={(e, a) => handleAlphabets('I')}>I</a>
            <a onClick={(e, a) => handleAlphabets('J')}>J</a>
            <a onClick={(e, a) => handleAlphabets('K')}>K</a>
            <a onClick={(e, a) => handleAlphabets('L')}>L</a>
            <a onClick={(e, a) => handleAlphabets('M')}>M</a>
            <a onClick={(e, a) => handleAlphabets('N')}>N</a>
            <a onClick={(e, a) => handleAlphabets('O')}>O</a>
            <a onClick={(e, a) => handleAlphabets('P')}>P</a>
            <a onClick={(e, a) => handleAlphabets('Q')}>Q</a>
            <a onClick={(e, a) => handleAlphabets('R')}>R</a>
            <a onClick={(e, a) => handleAlphabets('S')}>S</a>
            <a onClick={(e, a) => handleAlphabets('T')}>T</a>
            <a onClick={(e, a) => handleAlphabets('U')}>U</a>
            <a onClick={(e, a) => handleAlphabets('V')}>V</a>
            <a onClick={(e, a) => handleAlphabets('W')}>W</a>
            <a onClick={(e, a) => handleAlphabets('X')}>X</a>
            <a onClick={(e, a) => handleAlphabets('Y')}>Y</a>
            <a onClick={(e, a) => handleAlphabets('Z')}>Z</a>
          </nav>
        </Grid>
        {Loader ? (
          <>
            {noCFound === '200' ? (
              <Grid>
                {Company.map((company) => (
                  <Grid
                    className="cStyle"
                    container
                    xs={12}
                    md={12}
                    lg={12}
                    spacing={2}
                    style={{
                      marginTop: '40px',
                      marginBottom: '20px',
                      backgroundColor: '#f8f9fa'
                    }}
                    onClick={() => handleCompanyProfile(company._id)}
                  >
                    <Grid item xs={4} md={3} lg={3}>
                      <img
                        style={{ borderRadius: '3px' }}
                        src={company.logo?.companyPicture || defaultPic}
                        width="100%"
                        height="135px"
                      />
                    </Grid>
                    <Grid item xs={8} md={5} lg={7}>
                      <h4 className="cName"> {company.companyName}</h4>

                      <p className="cDiscription">
                        {company.companyDescription}
                      </p>
                    </Grid>
                  </Grid>
                ))}
                <Grid container justify="center">
                  <div className={classes.root}>
                    <Pagination
                      count={TotalDirectories}
                      color="primary"
                      onChange={handleChange}
                    />
                  </div>
                </Grid>
              </Grid>
            ) : (
              <div
                style={{
                  textAlign: 'center',

                  marginTop: '120px',
                  marginBottom: '250px'
                }}
              >
                <h3>No company Found </h3>
              </div>
            )}
          </>
        ) : (
          <div style={{ marginTop: '30px', marginBottom: '250px' }}>
            <Load />
          </div>
        )}
      </Container>
    </React.Fragment>
  );
}
