import React, { useState, createContext } from 'react';
import axiosClient from '../helper';

export const EventsContext = createContext();

const EventProvider = ({ children }) => {
	const [events, setEvents] = useState([]);

	const [Loader, setLoader] = React.useState(false);

	React.useEffect(() => {
		setLoader(false);

		axiosClient()
			.get(`/getAllEvents`)
			.then((res) => {
				setEvents(res.data.data);
				setLoader(true);
			})
			.catch((error) => {});
	}, []);

	return (
		<EventsContext.Provider
			value={{
				events,
			}}
		>
			{children}
		</EventsContext.Provider>
	);
};

export default EventProvider;
