import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import cardimg from '../../assets/images/news2.jpg';
import './BlogCard.css';
import { Link } from 'react-router-dom';

import { useHistory } from 'react-router-dom';
import './BlogCard.css';

import { BlogsContext } from '../../Context/BlogContext';

export default function BlogCards({ BlogDetail }) {
  const { setBlogsId } = useContext(BlogsContext);

  const {
    title,
    description,
    blogPicture,
    shortDescription,
    userId,
    bloggerId,
    _id,
    slug
  } = BlogDetail;

  const history = useHistory();
  //click function to navigate to signin page
  function handleDetailPage() {
    history.push(`/blog/${slug}`);
    setBlogsId(slug);
  }

  return (
    <Grid
      className="jobCard"
      style={{
        backgroundColor: 'white'
      }}
    >
      <Grid item>
        <img
          onClick={handleDetailPage}
          className="newsimg"
          style={{ cursor: 'pointer' }}
          src={blogPicture || cardimg}
          width="100%"
          height="200px"
        />
      </Grid>
      <Grid item xs={12} onClick={handleDetailPage}>
        <h5 className="font" style={{ marginTop: '5px', cursor: 'pointer' }}>
          {title}
        </h5>
      </Grid>
      <Grid item xs={12}>
        <p className="paragraph" style={{ color: 'gray' }}>
          {shortDescription}
        </p>
      </Grid>
      <Grid item xs={12}>
        By &nbsp;
        <Link
          to={{
            pathname: `/ProfilePage/${bloggerId._id}`
          }}
          style={{ cursor: 'pointer' }}
          variant="body2"
        >
          {bloggerId.userId?.firstName} {bloggerId.userId?.lastName}
        </Link>
      </Grid>
    </Grid>
  );
}
