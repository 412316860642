import React, { useContext } from "react";

import Container from "@material-ui/core/Container";
import Load from "../../Components/Loader/loader";

import { useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import "./CompanyProfile.css";

import JobCard from "../../Components/jobCards/jobCard";
import Button from "@material-ui/core/Button";
import { CompanyContext } from "../../Context/companyContext";
import PrimaryNavBar from "../../Components/PrimaryNavBar/PrimaryNavBar";
import NavBar from "../../Components/NavBar/NavBar";

export default function CompanyProfile() {
  const { id } = useParams();
  const { jobs, companyInfo, Loader } = useContext(CompanyContext);

  const {
    companyAddress,
    companyDescription,
    companyEmail,
    companyName,
    companyPicture,
    companyWebsite,
  } = companyInfo;

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <PrimaryNavBar />
      <NavBar />
      <Container style={{ marginTop: "40px" }}>
        <Grid
          container
          justify="space-between"
          xs={12}
          container
          // direction="row"
        >
          {Loader ? (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              style={{ marginTop: "30px" }}
            >
              <div style={{ textAlign: "justify" }}>
                <h4>
                  About <b style={{ color: "orange" }}> {companyName}</b>
                </h4>
                <p style={{ marginTop: "15px" }}>{companyDescription}</p>
              </div>
              <Grid
                item
                container
                style={{
                  backgroundColor: "#F8F9FA",
                  padding: "20px",
                  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                  marginTop: "30px",
                }}
              >
                <Grid item xs={12}>
                  <h4 style={{ color: "#FE9A01" }}>Basic Info</h4>
                </Grid>
                <Grid item xs={4} style={{ marginTop: "10px", color: "gray" }}>
                  <h6>Industry</h6>
                  <h6>Founded</h6>
                  <h6>Email</h6>
                  <h6>Website</h6>
                  <h6>Address</h6>
                </Grid>
                <Grid item xs={6} style={{ marginTop: "10px", color: "gray" }}>
                  <h6>Consulting</h6>
                  <h6>1952</h6>
                  <h6>{companyEmail}</h6>
                  <h6>{companyWebsite}</h6>
                  <h6>{companyAddress}</h6>
                </Grid>
              </Grid>

              <Grid
                item
                container
                style={{
                  backgroundColor: "#F8F9FA",
                  padding: "20px",
                  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                  marginTop: "20px",
                }}
              >
                <Grid item xs={12}>
                  <h4 style={{ color: "#FE9A01" }}>Company Reviews</h4>
                </Grid>
                <Grid item xs={12} style={{ marginTop: "10px", color: "gray" }}>
                  <h6>No Reviews Available</h6>
                  <p>
                    Do you currently work in this company or maybe have worked
                    there before?
                  </p>
                  <Button
                    className="btnnn"
                    style={{
                      background: "#FE9A01",
                      color: "white",
                      height: "38px",
                      fontSize: "small",
                      marginTop: "10px",
                    }}
                    type="submit"
                    variant="contained"
                  >
                    Drop a Review
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <div style={{ margin: "100px", marginBottom: "350px" }}>
              <Load />
            </div>
          )}
          {/* <Grid item container xs={8}>
            <h3>
              Latest Jobs Vacancies at{" "}
              <b style={{ color: "orange" }}>{companyName}</b>
            </h3>
          </Grid> */}
          {Loader ? (
            <Grid
              item
              xs={12}
              md={7}
              lg={7}
              item
              spacing={4}
              style={{ marginTop: "5px" }}
            >
              <h4 style={{ marginTop: "20px" }}>
                Latest Updates from{" "}
                <b style={{ color: "orange" }}>{companyName}</b>
              </h4>
              {jobs.map((Job) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={8}
                  style={{ marginTop: "40px" }}
                >
                  {" "}
                  <div>
                    <JobCard Jobs={Job} />
                  </div>
                </Grid>
              ))}
            </Grid>
          ) : (
            <div style={{ margin: "100px" }}>
              <Load />
            </div>
          )}
        </Grid>
      </Container>
    </React.Fragment>
  );
}
