import React, { useState, createContext } from "react";
import axiosClient from "../helper";
import { useHistory } from "react-router-dom";

export const PostApiContext = createContext();

const PostApiProvider = ({ children }) => {
  //   const [course, setCourse] = useState({});
  //   const [courseId, setCourseId] = useState("");
  const history = useHistory();

  const [signUpData, setSignUpData] = useState();
  const [signInData, setSignInData] = useState();

  const [isBtndisable, setisBtndisable] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [popupMsg, setPopupMsg] = React.useState("");
  const [snackbarColor, setSnackbarColor] = React.useState("");
  const [Loader, setLoader] = React.useState(false);
  const [userName, setUserName] = React.useState("");

  React.useEffect(() => {
    // setisBtndisable(true);

    if (signUpData !== undefined) {
      axiosClient()
        .post("/signUp", signUpData)
        .then((response) => {
          setisBtndisable(false);
          console.log("response.data", response.data);
          if (response.data.status === "400") {
            setOpen(true);
            setPopupMsg(response.data.message);
            setSnackbarColor("error");
            //show success msg if user created .
          } else {
            setOpen(true);
            setPopupMsg("User created successfully");
            setSnackbarColor("success");
            //   updateFormData(initialFormData);
            history.push("/Signin");
          }
        })
        .catch((error) => {
          alert("server error signin", error);
        });
    }
  }, [signUpData]);

  React.useEffect(() => {
    if (signInData !== undefined) {
      setisBtndisable(true);
      axiosClient()
        .post("/login", signInData)
        //getting response after hiting api
        .then((response) => {
          setUserName(response.data.data);
          // setCourseIds(response.data.data.courseId);
          //if login failed it display error
          setisBtndisable(false);
          if (response.data.status === "400") {
            //   setOpenss(true);
            setPopupMsg(response.data.message);
            alert(response.data.message);
            setSnackbarColor("error");
            //message to show on the successfully login
          } else {
            //   setOpenss(false);
            // setLoader(false);
            setPopupMsg("Login successful");
            setSnackbarColor("success");
            //   setOpenss(true);
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("companyId", response.data.data.companyId);
            localStorage.setItem("bloggerId", response.data.data.bloggerId);
            history.push("/");
            // setOpen(false);
          }
        })
        //if any problem on the server side
        .catch((error) => {
          alert("server error signin", error);
          setisBtndisable(false);
        });
    }
  }, [signInData]);

  return (
    <PostApiContext.Provider
      value={{
        setSignUpData,
        setSignInData,
        setUserName,
        userName,
        isBtndisable,
        snackbarColor,
        popupMsg,
      }}
    >
      {children}
    </PostApiContext.Provider>
  );
};

export default PostApiProvider;
