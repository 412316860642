import React from "react";
import ReactPlayer from "react-player";
import { Grid } from "@material-ui/core";

export default function courseVideo(props) {
  const { videoUrl, courseDescription } = props;

  return (
    <React.Fragment>
      <ReactPlayer
        style={{ cursor: "pointer", marginTop: "20px" }}
        controls="true"
        //   light={videoThumbnail}
        className="videoPlayer"
        url={videoUrl}
        config={{
          file: {
            attributes: {
              controlsList: "nodownload",
            },
          },
        }}
        //   onClick={handleCheckout}
      />
      {/* <Grid item style={{ marginTop: "20px" }}>
        <h5>Course Details:</h5>
        <p style={{ textAlign: "justify" }}>
          {" "}
          <p
            dangerouslySetInnerHTML={{
              __html: courseDescription,
            }}
          ></p>
        </p>
      </Grid> */}
    </React.Fragment>
  );
}
