import React, { useContext } from 'react';

import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';

import axiosClient from '../../helper';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import FormControl from '@material-ui/core/FormControl';

import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import './CompanyDetail.css';
import { CompanyContext } from '../../Context/companyContext';
import PrimaryNavBar from '../../Components/PrimaryNavBar/PrimaryNavBar';
import NavBar from '../../Components/NavBar/NavBar';
function Alert(props) {
	return <MuiAlert elevation={6} variant='filled' {...props} />;
}
const BootstrapInput = withStyles((theme) => ({
	root: {
		'label + &': {
			marginTop: theme.spacing(3),
		},
	},
	input: {
		borderRadius: 4,
		position: 'relative',
		backgroundColor: theme.palette.background.paper,
		border: '1px solid #ced4da',
		fontSize: 16,
		padding: '10px 26px 10px 12px',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		// Use the system font instead of the default Roboto font.
		fontFamily: [
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
		'&:focus': {
			borderRadius: 4,
			borderColor: '#80bdff',
			boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
		},
	},
}))(InputBase);
const useStyles = makeStyles((theme) => ({
	margin: {
		width: '100%',
	},
}));
export default function SignUp() {
	const { companyLogo } = useContext(CompanyContext);

	const [open, setOpen] = React.useState(false);
	const [popupMsg, setPopupMsg] = React.useState('');
	const [snackbarColor, setSnackbarColor] = React.useState('');
	const [isBtndisable, setisBtndisable] = React.useState(false);
	const [productImage, setProductImage] = React.useState('');

	const classes = useStyles();

	const handleImages = async (e) => {
		setisBtndisable(true);

		const files = e.target.files;

		const data = new FormData();
		data.append('file', files[0]);
		data.append('upload_preset', 'productimages');
		const res = await fetch(
			'https://api.cloudinary.com/v1_1/my-cloud-name/image/upload',
			{
				method: 'POST',
				body: data,
			}
		);

		let file = await res.json();

		setProductImage(file.secure_url);
		updateFormData({
			...formData,
			companyPicture: file.secure_url,
		});
		setisBtndisable(false);
	};
	const initialFormData = Object.freeze({
		companyName: '',
		phoneNumber: '',
		companyEmail: '',
		companyAddress: '',

		companyLogoName: '',
		companyPicture: '',

		companyWebsite: '',

		companyDescription: '',
	});
	const [formData, updateFormData] = React.useState(initialFormData);
	const handleChangeData = (e) => {
		updateFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};
	const history = useHistory();
	const handleCompany = (event) => {
		setisBtndisable(true);
		axiosClient()
			.post('/addCompanyByClient', formData)
			.then((response) => {
				setisBtndisable(false);

				if (response.data.status === '400') {
					setOpen(true);
					setPopupMsg(response.data.message);
					setSnackbarColor('error');
				} else {
					setOpen(true);
					setPopupMsg(response.data.message);
					setSnackbarColor('success');
					updateFormData(initialFormData);
					localStorage.setItem('companyId', response.data.data._id);

					history.push('/postJob');
				}
			})
			.catch((error) => {
				alert('server error Company', error);
				setisBtndisable(false);
			});
	};

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};
	const onlyNumbers = (e) => {
		e.target.value = e.target.value.replace(/[^0-9]/g, '');
	};
	return (
		<React.Fragment>
			<PrimaryNavBar />
			<NavBar />
			<Grid container justify='center'>
				<Grid
					item
					xs={11}
					md={6}
					lg={6}
					style={{
						backgroundColor: 'white',
						height: 'auto',
						marginTop: '70px',
						boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
					}}
				>
					<Grid container justify='center' style={{ marginTop: '10px' }}>
						<h3>Company Detail</h3>
					</Grid>
					<Grid
						container
						item
						justify='center'
						xs={12}
						// spacing={2}
						style={{ marginTop: '40px' }}
					>
						<Grid item xs={11} md={8} lg={8} style={{ marginTop: '10px' }}>
							<h6>Company Title</h6>

							{/* creating text feild for First Name */}
							<TextField
								autoComplete='fname'
								name='companyName'
								variant='outlined'
								required
								fullWidth
								value={formData.companyName}
								id='companyName'
								autoFocus
								onChange={handleChangeData}
							/>
						</Grid>

						<Grid item xs={11} md={8} lg={8} style={{ marginTop: '10px' }}>
							<h6>Company Phone Number</h6>

							{/* creating text feild for First Name */}
							<TextField
								autoComplete='phoneNumber'
								name='phoneNumber'
								variant='outlined'
								onInput={(e) => onlyNumbers(e)}
								required
								fullWidth
								value={formData.phoneNumber}
								id='phoneNumber'
								autoFocus
								onChange={handleChangeData}
							/>
						</Grid>
						<Grid item xs={11} md={8} lg={8} style={{ marginTop: '10px' }}>
							<h6>Company Email</h6>

							{/* creating text feild for First Name */}
							<TextField
								autoComplete='Lname'
								name='companyEmail'
								variant='outlined'
								required
								fullWidth
								value={formData.companyEmail}
								id='companyEmail'
								autoFocus
								onChange={handleChangeData}
							/>
						</Grid>
						<Grid item xs={11} md={8} lg={8} style={{ marginTop: '10px' }}>
							<h6>Company Website</h6>

							<TextField
								variant='outlined'
								required
								fullWidth
								id='companyWebsite'
								value={formData.companyWebsite}
								name='companyWebsite'
								autoComplete='companyWebsite'
								onChange={handleChangeData}
							/>
						</Grid>
						<Grid item xs={11} md={8} lg={8} style={{ marginTop: '10px' }}>
							<h6>Company Address</h6>

							<TextField
								variant='outlined'
								required
								fullWidth
								id='companyAddress'
								value={formData.companyAddress}
								name='companyAddress'
								autoComplete='CompanyAddress'
								onChange={handleChangeData}
							/>
						</Grid>
						<Grid item xs={11} md={8} lg={8} style={{ marginTop: '10px' }}>
							<h6>Description</h6>

							<TextField
								id='outlined-multiline-static'
								value={formData.companyDescription}
								name='companyDescription'
								fullWidth
								onChange={handleChangeData}
								multiline
								rows={3}
								variant='outlined'
							/>
						</Grid>

						<Grid item xs={11} md={8} lg={8} style={{ marginTop: '10px' }}>
							<h6>Existing Image</h6>

							<FormControl className={classes.margin}>
								<NativeSelect
									id='demo-customized-select-native'
									name='companyPicture'
									onChange={handleChangeData}
									input={<BootstrapInput />}
								>
									{companyLogo.map((x) => (
										<option value={x.companyPicture}>
											{x.companyLogoName}
										</option>
									))}
								</NativeSelect>
							</FormControl>
						</Grid>
						<Grid item xs={11} md={8} lg={8} style={{ marginTop: '10px' }}>
							<h6>Logo Name</h6>

							{/* creating text feild for First Name */}
							<TextField
								autoComplete='companyLogoName'
								name='companyLogoName'
								variant='outlined'
								required
								fullWidth
								value={formData.companyLogoName}
								id='companyLogoName'
								autoFocus
								onChange={handleChangeData}
							/>
						</Grid>
						<Grid item xs={11} md={8} lg={8} style={{ marginTop: '10px' }}>
							<h6>Upload Logo</h6>

							<form className='imgfeild'>
								<input
									name='companyPicture'
									type='file'
									onChange={handleImages}
								/>
							</form>
						</Grid>
						<Grid item xs={6} style={{ marginTop: '30px' }}>
							<Button
								style={{
									background: isBtndisable ? '#2f4f4f' : '#1979C3',

									color: 'white',
									marginBottom: '40px',
								}}
								type='submit'
								fullWidth
								variant='contained'
								disabled={isBtndisable}
								onClick={handleCompany}
							>
								{isBtndisable ? 'Please Wait...' : 'Register'}
							</Button>
						</Grid>
					</Grid>
				</Grid>

				<Snackbar
					anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
					open={open}
					autoHideDuration={5000}
					onClose={handleClose}
				>
					<Alert onClose={handleClose} severity={snackbarColor}>
						{popupMsg}
					</Alert>
				</Snackbar>
			</Grid>
			<div style={{ marginTop: '30px' }}></div>
		</React.Fragment>
	);
}
