import React, { useContext } from "react";

import { Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

import axiosClient from "../../helper";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { PostApiContext } from "../../Context/PostApiContext";
import PrimaryNavBar from "../../Components/PrimaryNavBar/PrimaryNavBar";
import NavBar from "../../Components/NavBar/NavBar";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function SignUp() {
  const { setSignUpData, isBtndisable } = useContext(PostApiContext);

  const [open, setOpen] = React.useState(false);
  const [popupMsg, setPopupMsg] = React.useState("");
  const [snackbarColor, setSnackbarColor] = React.useState("");

  const initialFormData = Object.freeze({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    confirmemail: "",
  });
  const [signUpData, updateSignUpData] = React.useState(initialFormData);
  const handleChangeData = (e) => {
    updateSignUpData({
      ...signUpData,
      [e.target.name]: e.target.value,
    });
  };
  const history = useHistory();
  function handleSignup(event) {
    event.preventDefault();
    if (signUpData.email !== signUpData.confirmemail) {
      setOpen(true);
      setPopupMsg("Email didnt match");
      setSnackbarColor("error");
    } else if (signUpData.password !== signUpData.confirmPassword) {
      setOpen(true);
      setPopupMsg("Password didnt match");
      setSnackbarColor("error");
    } else {
      setSignUpData(signUpData);
    }
  }
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (
    <React.Fragment>
      <PrimaryNavBar />
      <NavBar />
      <Grid container justify="center">
        <Grid
          item
          xs={11}
          md={6}
          lg={6}
          style={{
            backgroundColor: "white",
            height: "auto",
            marginTop: "70px",
            boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
          }}
        >
          <Grid container justify="center" style={{ marginTop: "20px" }}>
            <h3>Nursing Logo</h3>
          </Grid>
          <Grid
            container
            item
            justify="center"
            xs={12}
            style={{ marginTop: "40px" }}
          >
            <Grid item xs={11} md={8} lg={8}>
              {/* creating text feild for First Name */}
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                value={signUpData.firstName}
                id="firstName"
                label="First Name"
                autoFocus
                onChange={handleChangeData}
              />
            </Grid>
            <Grid item xs={11} md={8} lg={8} style={{ marginTop: "20px" }}>
              {/* creating text feild for First Name */}
              <TextField
                autoComplete="Lname"
                name="lastName"
                variant="outlined"
                required
                fullWidth
                value={signUpData.lastName}
                id="lastName"
                label="Last Name"
                onChange={handleChangeData}
              />
            </Grid>
            <Grid item xs={11} md={8} lg={8} style={{ marginTop: "20px" }}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email"
                value={signUpData.email}
                name="email"
                autoComplete="email"
                onChange={handleChangeData}
              />
            </Grid>
            <Grid item xs={11} md={8} lg={8} style={{ marginTop: "20px" }}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="confirmemail"
                label="Confirm Email "
                // name="confirmEmail"
                value={signUpData.confirmemail}
                name="confirmemail"
                autoComplete="confirmemail"
                onChange={handleChangeData}
              />
            </Grid>
            <Grid item xs={11} md={8} lg={8} style={{ marginTop: "20px" }}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                value={signUpData.password}
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={handleChangeData}
              />
            </Grid>
            <Grid item xs={11} md={8} lg={8} style={{ marginTop: "20px" }}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                value={signUpData.confirmPassword}
                type="password"
                id="confirmpassword"
                autoComplete="current-password"
                onChange={handleChangeData}
              />
            </Grid>
            <Grid item xs={6} style={{ marginTop: "30px" }}>
              <Button
                style={{
                  background: isBtndisable ? "#2f4f4f" : "#1979C3",

                  color: "white",
                  marginBottom: "40px",
                }}
                type="submit"
                fullWidth
                variant="contained"
                disabled={isBtndisable}
                //clicking the button navigate you to signin page.
                onClick={handleSignup}
              >
                {isBtndisable ? "Please Wait..." : "Register"}
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={open}
          autoHideDuration={5000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={snackbarColor}>
            {popupMsg}
          </Alert>
        </Snackbar>
      </Grid>
      <div style={{ marginTop: "30px" }}></div>
    </React.Fragment>
  );
}
