import React from 'react';
import { Grid } from '@material-ui/core';
import cardimg from '../../assets/images/default.jpeg';
import './newsCards.css';

import { useHistory } from 'react-router-dom';
import { NewsContext } from '../../Context/NewsContext';

export default function NewsCards({ News }) {
  const { newsTitle, newsDescription, _id, newsPicture, postedBy, slug } = News;
  const { setNewsId } = React.useContext(NewsContext);
  const history = useHistory();
  // var slugify = require('slugify');

  // const slug = slugify(newsTitle); // some-string

  //click function to navigate to signin page
  function handleDetailPage() {
    history.push(`/news/${slug}`);
    setNewsId(slug);
  }

  function handleImageError(e) {
    e.target.src = cardimg;
  }

  return (
    <Grid
      className="jobCard"
      style={{
        backgroundColor: 'white'
      }}
    >
      <Grid item onClick={handleDetailPage}>
        <img
          className="newsimg"
          style={{ cursor: 'pointer' }}
          src={newsPicture || cardimg}
          onError={handleImageError}
          width="100%"
          height="200px"
        />
      </Grid>
      <Grid item xs={12} onClick={handleDetailPage}>
        <h5 className="font" style={{ marginTop: '5px', cursor: 'pointer' }}>
          {newsTitle}
        </h5>
      </Grid>
      <Grid item xs={12}>
        <p className="paragraph" style={{ color: 'gray' }}>
          <p
            dangerouslySetInnerHTML={{
              __html: newsDescription
            }}
          ></p>
        </p>
      </Grid>
    </Grid>
  );
}
