import React, { useState, useContext } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useHistory } from "react-router-dom";
import "./Navbar.sass";
import "./NavBar.css";
import { HomeContext } from "../../Context/HomeContext";
import { CourseContext } from "../../Context/CourseContext";

export default function NavBar() {
  const {
    Company,
    courses,
    setNewsType,
    setJobType,
    setJobLocation,
    setjobSpecialties,
  } = useContext(HomeContext);

  const { setCourseId } = useContext(CourseContext);

  const history = useHistory();
  function handlepostBlog() {
    localStorage.getItem("token")
      ? localStorage.getItem("bloggerId") === "undefined"
        ? history.push("/BlogerDetail")
        : history.push("/postBlog")
      : history.push("/SignIn");
  }

  function handlepostJob() {
    localStorage.getItem("token")
      ? localStorage.getItem("companyId") === "undefined"
        ? history.push("/CompanyDetail")
        : history.push("/postJob")
      : history.push("/SignIn");
  }
  function handlepostNews() {
    localStorage.getItem("token")
      ? history.push("/postNews")
      : history.push("/SignIn");
  }
  function handleGallery() {
    history.push("/TrainingGallery");
  }
  function handleCalender() {
    history.push("/Calender");
  }
  function handleCourseVideo() {
    history.push("/courseVideo");
  }

  function handleDirectory() {
    history.push("/Directory");
  }
  function handleMyCourses() {
    localStorage.getItem("token")
      ? history.push("/myCourses")
      : history.push("/SignIn");
  }
  function handleHome() {
    history.push("/");
  }
  function handleCourse(course) {
    setCourseId(course._id);
    history.push(`/OnlineCourse/${course._id}`);
  }
  function handleNav(newsType) {
    setNewsType(newsType);
    history.push(`/ViewAllNews`);
  }
  function handleNavJob(jobType) {
    setJobType(jobType);
    history.push(`/ViewAllJobs`);
  }
  function handleNavLocation(jobLocation) {
    setJobLocation(jobLocation);
    history.push(`/ViewAllJobs`);
  }
  function handleNavSep(jobSep) {
    setjobSpecialties(jobSep);

    history.push(`/ViewAllJobs`);
  }
  function handle404() {
    // history.push(`/page404`);
  }
  function handleContactUs() {
    history.push("/contactUs");
  }
  return (
    <React.Fragment>
      <Navbar
        collapseOnSelect
        expand="lg"
        variant="dark"
        style={{
          backgroundColor: "#1979C3",
          color: "white",
          fontSize: "larger",
        }}
      >
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mx-auto">
            <Nav.Link
              className="navbartxtclr"
              className="active"
              onClick={handleHome}
            >
              Home
            </Nav.Link>
            {/* <NavDropdown
              title="Home"
              id="collasible-nav-dropdown"
              onClick={handleHome}
            >
              <NavDropdown.Item href="#action/3.1">Calender</NavDropdown.Item>
              <NavDropdown.Item onClick={handleGallery}>
                Gallery
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">
                Request In-House Training
              </NavDropdown.Item>
            </NavDropdown> */}
            <NavDropdown
              title="Nursing Jobs"
              className="active"
              id="collasible-nav-dropdown"
            >
              <NavDropdown
                title="Jobs by Type"
                id="collasible-nav-dropdown clrID"
              >
                <NavDropdown.Item
                  onClick={() => handleNavJob("Home Care Nurse")}
                >
                  Home Care Nurse
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleNavJob("School Jobs")}>
                  School Jobs
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleNavJob("NGO/HMO")}>
                  NGO/HMO
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => handleNavJob("Government Jobs")}
                >
                  Government Jobs
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => handleNavJob("Oil and Gas Jobs")}
                >
                  Oil and Gas Jobs
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleNavJob("Hospital Jobs")}>
                  Hospital Jobs
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title="Jobs by Location"
                id="collasible-nav-dropdown clrID"
              >
                <NavDropdown.Item onClick={() => handleNavLocation("Abia")}>
                  Abia
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleNavLocation("Abuja")}>
                  Abuja
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleNavLocation("Adamawa")}>
                  Adamawa
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => handleNavLocation("Akwa ibom")}
                >
                  Akwa ibom
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleNavLocation("Anambra")}>
                  Anambra
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleNavLocation("Bauchi")}>
                  Bauchi
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleNavLocation("Bayelsa")}>
                  Bayelsa
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleNavLocation("Benue")}>
                  Benue
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleNavLocation("Borno")}>
                  Borno
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => handleNavLocation("Cross River")}
                >
                  Cross River
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleNavLocation("Delta")}>
                  Delta
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleNavLocation("Ebonyi")}>
                  Ebonyi
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleNavLocation("Edo")}>
                  Edo
                </NavDropdown.Item>

                <NavDropdown.Item onClick={() => handleNavLocation("Ekiti")}>
                  Ekiti
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleNavLocation("Enugu")}>
                  Enugu
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleNavLocation("Gombe")}>
                  Gombe
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleNavLocation("Imo")}>
                  Imo
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleNavLocation("Jigawa")}>
                  Jigawa
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleNavLocation("Kaduna")}>
                  Kaduna
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleNavLocation("Kano")}>
                  Kano
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleNavLocation("katsina")}>
                  katsina
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleNavLocation("Kebbi")}>
                  Kebbi
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleNavLocation("Kogi")}>
                  Kogi
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleNavLocation("Kwara")}>
                  Kwara
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleNavLocation("Lagos")}>
                  Lagos
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => handleNavLocation("Nassarawa")}
                >
                  Nassarawa
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleNavLocation("Niger")}>
                  Niger
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleNavLocation("Ogun")}>
                  Ogun
                </NavDropdown.Item>

                <NavDropdown.Item onClick={() => handleNavLocation("Ondo")}>
                  Ondo
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleNavLocation("Osun")}>
                  Osun
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleNavLocation("Oyo")}>
                  Oyo
                </NavDropdown.Item>

                <NavDropdown.Item onClick={() => handleNavLocation("Plateau")}>
                  Plateau
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleNavLocation("Rivers")}>
                  Rivers
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleNavLocation("Sokoto")}>
                  Sokoto
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleNavLocation("Taraba")}>
                  Taraba
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleNavLocation("Yobe")}>
                  Yobe
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleNavLocation("Zamfara")}>
                  Zamfara
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown
                title="Jobs by Specialties"
                id="collasible-nav-dropdown clrID"
              >
                <NavDropdown.Item
                  onClick={() => handleNavSep("General Nurses ")}
                >
                  General Nurses{" "}
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => handleNavSep("Midwives ")}>
                  Midwives{" "}
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => handleNavSep("Mental Health Nurses ")}
                >
                  Mental Health Nurses{" "}
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => handleNavSep("Public Health Nurses ")}
                >
                  Public Health Nurses{" "}
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => handleNavSep("Public Health Nurses Educator ")}
                >
                  Public Health Nurses Educator{" "}
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => handleNavSep("Nurse Educators ")}
                >
                  Nurse Educators{" "}
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => handleNavSep("Midwife Educators ")}
                >
                  Midwife Educators{" "}
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => handleNavSep("Nurse Administration ")}
                >
                  Nurse Administration{" "}
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => handleNavSep("Orthopaedic Nurses ")}
                >
                  Orthopaedic Nurses{" "}
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => handleNavSep("Nurse Anaesthetists ")}
                >
                  Nurse Anaesthetists{" "}
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => handleNavSep("Ophthalmic Nurses ")}
                >
                  Ophthalmic Nurses{" "}
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => handleNavSep("Accident and Emergency Nurses ")}
                >
                  Accident and Emergency Nurses{" "}
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => handleNavSep("Paediatric Nurses ")}
                >
                  Paediatric Nurses{" "}
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => handleNavSep("Cardio-thoracic Nurses ")}
                >
                  Cardio-thoracic Nurses{" "}
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => handleNavSep("Burns and Plastic Nurses ")}
                >
                  Burns and Plastic Nurses{" "}
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => handleNavSep("Occupational Health Nurses ")}
                >
                  Occupational Health Nurses{" "}
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => handleNavSep("Ear, Nose and Throat Nurses ")}
                >
                  Ear, Nose and Throat Nurses{" "}
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() =>
                    handleNavSep("Nursing Degrees(BNSC, PGD, MSC, PHD) ")
                  }
                >
                  Nursing Degrees(BNSC, PGD, MSC, PHD){" "}
                </NavDropdown.Item>
              </NavDropdown>
              {/* <NavDropdown.Itemi onClck={handleNavSep}>Jobs By Industry</NavDropdown.Item>
              <NavDropdown.Item onClick={handleNavJob}>Jobs By State</NavDropdown.Item> */}
            </NavDropdown>

            <NavDropdown
              className="navbartxtclr"
              className="active"
              title="Nursing News"
              id="collasible-nav-dropdown"
            >
              <NavDropdown.Item onClick={() => handleNav("Admission News")}>
                Admission News
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleNav("Contributors")}>
                Contributors
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => handleNav("Foregin Nursing News")}
              >
                Foregin Nursing News
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleNav("ICN / ICM Updates")}>
                ICN / ICM Updates
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleNav("NANNM News")}>
                NANNM News
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleNav("News")}>
                News
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleNav("General News")}>
                General News
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => handleNav("Nursing Admission Forms on Sale")}
              >
                Nursing Admission Forms on Sale
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleNav("Nursing Articles")}>
                Nursing Articles
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => handleNav("Nursing Council Updates")}
              >
                Nursing Council Updates
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleNav("Press Publications")}>
                Press Publications
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleNav("Scholarships")}>
                Scholarships
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => handleNav("Traning / Workshop / Conference")}
              >
                Traning / Workshop / Conference
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => handleNav("West African College of Nursing")}
              >
                West African College of Nursing
              </NavDropdown.Item>
            </NavDropdown>
            {/* <Nav.Link className="active" onClick={handle404}>
              Articles
            </Nav.Link> */}

            <NavDropdown title="   Courses" id="collasible-nav-dropdown">
              <NavDropdown.Item onClick={handleGallery}>
                Gallery
              </NavDropdown.Item>
              <NavDropdown.Item onClick={handleCalender}>
                Calender
              </NavDropdown.Item>
              <NavDropdown.Item onClick={handleCourseVideo}>
                Online Courses
              </NavDropdown.Item>
              <NavDropdown.Item onClick={handleMyCourses}>
                <b>My Courses</b>
              </NavDropdown.Item>
              <hr></hr>
              <NavDropdown.Item>
                <b>Courses</b>
              </NavDropdown.Item>
              {courses.map((x) => (
                <NavDropdown.Item onClick={() => handleCourse(x)}>
                  {x.courseName}
                </NavDropdown.Item>
              ))}
            </NavDropdown>

            {/* <NavDropdown title="Directory" id="collasible-nav-dropdown">
              {Company.map((x) => (
                <NavDropdown.Item>{x.companyName}</NavDropdown.Item>
              ))}
            </NavDropdown> */}
            <Nav.Link
              className="navbartxtclr"
              className="active"
              onClick={handleDirectory}
            >
              Directory
            </Nav.Link>

            <Nav.Link
              className="navbartxtclr"
              className="active"
              onClick={handlepostBlog}
            >
              Post Blog
            </Nav.Link>

            <Nav.Link
              className="navbartxtclr"
              className="active"
              onClick={handlepostJob}
            >
              Post Job
            </Nav.Link>

            {/* <Nav.Link className="navbartxtclr" className="active" onClick={handle404}>
              Journals
            </Nav.Link> */}
            <Nav.Link
              className="navbartxtclr"
              className="active"
              onClick={handleContactUs}
            >
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </React.Fragment>
  );
}
