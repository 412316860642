import React, { useContext } from "react";
import { Grid, Container } from "@material-ui/core";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
// import "./calender.sass";
import moment from "moment";
import { EventsContext } from "../../Context/EventContext";
import { Event } from "jquery";
import PrimaryNavBar from "../../Components/PrimaryNavBar/PrimaryNavBar";
import NavBar from "../../Components/NavBar/NavBar";

export default function Calender() {
  function eventStyleGetter(event, start, end, isSelected) {
    console.log("my event", event);
    var backgroundColor = eventColor(event.courseColor);

    // var color = event.title === "ajooooo" ? "black" : "white";
    var style = {
      backgroundColor: backgroundColor,
      borderRadius: "0px",
      opacity: 0.8,
      color: "white",
      border: "0px",
      display: "block",
    };
    return {
      style: style,
    };
  }

  function eventColor(courses) {
    console.log("my courdse", courses);
    switch (courses) {
      case "First Aid, CPR & AED":
        return "black";
        // code block
        break;
      case "NCLEX Content Review Course":
        return "orange";
        // code block
        break;
      case "NCLEX Content Review Course":
        return "green";
        // code block
        break;
      case "Paediatric Advanced Life Support (PALS) – Provider Course":
        return "Magenta";
        // code block
        break;
      case "Advanced Cardiovascular Life Support (ACLS) – Provider Course":
        return "purple";
        // code block
        break;
      case "Basic Life Support (BLS) – Provider Course":
        return "orange";
        // code block
        break;
      default:
        return "blue";
      // code block
    }
  }
  const { events } = useContext(EventsContext);
  console.log("events", events);
  const localizer = momentLocalizer(moment);

  let event2 = [];

  let obj;
  let formattedStartDate;
  let formattedEndDate;
  events.map(
    (event) => (
      //    formattedStartDate = event.startDate + "T" + event.startTime + ":00"
      //  formattedEndDate = event.endDate + "T" + event.endTime + ":00";
      (obj = {
        title: "(" + event.states + ")" + event.courses,

        start: new Date(event.startDate),
        end: new Date(event.endDate),
        url: event.redirectURL,
        courseColor: event.courses,
        // start: moment(event.startTime).toDate(event.startDate),
        // end: moment(event.endTime).toDate(event.endDate),

        // start: moment(event.startTime).toDate(event.startDate),
        // end: moment(event.endTime).toDate(event.endDate),
        // start: moment("27/5/2021 09:15:00", "DD MM YYYY hh:mm:ss"),
      }),
      event2.push(obj)
    )
  );

  function handleRedirect(event2) {
    window.open(event2.url);
  }

  return (
    <>
      <PrimaryNavBar />
      <NavBar />
      <Container>
        <Grid style={{ marginTop: "40px", marginBottom: "20px" }}>
          <Calendar
            localizer={localizer}
            events={event2}
            onSelectEvent={handleRedirect}
            defaultView="agenda"
            showMultiDayTimes
            eventPropGetter={eventStyleGetter}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 1000, position: "relative" }}
          />
        </Grid>
      </Container>
    </>
  );
}
