import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";

function Copyright() {
  return (
    //typo for display text
    <Typography
      //styling of text
      style={{ color: "white" }}
      variant="body2"
      color="textSecondary"
    >
      {"Copyright © "}
      <Link
        to={{
          pathname: "/",
        }}
      >
        NursingWorld
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: "column",
  },
  main: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
  },
  //styling for the footer
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: "auto",
    backgroundColor: "#3D3D3E",
    color: "white",
    marginBottom: "0px",
  },
}));

export default function StickyFooter() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <footer className={classes.footer} style={{ textAlign: "center" }}>
        <Container maxWidth="sm">
          <Typography variant="body1">Terms and conditions.</Typography>
          <Copyright />
        </Container>
      </footer>
    </div>
  );
}
