import React, { useContext, useEffect } from 'react';

import Container from '@material-ui/core/Container';
import Load from '../../Components/Loader/loader';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import NewsCard from '../../Components/NewsCards/newsCards';

import { Grid } from '@material-ui/core';
import { HomeContext } from '../../Context/HomeContext.js';
import PrimaryNavBar from '../../Components/PrimaryNavBar/PrimaryNavBar';
import NavBar from '../../Components/NavBar/NavBar';
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
      marginBottom: '30px'
    }
  }
}));
export default function ViewAllNews() {
  const { news, setNewsPageNo, Loader, totalnews } = useContext(HomeContext);
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    setNewsPageNo(value);
  };

  const totalnew = Math.ceil(totalnews / 6);

  return (
    <React.Fragment>
      <PrimaryNavBar />
      <NavBar />
      <Container style={{ marginTop: '40px' }}>
        <Grid container justify="center" spacing={3}>
          <Grid item container justify="center">
            <h1 className="super">All News</h1>
          </Grid>
          {Loader ? (
            news.length === 0 ? (
              <div style={{ textAlign: 'center', marginTop: '120px', marginBottom: '120px' }}>
                <h4>No News Found ! </h4>
              </div>
            ) : (
              <Grid container spacing={3} justify="center" style={{ marginTop: '20px', marginBottom: '30px' }}>
                {news.map((news) => (
                  <Grid item xs={11} sm={6} md={4} lg={4}>
                    {' '}
                    <div>
                      <NewsCard News={news} />
                    </div>
                  </Grid>
                ))}
              </Grid>
            )
          ) : (
            <Grid
              item
              container
              justify="center"
              style={{
                marginTop: '60px',
                marginBottom: '500px'
              }}
            >
              <Load />
            </Grid>
          )}
          {news.length === 0 ? null : (
            <div className={classes.root}>
              <Pagination count={totalnew} color="primary" onChange={handleChange} />
            </div>
          )}
        </Grid>
      </Container>
    </React.Fragment>
  );
}
