import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import image from '../../assets/images/jobD.jpg';
import DateRangeIcon from '@material-ui/icons/DateRange';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { useHistory } from 'react-router-dom';
import '../jobCards/jobCard.css';
import { CompanyContext } from '../../Context/companyContext';

export default function JobCard(props) {
  const history = useHistory();

  const {
    jobTitle,
    jobDetail,
    categoryName,
    date,
    Experience,
    NursingSpecialties,
    Education,
    jobType,
    jobDescription,
    jobPicture,
    _id,
    slug
  } = props.Jobs;
  // const { logo } = props.Jobs.companyId;

  const { setJobId } = useContext(CompanyContext);

  //click function to navigate to signin page
  function handleDetailPage() {
    history.push(`/job/${slug}`);
    setJobId(slug);
  }

  return (
    <Grid
      container
      onClick={handleDetailPage}
      style={{
        cursor: 'pointer'
      }}
    >
      <Grid></Grid>
      <Grid
        item
        className="Mobile"
        xs={12}
        md={12}
        lg={12}
        style={{ paddingLeft: '30px' }}
      >
        <Grid item style={{ display: 'flex' }}>
          <img
            className="mobileviewpic"
            src={
              props.Jobs.companyId
                ? props.Jobs.companyId.logo
                  ? props.Jobs.companyId.logo.companyPicture
                  : jobPicture
                  ? jobPicture
                  : image
                : jobPicture
                ? jobPicture
                : image
            }
            // src={jobPicture || image}
            width="50px"
            height="50px"
          />
          <h5 className="title" style={{ paddingLeft: '20px' }}>
            {' '}
            {jobTitle}
          </h5>
        </Grid>
        <p className="jobparagraph" style={{ textAlign: 'justify' }}>
          <p
            dangerouslySetInnerHTML={{
              __html: jobDescription
            }}
          ></p>
        </p>
        <Grid xs={12} className="cardAdjust" style={{ display: 'flex' }}>
          <Grid item xs={4} md={2} lg={2}>
            <h6 style={{ color: 'red' }}>
              <DateRangeIcon className="calender" />{' '}
              {new Date(date).toDateString().slice(4, 10)}
            </h6>
          </Grid>
          <Grid item xs={12} md={10} lg={10}>
            <h6
              className="Mobile"
              style={{ color: 'gary', paddingLeft: '20px' }}
            >
              <LocalOfferIcon className="tag" />{' '}
              <b className="jobtype">Job Type :</b> {categoryName || jobType}
            </h6>
          </Grid>
        </Grid>
        <Grid className="Mobilet">
          {NursingSpecialties.length !== 0 ? (
            <div style={{ display: 'flex', marginTop: '10px' }}>
              <DoubleArrowIcon className="arrow" />{' '}
              <h6>
                <b>Nursing Specialties :</b> {NursingSpecialties}
              </h6>
            </div>
          ) : null}
          {/* {companyName ? (
            <div style={{ display: "flex" }}>
              <DoubleArrowIcon className="arrow" />{" "}
              <h6>
                {" "}
                <b>Company :</b> {companyName}{" "}
              </h6>
            </div>
          ) : null} */}
        </Grid>
        <Grid></Grid>
      </Grid>
    </Grid>
  );
}
