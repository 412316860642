import React, { useState, createContext } from 'react';
import axiosClient from '../helper';

export const BloggerContext = createContext();

const BloggerProvider = ({ children }) => {
  const [BloggerId, setBloggerId] = useState('');

  const [BloggerInfo, setBloggerInfo] = useState([]);
  const [BloggerProfile, setBloggerProfile] = useState({});
  const [Loader, setLoader] = React.useState(false);

  React.useEffect(() => {
    setLoader(false);
    if (BloggerId !== '') {
      axiosClient()
        .get(`/getBlogsByBloggerId/${BloggerId}`)
        .then((res) => {
          setBloggerInfo(res.data.data);
          setBloggerProfile(res.data.bloggerInfo);
          setLoader(true);
        })
        .catch((error) => {});
    }
  }, [BloggerId]);

  return (
    <BloggerContext.Provider
      value={{
        setBloggerId,
        BloggerInfo,
        BloggerProfile,
        Loader
      }}
    >
      {children}
    </BloggerContext.Provider>
  );
};

export default BloggerProvider;
