import React, { useState, createContext } from 'react';
import axiosClient from '../helper';

export const BlogsContext = createContext();

const BlogProvider = ({ children }) => {
	const [BlogsId, setBlogsId] = useState('');
	const [BlogsDetail, setBlogsDetail] = useState({});
	const [bloggerInfo, setbloggerInfo] = useState({});
	const [relatedBlogs, setRelatedBlogs] = useState([]);

	const [Loader, setLoader] = React.useState(false);

	React.useEffect(() => {
		setLoader(false);
		if (BlogsId !== '') {
			axiosClient()
				.get(`/getBlogById/${BlogsId}`)
				.then((res) => {
					setBlogsDetail(res.data.data);
					setbloggerInfo(res.data.bloggerInfo);
					setRelatedBlogs(res.data.relatedBlogs);

					setLoader(true);
				})
				.catch((error) => {});
		}
	}, [BlogsId]);

	return (
		<BlogsContext.Provider
			value={{
				setBlogsId,
				relatedBlogs,
				BlogsDetail,
				bloggerInfo,
				Loader,
			}}
		>
			{children}
		</BlogsContext.Provider>
	);
};

export default BlogProvider;
