import React from 'react';

import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ReactQuill from 'react-quill';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import backg from '../../assets/images/background.jpg';

import 'react-quill/dist/quill.snow.css';
import FormControl from '@material-ui/core/FormControl';
import axiosClient from '../../helper';
import NativeSelect from '@material-ui/core/NativeSelect';
import { DropzoneArea } from 'material-ui-dropzone';
import '../postBlog/postBlog.css';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import PrimaryNavBar from '../../Components/PrimaryNavBar/PrimaryNavBar';
import NavBar from '../../Components/NavBar/NavBar';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}))(InputBase);
const useStyles = makeStyles((theme) => ({
  margin: {
    width: '100%'
  }
}));
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function PostJob() {
  const CHARACTER_LIMIT = 105;

  const [productImage, setProductImage] = React.useState('');
  const [isBtndisable, setisBtndisable] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [popupMsg, setPopupMsg] = React.useState('');
  const [snackbarColor, setSnackbarColor] = React.useState('');
  const classes = useStyles();
  const initialFormData = Object.freeze({
    newsTitle: '',
    newsType: '',
    newsDetail: '',
    newsPicture: '',
    newsDescription: ''
  });
  const [formData, updateFormData] = React.useState(initialFormData);

  const handleChangeData = (e) => {
    const { value, name } = e.target;

    updateFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  const handleNewsDetail = (a) => {
    updateFormData({
      ...formData,
      newsDetail: a
    });
  };
  const handleImages = async (e) => {
    const files = e;

    const data = new FormData();
    data.append('file', files[0]);
    data.append('upload_preset', 'productimages');

    const res = await fetch('https://api.cloudinary.com/v1_1/my-cloud-name/image/upload', {
      method: 'POST',
      body: data
    });

    let file = await res.json();

    setProductImage(file.secure_url);
    updateFormData({
      ...formData,
      newsPicture: file.secure_url
    });
  };
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],
      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction
      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],
      ['clean'] // remove formatting button
    ]
  };
  const handlepostNews = (event) => {
    event.preventDefault();

    setisBtndisable(true);
    axiosClient()
      .post('/addNewsByClient', formData)
      .then((response) => {
        setisBtndisable(false);
        if (response.data.status === '400') {
          setOpen(true);
          setPopupMsg(response.data.message);
          setSnackbarColor('error');
        } else {
          setOpen(true);
          setPopupMsg(response.data.message);
          setSnackbarColor('success');
          updateFormData(initialFormData);
        }
      })
      .catch((error) => {
        alert('server error Post Job Api', error);
      });
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  return (
    <React.Fragment>
      <PrimaryNavBar />
      <NavBar />
      <Grid container justify="center" style={{ backgroundImage: `url(${backg})`, backgroundSize: 'cover' }}>
        <Grid
          item
          xs={11}
          md={8}
          lg={8}
          style={{
            backgroundColor: 'white',
            height: 'auto',
            marginTop: '70px',
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
          }}
        >
          <Grid container justify="center" style={{ marginTop: '20px' }}>
            <h3>Post A News</h3>
          </Grid>
          <Grid container justify="center" style={{ marginTop: '40px' }}>
            <Grid item xs={11} md={7} lg={7}>
              {/* creating text feild for First Name */}
              <h6>News Title</h6>
              <TextField
                autoComplete="newsTitle"
                name="newsTitle"
                variant="outlined"
                required
                fullWidth
                value={formData.newsTitle}
                id="newsTitle"
                // label="Title"
                autoFocus
                inputProps={{
                  maxlength: CHARACTER_LIMIT
                }}
                onChange={handleChangeData}
              />
            </Grid>
            <Grid item xs={11} md={7} lg={7} style={{ marginTop: '20px' }}>
              <h6>News Type</h6>
              <FormControl className={classes.margin}>
                <NativeSelect
                  id="demo-customized-select-native"
                  name="newsType"
                  value={formData.newsType}
                  onChange={handleChangeData}
                  input={<BootstrapInput />}
                >
                  <option aria-label="None" value="" />
                  <option value={'None'}>None</option>
                  <option value={'Gernal News'}>Gernal News</option>
                  <option value={'Nursing Admission News'}>Nursing Admission News</option>
                  <option value={'Foreign Nursing News'}>Foreign Nursing News</option>
                  <option value={'Scholarships'}>Scholarships</option>
                  <option value={'Press Publications'}>Press Publications</option>
                  <option value={'NMCN Updates'}>NMCN Updates</option>
                  <option value={'NANNM Updates'}>NANNM Updates</option>
                  <option value={'WACN Updates'}>WACN Updates</option>
                  <option value={'ICN/ICM Updates'}>ICN/ICM Updates</option>
                </NativeSelect>
              </FormControl>
            </Grid>
            <Grid item xs={11} md={7} lg={7} style={{ marginTop: '20px' }}>
              <h6>Short Description</h6>
              <TextField
                id="outlined-multiline-static"
                label="Description"
                value={formData.newsDescription}
                name="newsDescription"
                fullWidth
                onChange={handleChangeData}
                multiline
                inputProps={{
                  maxlength: CHARACTER_LIMIT
                }}
                rows={4}
                // helperText="Include condition, features and reason for selling"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={11} md={7} lg={7} style={{ marginTop: '20px' }}>
              <DropzoneArea
                initialFiles={formData.newsPicture}
                name="newsPicture"
                onDrop={handleImages}
                filesLimit={5}
                acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                maxFileSize={5000000}
              />
            </Grid>
            <Grid item xs={11} md={7} lg={7} style={{ marginTop: '20px' }}>
              <ReactQuill value={formData.newsDetail} modules={modules} onChange={(a) => handleNewsDetail(a)} />
            </Grid>
            <Grid container justify="center" style={{ marginTop: '20px' }}>
              <Grid item xs={2}>
                <Button
                  style={{
                    background: isBtndisable ? '#2f4f4f' : '#1979C3',

                    color: 'white',
                    marginBottom: '40px'
                  }}
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={isBtndisable}
                  //clicking the button navigate you to signin page.
                  onClick={handlepostNews}
                >
                  {isBtndisable ? 'Please Wait...' : 'Post'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={open}
          autoHideDuration={5000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={snackbarColor}>
            {popupMsg}
          </Alert>
        </Snackbar>
      </Grid>
    </React.Fragment>
  );
}
