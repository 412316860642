import React from "react";

import { Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import "react-quill/dist/quill.snow.css";
import { DropzoneArea } from "material-ui-dropzone";
import axiosClient from "../../helper";
import ReactQuill, { Quill } from "react-quill";

import "../postBlog/postBlog.css";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import PrimaryNavBar from "../../Components/PrimaryNavBar/PrimaryNavBar";
import NavBar from "../../Components/NavBar/NavBar";
import { useHistory } from "react-router-dom";
import ImageResize from "quill-image-resize-module-react";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function PostJob() {
  Quill.register("modules/imageResize", ImageResize);

  const CHARACTER_LIMIT = 150;

  const modules = {
    imageResize: {
      parchment: Quill.import("parchment"),
      modules: ["Resize", "DisplaySize"],
    },
    toolbar: [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      ["blockquote", "code-block"],
      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ direction: "rtl" }], // text direction
      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["link", "image", "video", "file"],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],
      ["clean"], // remove formatting button
    ],
  };
  const [open, setOpen] = React.useState(false);
  const [popupMsg, setPopupMsg] = React.useState("");
  const [snackbarColor, setSnackbarColor] = React.useState("");
  const [isBtndisable, setisBtndisable] = React.useState(false);
  const [productImage, setProductImage] = React.useState("");
  const [blogPicture, setBlogPicture] = React.useState("");
  const data = new FormData();
  const history = useHistory();

  // const handleImages = async (e) => {
  //   setisBtndisable(true);

  //   const files = e;

  //   const data = new FormData();
  //   data.append("file", files[0]);
  //   data.append("upload_preset", "productimages");
  //   const res = await fetch(
  //     "https://api.cloudinary.com/v1_1/my-cloud-name/image/upload",
  //     {
  //       method: "POST",
  //       body: data,
  //     }
  //   );

  //   let file = await res.json();

  //   setProductImage(file.secure_url);
  // updateFormData({
  //   ...formData,
  //   blogPicture: file.secure_url,
  // });
  //   setisBtndisable(false);
  // };
  const initialFormData = Object.freeze({
    title: "",
    description: "",

    shortDescription: "",
  });
  const [formData, updateFormData] = React.useState(initialFormData);
  const handleChangeData = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleBlogDetail = (a) => {
    updateFormData({
      ...formData,
      description: a,
    });
  };
  const handleBlogPicture = (file) => {
    setBlogPicture(file);
  };

  const handleBlog = (event) => {
    setisBtndisable(true);

    for (const [key, value] of Object.entries(formData)) {
      data.append(key, value);
    }
    data.append("blogPicture", blogPicture[0]);

    axiosClient()
      .post("/addBlog", data)
      .then((response) => {
        setisBtndisable(false);

        if (response.data.status === "400") {
          setOpen(true);
          setPopupMsg(response.data.message);
          setSnackbarColor("error");
        } else {
          setOpen(true);
          setPopupMsg(response.data.message);
          setSnackbarColor("success");
          updateFormData(initialFormData);
          setTimeout(() => {
            history.push("/");
          }, 3000);
        }
      })
      .catch((error) => {
        alert("server error blog", error);

        setisBtndisable(false);
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (
    <React.Fragment>
      <PrimaryNavBar />
      <NavBar />
      <Grid container justify="center">
        <Grid
          item
          xs={11}
          md={8}
          lg={8}
          style={{
            backgroundColor: "white",
            height: "auto",
            marginTop: "70px",
            boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
          }}
        >
          <Grid container justify="center" style={{ marginTop: "20px" }}>
            <h3>Post A Blog</h3>
          </Grid>
          <Grid container justify="center" style={{ marginTop: "40px" }}>
            <Grid item xs={11} md={7} lg={7}>
              {/* creating text feild for First Name */}
              <TextField
                autoComplete="title"
                name="title"
                variant="outlined"
                required
                fullWidth
                value={formData.title}
                id="title"
                autoFocus
                onChange={handleChangeData}
              />
            </Grid>
            <Grid item xs={11} md={7} lg={7}>
              <h6>Short Description</h6>
              <TextField
                id="outlined-multiline-static"
                label="Description"
                value={formData.shortDescription}
                name="shortDescription"
                fullWidth
                onChange={handleChangeData}
                multiline
                inputProps={{
                  maxlength: CHARACTER_LIMIT,
                }}
                rows={4}
                // helperText="Include condition, features and reason for selling"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={11} md={7} lg={7} style={{ marginTop: "20px" }}>
              <DropzoneArea
                name="blogPicture"
                // onDrop={setBlogPicture}
                filesLimit={1}
                onChange={(a) => handleBlogPicture(a)}
                acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                maxFileSize={5000000}
              />
            </Grid>
            <Grid item xs={11} md={7} lg={7} style={{ marginTop: "20px" }}>
              <ReactQuill
                style={{ height: 300 }}
                value={formData.description}
                modules={modules}
                onChange={(a) => handleBlogDetail(a)}
              />
            </Grid>
            <Grid container justify="center" style={{ marginTop: "120px" }}>
              <Grid item xs={2}>
                <Button
                  style={{
                    background: isBtndisable ? "#2f4f4f" : "#1979C3",
                    // background: "#1979C3",
                    color: "white",
                    marginBottom: "40px",
                  }}
                  type="submit"
                  fullWidth
                  variant="contained"
                  // disabled={isBtndisable}
                  onClick={handleBlog}
                >
                  {/* Submit */}
                  {isBtndisable ? "Please Wait..." : "Submit"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={open}
          autoHideDuration={5000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={snackbarColor}>
            {popupMsg}
          </Alert>
        </Snackbar>
      </Grid>
    </React.Fragment>
  );
}
